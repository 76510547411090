import { Button, Col } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { token } from "../../../utils/authentication";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import Loader from "../../loadar/Loader";

import { DatePicker } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { io } from "socket.io-client";
import defaultImage from "../../../assets/images/default.png";
import { ContextApi } from "../../../hooks/ContextApi";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { GiPathDistance } from "react-icons/gi";
import {
  getUserInfo,
  paramsToQueryString,
} from "../../../utils/commonFunctions";
import BookingDates from "./BookingDates";
import ChattSidebar from "./ChattSidebar";
import NewChat from "./NewChat";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

const { RangePicker } = DatePicker;

function MyEmployee() {
  const { t } = useTranslation();
  const jwtDecode = jwtTokenDecode();
  const navigate = useNavigate();
  console.log(jwtDecode);

  const [getEmployee, setEmployee] = useState([]);
  const [myEmployee, setMyEmployee] = useState([]);
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [activeEmployee, setActiveEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeEmployeeId, setActiveEmployeeId] = useState(null);
  const { currencyType } = useContext(ContextApi);
  const [employeeInfo, setEmployeeInfo] = useState();
  const [employeeId, setEmployeeId] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [distanceList, setDistanceList] = useState([]);
  const [calculatedDistance, setCalculatedDistance] = useState(0);
  // prevous month
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = (item) => {
    setIsSidebarOpen(!isSidebarOpen);
    setEmployeeInfo(item);
    setEmployeeId(item?.employeeId);
  };

  const [chatMessages, setChatMessages] = useState([]);
  const chatMessagesTest = [
    {
      sender: "OtherGuy",
      text: "Hello, how are you? Mr Nahid Hassan ",
      timestamp: 1671446460000,
    },
    {
      sender: "Mr Nahid Hassan Bulbul",
      text: "I'm fine, thank you. How are you?",
      timestamp: 1671446400000,
    },
    // ... more messages
  ];
  const myMessageStyle = {
    textAlign: "left",
    backgroundColor: "#f1f1f1",
    margin: "10px 0",
    padding: "10px",
    borderRadius: "10px",
    display: "inline-block",
  };

  const otherMessageStyle = {
    textAlign: "right",
    backgroundColor: "#ccc",
    margin: "10px 0",
    padding: "10px",
    borderRadius: "10px",
    display: "inline-block",
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    const message = event.target.message.value;
    setChatMessages([...chatMessages, { text: message, sender: "you" }]);
    event.target.message.value = "";
  };

  //Set filter data
  const [getName, setName] = useState(undefined);

  // const fecthMyEmployee = async () => {
  //   setLoading(true);
  //   // const query = {
  //   //   startDate: startDate,
  //   //   endDate: endDate,
  //   //   hiredStatus: "ALLOW",
  //   //   employeeName: getName ? getName : undefined,
  //   //   restaurantName: getUserInfo()?.restaurantName,

  //   // };
  //   const start =
  //     activeTab === "2"
  //       ? dayjs().format("YYYY-MM-DD")
  //       : startDate
  //         ? startDate
  //         : undefined;
  //   const end =
  //     activeTab === "2"
  //       ? dayjs().format("YYYY-MM-DD")
  //       : endDate
  //         ? endDate
  //         : undefined;
  //   const query2 = {
  //     startDate: start,
  //     endDate: end,
  //     hiredBy: getUserInfo()?._id,
  //   };
  //   // const queryToString = paramsToQueryString(query);
  //   const queryToString2 = paramsToQueryString(query2);
  //   // const url = `${process.env.REACT_APP_API_BASE_URL}/book-history?${queryToString}`;
  //   const url2 = `${process.env.REACT_APP_API_BASE_URL}/book-history/client-employee?${queryToString2}`;
  //   try {
  //     const responseData = await axios.get(url2, {
  //       headers: {
  //         Authorization: `Bearer ${token()}`,
  //       },
  //     });
  //     if (responseData && responseData?.data.statusCode === 200) {
  //       // const data = activeTab === "1" ? responseData?.data?.data : responseData?.data?.details?.result[0]?.employee;
  //       const data = responseData?.data?.details?.result[0]?.employee;
  //       console.log("data", responseData?.data?.details);
  //       setMyEmployee(data);
  //       setLoading(false);
  //     } else if (responseData && responseData?.data.statusCode === 400) {
  //       // setError(responseData.errors);
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setError(error);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fecthMyEmployee();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [startDate, endDate, activeTab]);

  // useEffect(() => {
  //   if (activeTab === "1" && getName) {
  //     const filteredEmployee = myEmployee.filter((item) =>
  //       item?.employeeDetails?.name
  //         ?.toLowerCase()
  //         .includes(getName?.toLowerCase()),
  //     );
  //     setFilteredEmployee(filteredEmployee);
  //   } else {
  //     setFilteredEmployee(myEmployee);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getName, myEmployee]);

  // API call for fetching employees
  const fetchMyEmployeeAPI = ({ startDate, endDate, hiredBy }) => {
    // Ensure the dates are set correctly for "Tab 2"
    const query = {
      startDate: startDate,
      endDate: endDate,
      hiredBy,
    };
    const queryString = paramsToQueryString(query);
    const url = `${process.env.REACT_APP_API_BASE_URL}/book-history/client-employee?${queryString}`;

    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((response) => response.data.details.result[0]?.employee || []);
  };

  const { data, isLoading, error } = useQuery(
    ["myEmployees", { startDate, endDate, hiredBy: jwtDecode?._id, activeTab }],
    () =>
      fetchMyEmployeeAPI({
        startDate: activeTab === "2" ? dayjs().format("YYYY-MM-DD") : startDate,
        endDate: activeTab === "2" ? dayjs().format("YYYY-MM-DD") : endDate,
        hiredBy: userInfo?._id,
      }),
    {
      enabled: !!jwtDecode?._id,
    },
  );

  // Filtering logic as previously described
  useEffect(() => {
    if (activeTab === "1" && getName) {
      const filteredEmployee = data.filter((item) =>
        item.employeeDetails.name.toLowerCase().includes(getName.toLowerCase()),
      );
      setFilteredEmployee(filteredEmployee);
    } else {
      setFilteredEmployee(data);
    }
  }, [data, getName, activeTab]);

  const showModal = (employeeId) => {
    setActiveEmployeeId(employeeId);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // const activeEmployee = getEmployee?.hiredHistories?.find(
  //   (emp) => emp.id === activeEmployeeId
  // );
  //search
  const handleSearchkeywordOnChange = (e) => {
    setLoading(true);
    debounce(() => {
      setName(e.target.value);
      setLoading(false);
    }, 1000)();
  };
  const countDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start.toDateString() === end.toDateString()) {
      return "1";
    }

    const timeDifference = Math.abs(end - start);
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1; // Add 1 to include both start and end dates

    return `${dayDifference}`;
  };

  const chatSidebarStyle = {
    position: "fixed",
    right: isSidebarOpen ? "0px" : "-400px",
    width: "400px",
    height: "100vh",
    backgroundColor: "#fff",
    transition: "right 0.3s ease",
    zIndex: 1000,
    padding: "20px",
    borderRadius: "10px",
    background: "#58c8c87f",
  };

  const formStyle = {
    position: "fixed",
    bottom: "0px",
    width: "400px",
    backgroundColor: "#fff",
    zIndex: 1000,
    padding: "20px",
    borderRadius: "10px",
    background: "#58c8c87f",
    marginBottom: "20px",
  };

  useEffect(() => {
    function handleClickOutside(event) {
      const sidebarElement = document.getElementById("chatSidebar");
      if (sidebarElement && !sidebarElement.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getDistance = (from, to, unit) => {
    const R = 6371e3; // metres
    const φ1 = (from.lat * Math.PI) / 180; // φ, λ in radians
    const φ2 = (to.lat * Math.PI) / 180;
    const Δφ = ((to.lat - from.lat) * Math.PI) / 180;
    const Δλ = ((to.lng - from.lng) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    const meterToMile = d * 0.000621371192;
    if (unit === "miles") {
      return meterToMile;
    } else {
      return d;
    }
  };

  const userInfo = getUserInfo();
  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
      withCredentials: false,
      transports: ["websocket", "polling", "flashsocket"],
    });
    socket.on("connect", () => {
      console.log("Socket connected");
    });
    socket.on("location:move", (data) => {
      const parsedData = data;
      const userLatLong = [
        {
          lat: +userInfo.lat,
          lng: +userInfo.long,
        },
        {
          lat: parsedData.cords.latitude,
          lng: parsedData.cords.longitude,
        },
      ];
      // now calculate distance with pure js
      const distance = getDistance(userLatLong[0], userLatLong[1], "meter");
      setCalculatedDistance(
        getDistance(userLatLong[0], userLatLong[1], "miles"),
      );
      const userList = [
        {
          user: parsedData.sender,
          distance: distance,
          liveLoc: parsedData.cords,
        },
      ];
      setDistanceList(userList);
    });
    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
    return () => {
      console.log("Socket disconnecting");
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* Inner Dashboard Search Part Start */}
      <div>
        {/* <ChattSidebar
          setOpen={setIsSidebarOpen}
          open={isSidebarOpen}
          userInfo={employeeInfo}
          setUserInfo={setEmployeeInfo}
        /> */}
        <NewChat
          setOpen={setIsSidebarOpen}
          open={isSidebarOpen}
          userInfo={employeeInfo}
          setUserInfo={setEmployeeInfo}
        />
        {/* Other parts of your component */}
      </div>
      <section className="InnnerDashboardSearch">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="innerDashSearchItems d-flex align-items-center">
                <Link to="/client-dashboard">
                  <button className="innerdashboardBackButton">
                    <img
                      src="assets/frontend/images/InnerDashboard/arrow.png"
                      className="img-fluid"
                      alt="arrow"
                    />
                  </button>
                </Link>
                {/* <img
                  src="assets/frontend/images/InnerDashboard/mapSearch.png"
                  className="img-fluid"
                  alt="mapSearch"
                />
                <span className="innerDashSearchItemsSpan">
                  Booking History ({getEmployee?.total})
                </span> */}
                <div>
                  {/* // three tab here  */}
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <span
                        className={`nav-link border-1
                             ${activeTab === "1" ? "active" : ""}`}
                        style={{
                          cursor: "pointer",
                          background: activeTab === "1" ? "#58c8c8" : "",
                          color: activeTab === "1" ? "#fff" : "",
                        }}
                        onClick={() => setActiveTab("1")}
                      >
                        {t("my_employee")}
                      </span>
                    </li>
                    <li className="nav-item">
                      <span
                        className={`nav-link ${
                          activeTab === "2" ? "active" : ""
                        }`}
                        style={{
                          cursor: "pointer",
                          background: activeTab === "2" ? "#58c8c8" : "",
                          color: activeTab === "2" ? "#fff" : "",
                        }}
                        onClick={() => setActiveTab("2")}
                      >
                        {t("todays_employee")}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {activeTab === "1" ? (
              <div className="col-lg-3">
                <Col sm={24} md={24} lg={24}>
                  <RangePicker
                    name="dateRange"
                    // size="large"
                    placeholder={[t("start_date"), t("end_date")]}
                    value={[
                      startDate && dayjs(startDate),
                      endDate && dayjs(endDate),
                    ]}
                    label="Date Range"
                    style={{
                      boxShadow:
                        "6px 6px 12px #aaaaaa, -6px -6px 12px #ffffff ",
                      marginTop: "8px",
                      width: "100%",
                      borderRadius: "25px",
                    }}
                    onChange={(date, dateString) => {
                      setStartDate(dateString[0]);
                      setEndDate(dateString[1]);
                    }}
                    allowClear={true}
                  />
                </Col>
              </div>
            ) : null}
            <div className={activeTab === "1" ? "col-lg-3" : "d-none"}>
              <div className="innerDashboardRightSideFormWrapper d-flex align-items-center">
                <div className="InnerDashSearchCion">
                  <img
                    src="assets/frontend/images/InnerDashboard/SearchIcon.png"
                    alt="SearchIcon"
                  />
                </div>
                <input
                  onChange={handleSearchkeywordOnChange}
                  type="text"
                  className="form-control innerDashRightSideSearchBar"
                  placeholder={t("search_here")}
                  aria-label="First name"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dashboard2">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 DashboardColXL9">
              <div
                className="row"
                style={{
                  gutter: 16,
                  gap: 16,
                }}
              >
                <div className="col-lg-12">
                  <div className="card-group">
                    {isLoading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : filteredEmployee?.length ? (
                      <>
                        {filteredEmployee?.map((item, index) => {
                          return (
                            <div
                              key={item?._id}
                              className="col-lg-3 col-md-6 col-sm-12 mb-3" // Use Bootstrap grid system
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                className="card DashboardEmployeeCard"
                                style={{
                                  width: "303px",
                                }}
                              >
                                <Link
                                  className="text-decoration-none"
                                  to={`/employee-view-details/${item?.employeeId}`}
                                >
                                  <img
                                    style={{
                                      width: "100%", // Make images responsive
                                      height: 300,
                                      borderRadius: 15,
                                    }}
                                    src={
                                      item?.employeeDetails?.profilePicture
                                        ? process.env
                                            .REACT_APP_ASSETs_BASE_URL +
                                          "/" +
                                          item?.employeeDetails?.profilePicture
                                        : defaultImage
                                    }
                                    className="Dashboard2-card-img-top img-fluid" // Make the image responsive
                                    alt="custom-profile"
                                  />
                                </Link>
                                <div className="card-body Dashboard2CardbodyPaddingFixfor768">
                                  <h5
                                    className="card-title Dashboard2CardTItle"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginBottom: 0,
                                    }}
                                  >
                                    {item?.employeeDetails?.name}{" "}
                                  { activeTab === "2"? <BsFillChatLeftTextFill
                                  onClick={() => {
                                    toggleSidebar(item);
                                  }}
                                  style={{
                                    color: "#58c8c8",
                                    cursor: "pointer",
                                  }}
                                  size={20}
                                /> : null}
                                  </h5>
                                  <div className="row">
                                    <div className="col-lg-5 col-md-3">
                                      <div className="DashboardratingimgWraper">
                                        <img
                                          src="assets/frontend/images/Dashboardimages/dashboard2/Star 1.png"
                                          className="img-fluid"
                                          alt="custom-p"
                                        />
                                        <span className="Dashboard2Card_rating">
                                          {" "}
                                          {item?.employeeDetails?.rating}
                                        </span>
                                        <span className="Dashboard2Card_count">
                                          {/* {item?.employeeDetails?.rating || 5} */}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="dashboard2chefwrapper d-flex">
                                      <img
                                        src="assets/frontend/images/Dashboardimages/dashboard2/chef.png"
                                        className="img-fluid"
                                        alt="custom-position"
                                        style={{
                                          marginRight: "5px",
                                        }}
                                      />
                                      <span>
                                        {item?.employeeDetails?.positionName}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    className="dashboard2totalhourspan"
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <img
                                      src="assets/frontend/images/Dashboardimages/dashboard2/clock.png"
                                      className="img-fluid"
                                      alt="custom-clock"
                                    />{" "}
                                    {t("total_hours")}:{" "}
                                    {item?.employeeDetails?.totalWorkingHour} H
                                  </div>
                                  <div
                                    className="dashboard2totalhourspan"
                                    style={{
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <img
                                      src="assets/frontend/images/Dashboardimages/dashboard2/rate.png"
                                      class="img-fluid"
                                      alt="rate"
                                    ></img>
                                    {t("hourly_rate")}: {currencyType}
                                    {item?.employeeDetails?.hourlyRate}{" "}
                                    {t("per_hour")}
                                  </div>
                                  {activeTab === "2" && (
                                    <div className="d-flex justify-content-between">
                                      <div className="dashboard2chefwrapper text-center">
                                        <div
                                          className="dashboard2totalhourspan mx-auto my-auto"
                                          style={{
                                            paddingBottom: "5px",
                                          }}
                                        >
                                          <GiPathDistance
                                            color="red"
                                            className="mr-1"
                                          />{" "}
                                          {t("distance")}:{" "}
                                          {calculatedDistance
                                            ? calculatedDistance.toFixed(2) +
                                              " miles away"
                                            : getDistance(
                                                {
                                                  lat: +userInfo.lat,
                                                  lng: +userInfo.long,
                                                },
                                                {
                                                  lat: item?.employeeDetails
                                                    ?.lat,
                                                  lng: item?.employeeDetails
                                                    ?.long,
                                                },
                                                "miles",
                                              ).toFixed(2) + " miles away"}
                                        </div>
                                      </div>
                                      {distanceList?.length ? (
                                        <div>
                                          {/* // map icon */}
                                          {distanceList?.find(
                                            (emp) =>
                                              emp.user === item?.employeeId,
                                          )?.distance > 200 ? (
                                            <img
                                              src="/assets/navigate.gif"
                                              className="img-fluid"
                                              alt="custom-position"
                                              height={32}
                                              width={32}
                                              style={{
                                                marginRight: "5px",
                                                cursor: "pointer",
                                                marginTop: " -10px",
                                              }}
                                              onClick={() => {
                                                navigate(
                                                  "live-location/" +
                                                    item?.employeeId +
                                                    `?lat=${
                                                      distanceList?.find(
                                                        (emp) =>
                                                          emp.user ===
                                                          item?.employeeId,
                                                      )?.liveLoc?.latitude
                                                    }&long=${
                                                      distanceList?.find(
                                                        (emp) =>
                                                          emp.user ===
                                                          item?.employeeId,
                                                      )?.liveLoc?.longitude
                                                    }`,
                                                );
                                              }}
                                            />
                                          ) : (
                                            <span
                                              style={{
                                                background: "green",
                                                color: "#fff",
                                                padding: "3px",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              Arrived
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        <img
                                          src="/assets/navigate.gif"
                                          className="img-fluid"
                                          alt="custom-position"
                                          height={32}
                                          width={32}
                                          style={{
                                            marginRight: "5px",
                                            cursor: "pointer",
                                            marginTop: " -10px",
                                          }}
                                          onClick={() => {
                                            navigate(
                                              "live-location/" +
                                                item?.employeeId +
                                                `?lat=${item?.employeeDetails?.lat}&long=${item?.employeeDetails?.long}`,
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                  <div className="row">
                                    <div className="">
                                      <Button
                                        style={{
                                          background: "#58c8c8",
                                          color: "#fff",
                                        }}
                                      >
                                        <Link
                                          style={{
                                            color: "#fff",
                                            textDecoration: "none",
                                          }}
                                          to={`/booknow/${item?.employeeId}`}
                                          state={{
                                            positionId: {
                                              ...item?.employeeDetails,
                                              _id: item?.employeeId,
                                            },
                                          }}
                                        >
                                          {t("book_again")}
                                        </Link>
                                      </Button>
                                      <Button
                                        style={{
                                          background: "#58c8c8",
                                          color: "#fff",
                                        }}
                                        onClick={() => {
                                          showModal(item?.id);
                                          setActiveEmployee(item);
                                        }}
                                      >
                                        {t("booked_dates")}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="text-center text-danger">
                        {t("no_data_found")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ) : null} */}
        </div>
      </section>
      <BookingDates
        isModalVisible={isModalVisible}
        activeEmployee={activeEmployee}
        countDates={countDates}
        handleCancel={handleCancel}
        handleOk={handleOk}
        employeeId={employeeId}
      />
      {/* Chat Sidebar */}
      <br />
      <br />
      <br />
    </div>
  );
}

export default MyEmployee;
