import PolicyPage from "./Policy";

const contentArry = [
  {
    id: 1,
    heading: "1. Acceptance of Terms",
    text: 'By using the Wege website ("Site"), you agree to abide by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our services.',
  },
  {
    id: 2,
    heading: "2. Products and Services",
    text: "Wege offers a variety of apparel products for sale. By placing an order, you agree to purchase products for personal use and not for resale.",
  },
  {
    id: 3,
    heading: "3. Order Acceptance",
    text: "All orders are subject to acceptance. Wege reserves the right to refuse or cancel any order for any reason.",
  },
  {
    id: 4,
    heading: "4. Pricing and Payment",
    text: "Prices are listed in [currency] and are subject to change without notice. Payment is due upon order confirmation. Wege uses secure payment gateways to protect your financial information.",
  },
  {
    id: 5,
    heading: "5. Shipping and Delivery",
    text: "Wege strives to fulfill orders promptly. However, delivery times may vary. We are not responsible for any delays due to third-party carriers.",
  },
  {
    id: 6,
    heading: "6. Returns and Exchanges",
    text: "Refer to our Return and Exchange Policy for information on returns, exchanges, and refunds.",
  },
  {
    id: 7,
    heading: "7. User Conduct",
    text: "Users agree not to engage in any activity that disrupts or interferes with the proper functioning of the Site.",
  },
  {
    id: 8,
    heading: "8. Privacy Policy",
    text: "Refer to our Privacy Policy to understand how we collect, use, and safeguard your personal information.",
  },
  {
    id: 9,
    heading: "9. Governing Law",
    text: "These Terms and Conditions are governed by the laws of the United Arab Emirates.",
  },
  {
    id: 10,
    heading: "10. Changes to Terms & Conditions",
    text: "Wege reserves the right to modify or update these Terms and Conditions at any time. Changes will be effective upon posting on the Site.",
  },
  {
    id: 11,
    heading: "11. Contact Information",
    text: "For inquiries or concerns regarding these Terms and Conditions, contact us at",
    link: "support@wege.ae",
  },
];

const TermsAndConditions = () => {
  return (
    <PolicyPage
      title="Terms and Conditions"
      data={contentArry}
      update="Last Updated: 01/03/2024"
    />
  );
};

export default TermsAndConditions;
