import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { token } from "../../../../utils/authentication";
import NotFound from "../notFound/notFound";
import RequestDates from "./RequestDates";
import booked from "./booked.png";
import { useTranslation } from "react-i18next";

export default function EmployeeBookedHistory() {
  const { t } = useTranslation();
  const [bookedHistory, setBookedHistory] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeEmployee, setActiveEmployee] = useState([]);
  const [modalId, setModalId] = useState("");
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/notifications/details`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBookedHistory(data.details);
      });
  }, [refetch]);
  // console.log(bookedHistory);
  const countDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start.toDateString() === end.toDateString()) {
      return "1";
    }

    const timeDifference = Math.abs(end - start);
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1; // Add 1 to include both start and end dates

    return `${dayDifference}`;
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  return (
    <div>
      {/*<section className="InnnerDashboardSearch">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="innerDashSearchItems d-flex align-items-center">
                <Link to="/employee-home">
                  <button className="innerdashboardBackButton">
                    <img
                      src="assets/frontend/images/InnerDashboard/arrow.png"
                      className="img-fluid"
                      alt="arrow"
                    />
                  </button>
                </Link>
                <img
                  src={booked}
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                  className="img-fluid"
                  alt="mapSearch"
                />
                <span className="innerDashSearchItemsSpan">{t("booked_history")}</span>
              </div>
            </div>
          </div>
        </div>
      </section>*/}
      <div
        className="row"
        style={
          {
            // marginTop: "50px",
          }
        }
      >
        {bookedHistory?.length <= 0 && (
          <NotFound
            title={t("no_booked_history")}
            description={t("you_have_not_been_booked.")}
          />
        )}

        {/* {bookedHistory?.length > 0 && (
          <div className="mx-auto w-50 m-5 p-5">
            {bookedHistory?.map((employeeHiredHistory, index) => {
              return (
                <div
                  className="blur-container"
                  style={
                    {
                      // backgroundImage: `url(https://images.unsplash.com/photo-1414235077428-338989a2e8c0)`,
                      // backgroundImage: "black",
                    }
                  }
                >
                  <div className="content">
                    <p>{employeeHiredHistory?.text}</p>
                    <div
                      className="details"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        className=""
                        style={{
                          background: "#58c8c8",
                          color: "#fff",
                        }}
                        onClick={() => {
                          showModal();
                          setActiveEmployee(employeeHiredHistory);
                          setModalId(employeeHiredHistory?._id);
                        }}
                      >
                        Details
                      </Button>
                    </div>
                  </div>
                  <div>
                    <RequestDates
                      isModalVisible={isModalVisible}
                      activeEmployee={activeEmployee}
                      countDates={countDates}
                      handleCancel={handleCancel}
                      handleOk={handleOk}
                      modalId={modalId}
                      setRefetch={setRefetch}
                      refetch={refetch}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )} */}
        {bookedHistory?.length > 0 && (
          <div className="col-md-6 col-lg-6 mb-4">
            {bookedHistory?.map((employeeHiredHistory, index) => {
              return (
                <Card
                  key={index}
                  style={{
                    height: "10rem",
                    borderRadius: "10px",
                    width: "100%",
                    backgroundImage:
                      "url(https://source.unsplash.com/featured/?restaurant,dining)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  className="mb-4"
                >
                  <Card.Body>
                    <p style={{ color: "black" }}>
                      {employeeHiredHistory?.text}
                    </p>

                    <div className="">
                      <div
                        className="details"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          className=""
                          style={{
                            background: "#58c8c8",
                            color: "#fff",
                          }}
                          onClick={() => {
                            showModal();
                            setActiveEmployee(employeeHiredHistory);
                            setModalId(employeeHiredHistory?._id);
                          }}
                        >
                          {t("details")}
                        </Button>
                      </div>
                    </div>
                    <div>
                      <RequestDates
                        isModalVisible={isModalVisible}
                        activeEmployee={activeEmployee}
                        countDates={countDates}
                        handleCancel={handleCancel}
                        handleOk={handleOk}
                        modalId={modalId}
                        setRefetch={setRefetch}
                        refetch={refetch}
                      />
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
