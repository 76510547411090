/* eslint-disable no-unused-vars */
import dayjs from "dayjs";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaGlobe } from "react-icons/fa";
import { IoCall, IoMailOpenSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Loader from "../../../common/Loader";
import { ContextApi } from "../../../hooks/ContextApi";
import { token } from "../../../utils/authentication";
import "./print-invoice.css";
import circleBg from "./card.svg";
import {
  getUserCountry,
  getUserCurrency,
} from "../../../utils/commonFunctions";
export default function InvoiceDetails3() {
  const { id } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [clientBankInfo, setclientBankInfo] = useState(null);
  const componentRef = React.createRef();
  // console.log("invoiceData: ", invoiceData);
  const currencyType = getUserCurrency();
  const country = getUserCountry();
  console.log("data: ", country);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/check-in-check-out-histories/${id}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token()}`, // Call the function to get the token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoiceData(data.details);
        } else {
          console.error("Failed to fetch invoice details");
        }
      })
      .catch((error) => {
        console.error("Error while fetching invoice details: ", error);
      });
  }, [id]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/bank-info/${invoiceData?.clientId}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token()}`, // Call the function to get the token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setclientBankInfo(data.details);
        } else {
          console.error("Failed to fetch invoice details");
        }
      })
      .catch((error) => {
        console.error("Error while fetching invoice details: ", error);
      });
  }, [invoiceData?.clientId]);

  const formatDate = (date) => {
    return moment(date).format("MMM D, YYYY");
  };

  // eslint-disable-next-line no-unused-vars
  const formatDateRange = (fromDate, toDate) => {
    const formattedFromDate = moment(fromDate).format("MMM D");
    const formattedToDate = moment(toDate).format("MMM D");
    const isSameYear = moment(fromDate).isSame(toDate, "year");
    const yearString = isSameYear ? `, ${moment(fromDate).format("YYYY")}` : "";
    return `From ${formattedFromDate} to ${formattedToDate}${yearString}`;
  };

  function formatDuration(durationInMillis) {
    const duration = moment.duration(durationInMillis);
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours} hours `;
    }
    if (minutes > 0 || (hours > 0 && seconds === 0)) {
      formattedDuration += `${minutes} mins `;
    }
    if (seconds > 0 || (hours === 0 && minutes === 0)) {
      formattedDuration += `${seconds} sec`;
    }

    return formattedDuration.trim(); // Remove any trailing whitespace
  }

  return (
    <div>
      {invoiceData ? (
        <>
          <div
            className="text-center"
            style={{
              // backgroundColor: "#58c8c8",
              color: "white",
              // padding: "10px",
            }}
          >
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  className="mt-5 px-4 text-center rounded"
                  style={{
                    backgroundColor: "#58c8c8",
                    color: "white",
                    border: "1px solid #58c8c8",
                    textDecoration: "none",
                  }}
                >
                  Print Now
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div
            className="container mx-auto p-3 m-3 rounded"
            style={{
              border: "1px solid #58c8c8",
              width: "800px",
              backgroundColor: "#FFF",
              fontWeight: 600, // or fontWeight: "bold" depending on your preference
            }}
            ref={componentRef}
          >
            {/* header */}
            <div className="d-flex justify-content-between align-items-center">
              <img
                src={`https://www.plagit.com/assets/frontend/images/indexImages/logo_full.png`}
                alt="logo"
                className="w-25"
              />

              <h1 className="">INVOICE</h1>
            </div>

            {/* body */}

            <div
              className="from m-5"
              style={{
                lineHeight: "1.3",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h3 className="">Payable To</h3>
                <small>Plagit </small>
                <br />
                <small>
                  {country === "United Kingdom"
                    ? "London, W1B 5AW"
                    : "202 Souk Al Bahar Saaha C, Downtown Dubai"}
                </small>
                <br />
                {/* <small>VAT number: 450105738</small> */}
              </div>

              <div>
                <p>Invoice No: {invoiceData?.invoiceNumber}</p>
                <p>Date: &nbsp;{formatDate(invoiceData?.createdAt)}</p>
              </div>
            </div>
            <div className="to m-5">
              <div className="row mb-4 mt-5">
                <div className="col-2">
                  <h4>To</h4>
                </div>
                <div
                  className="col-6"
                  style={{
                    lineHeight: "1",
                  }}
                >
                  <p style={{ margin: "0" }}>
                    {invoiceData?.restaurantDetails?.restaurantName}
                  </p>
                  <p style={{ margin: "0" }}>
                    {clientBankInfo?.companyName &&
                      `${clientBankInfo?.companyName}`}
                  </p>
                  <p style={{ margin: "0" }}>
                    {clientBankInfo?.companyRegisterNumber &&
                      `Reg No: ${clientBankInfo?.companyRegisterNumber}`}
                  </p>{" "}
                  <p style={{ margin: "0" }}>
                    {invoiceData?.restaurantDetails?.restaurantAddress}
                  </p>
                  <p style={{ margin: "0" }}>
                    {clientBankInfo?.phoneNumber &&
                      `${clientBankInfo?.phoneNumber}`}
                  </p>
                  <p style={{ margin: "0" }}>
                    {invoiceData?.restaurantDetails?.email &&
                      `${invoiceData?.restaurantDetails?.email}`}
                  </p>
                </div>
                <div className="col-2 ">
                  <img
                    src={circleBg}
                    alt="circleBg"
                    style={{
                      position: "absolute",
                      rotate: "180deg",
                      top: "0",
                      // marginTop: "-200px",
                      width: "200px",
                      overflow: "hidden",
                      marginLeft: "90px",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table
                className="table"
                style={
                  {
                    // border: "1px solid #58c8c8",
                  }
                }
              >
                <thead
                  style={{
                    backgroundColor: "#58C8C8",
                    padding: "10px",
                    color: "white",
                    border: "none",
                    fontSize: "12px",
                  }}
                >
                  <tr className="text-left">
                    <th>EMPLOYEE NAME</th>
                    <th>POSITION</th>
                    <th>RATE</th>
                    <th>TOTAL HOUR</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <small>{invoiceData?.employeeDetails?.name}</small>
                    </td>
                    <td>
                      <small>
                        {invoiceData?.employeeDetails?.positionName}
                      </small>
                    </td>
                    <td>
                      <small>
                        {currencyType}
                        {invoiceData?.employeeDetails.hourlyRate}
                      </small>{" "}
                    </td>
                    <td>
                      <small>
                        {/* {moment.duration(invoiceData?.workedHour).humanize()} */}
                        {formatDuration(invoiceData?.workedHour)}{" "}
                      </small>
                    </td>
                    <td>
                      <small>
                        {currencyType}
                        {invoiceData?.clientAmount}
                      </small>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                style={{
                  lineHeight: "0.5",
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "13px",
                  alignItems: "flex-end",
                  marginRight: "50px",
                }}
              >
                <p>
                  VAT:
                  {invoiceData?.vat} % = {currencyType} {invoiceData?.vatAmount}
                </p>
                <p>
                  PLATFORM FEE: {currencyType}
                  {invoiceData?.platformFee}
                </p>

                <p
                  style={{
                    borderTop: "3px solid #05263B",
                    // marginTop: "10px",

                    fontSize: "15px",
                    paddingTop: "5px", // Adjust this value as needed
                  }}
                >
                  TOTAL: {currencyType}
                  {invoiceData?.totalAmount}
                </p>
              </div>
            </div>

            <hr />
            <div
              style={{
                backgroundColor: "#58C8C8",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#03263B",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                  margin: "10px",
                  alignItems: "center",
                  borderRadius: "30px",
                }}
              >
                <p>
                  <a
                    href="https://www.plagit.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none text-white"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "-15px",
                    }}
                  >
                    {" "}
                    <FaGlobe className="m-1" />
                    www.plagit.com
                  </a>
                </p>
                {/* <p>
                  <a
                    href="https://www.plagit.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none text-white"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "-15px",
                    }}
                  >
                    {" "}
                    <IoCall className="m-1" />
                    {country === "United Arab Emirates"
                      ? {t("privacy_notice_des_8_4")}
                      : "07960966110"}
                  </a>
                </p> */}
                <p>
                  <a
                    href="https://www.plagit.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none text-white"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "-15px",
                    }}
                  >
                    {" "}
                    <IoMailOpenSharp className="m-1" />
                    {country === "United Arab Emirates"
                      ? "info@plagit.com"
                      : "info@plagit.com"}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
