import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login_style.scss";

import { Form, Input } from "antd";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { loginHandler } from "../../../api/auth";
import { ContextApi } from "../../../hooks/ContextApi";
import { responseNotification } from "../../../utils/notifcation";
import { useTranslation } from "react-i18next";
import PlagitLogo from "./images/plagit_logo.png";
import CoverBg from "./images/banner_image.png";
import PlayStoreBtn from "./images/play_store_btn.png";
import AppleStoreBtn from "./images/apple_store_btn.png";
import AppsDownloadText from "./images/apps_download_text.png";

function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [saveCredentials, setSaveCredentials] = useState(false);

    const [loading, setLoading] = useState(false);
    const [getError, setError] = useState();

    const [form] = Form.useForm();
    const { setShouldRefetchShortList } = useContext(ContextApi);

    const onFinish = async (values) => {
        // Get the value from the input field
        const emailOrUserId = values.email;

        const receivedLoginFields = {
            password: values?.password,
        };

        const regex = /@/;

        if (regex.test(emailOrUserId)) {
            receivedLoginFields.email = values?.email;
        } else {
            receivedLoginFields.userIdNumber = values?.email;
        }

        if (receivedLoginFields) {
            setLoading(true);

            await loginHandler(receivedLoginFields)
                .then((res) => res.json())
                .then((res) => {
                    if (res.statusCode === 200) {
                        responseNotification(res?.message, "success");
                        form.resetFields();

                        localStorage.setItem("accessToken", res?.token);

                        const jwtDecode = jwt_decode(res?.token);
                        if (jwtDecode?.role === "EMPLOYEE") {
                            // get current lat long
                            navigator.geolocation.getCurrentPosition((position) => {
                                const lat = position.coords.latitude;
                                const long = position.coords.longitude;
                                const updateLatLong = fetch(
                                    `${process.env.REACT_APP_API_BASE_URL}/users/update-location`,
                                    {
                                        method: "PUT",
                                        headers: {
                                            Authorization: `Bearer ${res?.token}`,
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            id: jwtDecode?._id,
                                            lat,
                                            long,
                                        }),
                                    }
                                );
                                return updateLatLong;
                            });
                        }
                        if (
                            jwtDecode &&
                            (jwtDecode.admin || jwtDecode.hr || jwtDecode.isMhEmployee)
                        ) {
                            navigate("/admin/dashboard");
                            setLoading(false);
                        } else if (jwtDecode && jwtDecode.client) {
                            navigate("/client-dashboard");
                            setLoading(false);
                            setShouldRefetchShortList(true);
                        } else if (jwtDecode && jwtDecode.employee) {
                            navigate("/employee-home");
                            setLoading(false);
                        } else if (jwtDecode && jwtDecode.role === "TEMPUSER") {
                            navigate("/employee-profile");
                            setLoading(false);
                        } else {
                            navigate("/login");
                            setLoading(false);
                        }
                    } else if (res?.statusCode === 400) {
                        setError(res?.message);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setError(res?.message);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        // Load saved credentials from localStorage on component mount
        const savedCredentials = localStorage.getItem("savedCredentials");
        if (savedCredentials && form) {
            const [savedEmail, savedPassword] = savedCredentials.split(":");
            console.log(savedEmail, savedPassword);

            form.setFieldsValue({
                email: savedEmail,
                password: savedPassword,
            });

            setSaveCredentials(true); // Set the state to true when credentials are loaded
        }
    }, [form]); // Include form in the dependency array

    const handleSaveCredentialsChange = (e) => {
        const isChecked = e.target.checked;
        setSaveCredentials(isChecked);

        // Save credentials to localStorage when "Save Password" checkbox is clicked
        if (isChecked && form) {
            const emailValue = form.getFieldValue("email");
            const passwordValue = form.getFieldValue("password");
            const credentialsString = `${emailValue}:${passwordValue}`;
            localStorage.setItem("savedCredentials", credentialsString);
        } else {
            // Clear saved credentials when "Save Password" is unchecked
            localStorage.removeItem("savedCredentials");
        }
    };
    return (
        <section className="total_wrapper">
            <Helmet>
                <meta
                    name="description"
                    content=" Access the PLAGIT portal. Log in to connect with top-tier talent, manage job postings, and ensure your company's recruitment success."
                />
                <title>Login | PLAGIT: Your Gateway to Talent Excellence</title>
            </Helmet>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-6 Login_Page_left_part">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Link to="/">
                                        <img
                                            src="assets/frontend/images/registrationFormImages/clientAndEmployee/Group 685.png"
                                            className="img-fluid"
                                            alt="image"
                                        />
                                    </Link>
                                    <Link className="top_logo_left" to="/">
                                        <img src={PlagitLogo} className="img-fluid" alt="logo" />
                                    </Link>
                                </div>
                                <div className="col-lg-12 helps_you_hire">
                                    <h4>
                                        PLAGIT {t("helps_you_hire_great")} &amp; {t("experienced_workers_at_a_moment's_notice")}
                                    </h4>
                                </div>

                                <div className="col-lg-12 text-center">
                                    <img
                                        className="img-fluid cover_image"
                                        src={CoverBg}
                                        alt="cover"
                                    />
                                </div>
                            </div>
                            <div className="row footer_apps_download_link_btn">
                                <div className="col-lg-7">
                                    <img
                                        className="img-fluid apps_download_btn_image_text"
                                        src={AppsDownloadText}
                                        alt=""
                                    />
                                    {/* <div className="download_app_message_div">
                                        <div className="plagit_app">
                                            plagit app
                                        </div>
                                        <div className="available_now_text">
                                            Is available now on android and ios
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-lg-5 apps_download_brn_group">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.invain.mh&pli=1"
                                        target="_blank"
                                        className="play_store_btn"
                                    >
                                        <img className="img-fluid" src={PlayStoreBtn} alt="cover" />
                                    </a>
                                    <a
                                        href="https://apps.apple.com/us/app/plagit/id6446052294"
                                        target="_blank"
                                        className="apple_store_btn"
                                    >
                                        <img
                                            className="img-fluid"
                                            src={AppleStoreBtn}
                                            alt="cover"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 Login_page_right_part">
                        <div className="container custom_for_320">
                            <div className="row right_part_wrapper ">
                                <div className="first_eclips_img">
                                    <img
                                        className="img-fluid"
                                        src="assets/frontend/images/login_page_images/Ellipse 70.png"
                                        alt="Ellipse"
                                    />
                                </div>
                                <div className="form_part ">
                                    <div className="card custom_card " style={{ width: "88%" }}>
                                        <div className="card-body card-body_custom">
                                            <h5 className="card-title-custom text-center ">
                                                {t("welcome_back")}
                                            </h5>
                                            <div className="form_wrapper">
                                                <Form
                                                    className="ant-form ant-form-vertical"
                                                    layout="vertical"
                                                    onFinish={onFinish}
                                                    form={form}
                                                >
                                                    <div className="mb-3">
                                                        <Form.Item
                                                            name="email"
                                                            hasFeedback
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        "please_enter_your_email_or_userid"
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                size="large"
                                                                prefix={
                                                                    <img
                                                                        src="assets/frontend/images/login_page_images/Profile.png"
                                                                        className="img-fluid"
                                                                        alt="Profile"
                                                                    />
                                                                }
                                                                placeholder={t("enter_email_or_userid")}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="mb-3">
                                                        <Form.Item
                                                            name="password"
                                                            hasFeedback
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("please_enter_your_password"),
                                                                },
                                                            ]}
                                                        >
                                                            <Input.Password
                                                                size="large"
                                                                prefix={
                                                                    <img
                                                                        src="assets/frontend/images/login_page_images/Icon.png"
                                                                        className="img-fluid password_logo"
                                                                        alt="Password Icon"
                                                                    />
                                                                }
                                                                placeholder={t("enter_password")}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="mb-3 form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="exampleCheck1"
                                                            checked={saveCredentials}
                                                            onChange={handleSaveCredentialsChange}
                                                        />
                                                        <div className="save_forget_pass_wrapper d-flex justify-content-between">
                                                            <label
                                                                className="form-check-label check_me_out_custom"
                                                                htmlFor="exampleCheck1"
                                                            >
                                                                {t("save_password")}
                                                            </label>
                                                            <label
                                                                className="form-check-label "
                                                                htmlFor="exampleCheck1"
                                                            >
                                                                <Link to="/forget-password">
                                                                    <a
                                                                        className="forget_password_custom"
                                                                        href="#"
                                                                    >
                                                                        {t("forget_password")}
                                                                    </a>
                                                                </Link>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {getError ? (
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="error-message">
                                                                    <p className="text-danger">{getError}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : undefined}

                                                    {/* <button type="submit" className="btn login_button">Login</button> */}

                                                    <Form.Item>
                                                        <button
                                                            disabled={loading}
                                                            className="btn login_button"
                                                            type="submit"
                                                        >
                                                            {!loading && t("login")}
                                                            {loading && (
                                                                <span
                                                                    className="indicator-progress"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    {t("please_wait...")}
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accountandregisterwrapper text-center">
                                    <span className="donthaveaccounttext">
                                        {t("don’t_have_an_account?")}
                                    </span>
                                    <Link to="/client-register">{t("register")}</Link>
                                </div>
                                <div className="last_eclips_img_wrapper">
                                    <img
                                        src="assets/frontend/images/login_page_images/Ellipse 69.png"
                                        alt="Ellipse"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
