import React, { useContext } from "react";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ContextApi } from "../../../hooks/ContextApi";
import AcademyCard from "./AcademyCard";
import bartender from "./images/bartender.png";
import path1 from "./images/path217.png";
import path2 from "./images/path219.png";

const BarTending = () => {
  const { currencyType } = useContext(ContextApi);

  return (
    <div
      className="card  pb-5 pt-3 container my-5 "
      style={{ borderRadius: "20px" }}
    >
      <div className="d-flex justify-content-between my-5">
        <Link
          to="/mh-academy"
          style={{ textDecoration: "none", color: "gray" }}
        >
          <p className="mt-3">
            <BsFillArrowLeftCircleFill
              style={{ color: "#58c8c8", fontSize: "30px" }}
            />{" "}
            Back
          </p>
        </Link>
        <div className="d-flex">
          <img src={bartender} alt="the" height={50} />
          <p
            className="mt-3 mx-1"
            style={{ fontSize: "20px", fontWeight: 500 }}
          >
            BARTENDING COURSES
          </p>
        </div>
        <p></p>
      </div>

      <p style={{ color: "#7B7B7B", fontWeight:"bold" }} className="text-center">
      This training course is designed to guide our students through their initial journey behind the bar, with the primary goal of sparking their passion for our profession, while imparting essential values of hospitality. We offer bartenders the opportunity to solidify their foundational knowledge in bar service and the art of crafting
cocktails.      </p>

      <div class="row gy-5 mt-5">
        <div class="col-lg-4 ">
          {/* <AcademyCard
            course={"EXPRESSIVE AND EFFICIENT TRAINING PROGRAM"}
            price={
              <div className="d-flex" style={{ marginLeft: "15%" }}>
                <img src={path2} alt="the" height={15} className="mt-1" />
                <p
                  style={{
                    color: "#7B7B7B",
                    font: "Montserrat",
                    fontWeight: 400,
                    fontSize: "15px",
                    marginLeft: "8%",
                    marginRight: "10%",
                  }}
                >
                  Price: <strong>{currencyType}150</strong>
                </p>
              </div>
            }
            duration={
              <div className="d-flex" style={{ marginLeft: "15%" }}>
                <img src={path1} alt="the" height={15} className="mt-1" />
                <p
                  style={{
                    color: "#7B7B7B",
                    font: "Montserrat",
                    fontWeight: 400,
                    fontSize: "15px",
                    marginLeft: "10%",
                    marginRight: "10%",
                  }}
                >
                  Duration: <strong>10 hours spread over 3 days</strong>
                </p>
              </div>
            }
            description={`Our exclusive package offers a fast-track and intelligent approach to cater to the needs of professionals already established in the industry. This course provides intensive training sessions tailored to our customers' specific requirements, ensuring rapid skill development and growth.`}
            example={`Sample Topics Covered:
1. Classic Cocktail Training: Mastering the Methods and Techniques
2. Step of Service and Upselling Training: Enhancing Customer Experiences
3. Practice Sessions: Reinforcing Skills Through Hands-on Application`}
          /> */}
        </div>

        <div class="col-lg-4 ">
          <AcademyCard
            course={"ESSENTIALS OF BARTENDING"}
            price={
              <div className="d-flex" style={{ marginLeft: "15%" }}>
                <img src={path2} alt="the" height={15} className="mt-1" />
                <p
                  style={{
                    color: "#7B7B7B",
                    font: "Montserrat",
                    fontWeight: 400,
                    fontSize: "15px",
                    marginLeft: "8%",
                    marginRight: "10%",
                  }}
                >
                  Price: <strong>{currencyType}400</strong>
                </p>
              </div>
            }
            duration={
              <div className="d-flex" style={{ marginLeft: "15%" }}>
                <img src={path1} alt="the" height={15} className="mt-1" />
                <p
                  style={{
                    color: "#7B7B7B",
                    font: "Montserrat",
                    fontWeight: 400,
                    fontSize: "15px",
                    marginLeft: "10%",
                    marginRight: "10%",
                  }}
                >
                  Duration: <span style={{
                    fontWeight: 600,
                  }}>30 HOURS </span>
                  
                </p>
              </div>
            }
            description={`Embark on a thrilling journey into the world of bartending with our comprehensive essentials course. Designed to ignite passion and instill a deep appreciation for the values of hospitality, this program serves as the perfect foundation for aspiring bartenders.`}
            // example={``}
          />
        </div>
        <div class="col-lg-4 ">
          {/* <AcademyCard
            course={"CUSTOMIZED BARTENDING ESSENTIALS COURSE"}
            price={
              <div className="d-flex" style={{ marginLeft: "15%" }}>
                <img src={path2} alt="the" height={15} className="mt-1" />
                <p
                  style={{
                    color: "#7B7B7B",
                    font: "Montserrat",
                    fontWeight: 400,
                    fontSize: "15px",
                    marginLeft: "8%",
                    marginRight: "10%",
                  }}
                >
                  Price: <strong>Based on the tailored program offered</strong>
                </p>
              </div>
            }
            duration={
              <div className="d-flex" style={{ marginLeft: "15%" }}>
                <img src={path1} alt="the" height={15} className="mt-1" />
                <p
                  style={{
                    color: "#7B7B7B",
                    font: "Montserrat",
                    fontWeight: 400,
                    fontSize: "15px",
                    marginLeft: "10%",
                    marginRight: "10%",
                  }}
                >
                  Duration: <strong>Upon customer request</strong>
                </p>
              </div>
            }
            description={`Embark on a thrilling journey into the world of bartending with our comprehensive essentials course. Designed to ignite passion and instill a deep appreciation for the values of hospitality, this program serves as the perfect foundation for aspiring bartenders.`}
            example={`Sample Topics Covered: 1. Classic Cocktail Training: Mastering the Methods and Techniques 2. Step of Service and Upselling Training: Enhancing Customer Experiences
`}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default BarTending;
