import React, { Fragment, useEffect, useState } from "react";
import { BiCamera } from "react-icons/bi";
import socialImage from "./social.png";
import PostImages1 from "./SocialFeed/images/post001.jpg";
import PostFeedCard from "./SocialFeed/PostFeedCard";
import EmployeeCard from "./SocialFeed/EmployeeCard";
import PostFeedCardMulti from "./SocialFeed/PostFeedCardMulti";
import { token } from "../../../utils/authentication";
import axios from "axios";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import Loader from "../../loadar/Loader";
import default_profile from '../../../assets/images/Client.jpg';

export default function SocialFeeds() {
    const params = new URLSearchParams(window.location.search);
    const type = params.get("type");
    const jwtDecode = jwtTokenDecode();
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [edit, setEdit] = useState(true);
    const [selectedPost, setSelectedPost] = useState({});

    // Function to handle post updates
    const handleUpdatePost = async (updatedPost) => {
        try {
            // Update the local state with the updated post
            setPosts(
                posts.map((post) => (post._id === updatedPost._id ? updatedPost : post))
            );
        } catch (error) {
            console.error("Failed to update post:", error);
        }
    };

    // Function to handle post updates
    const handleDeletePost = async (post) => {
        try {
            // Update the local state with the updated post
            const allpost = [...posts];
            const data = allpost.filter((item) => item?._id != post?._id);
            console.log({ data });
            setPosts(data);
        } catch (error) {
            console.error("Failed to update post:", error);
        }
    };

    const fetchPostListData = async () => {
        setLoading(true);
        let endpint;
        if (type == "mypost") {
            endpint = `${process.env.REACT_APP_API_BASE_URL}/social-feed?user=${jwtDecode?._id}&limit=${pageSize}&page=${currentPage}`;
        } else {
            endpint = `${process.env.REACT_APP_API_BASE_URL}/social-feed?active=true&limit=${pageSize}&page=${currentPage}`;
        }
        await axios
            .get(endpint, {
                headers: {
                    Authorization: `Bearer ${token()}`,
                },
            })
            .then((res) => {
                if (res.data?.status == "success") {
                    const newPosts = res.data?.socialFeeds?.posts || [];

                    if (currentPage == 1) {
                        setPosts(newPosts);
                    } else {
                        setPosts((prev) => {
                            // Merge old and new posts and filter out duplicates by _id
                            const allPosts = [...prev, ...newPosts];
                            const uniquePosts = Array.from(
                                new Set(allPosts.map((post) => post._id))
                            ).map((id) => allPosts.find((post) => post._id === id));
                            return uniquePosts;
                        });

                        if (newPosts.length < pageSize) {
                            setHasMore(false);
                        }
                    }
                }
            });
        setLoading(false);
    };

    useEffect(() => {
        setCurrentPage(1);
        fetchPostListData();
    }, [pageSize, currentPage, type]);

    // console.log("posts", posts);

    return (
        <div className="container-fluid mx-auto social_feed_container">
            <div className="row">
                <div className="col-12 col-lg-9 mx-auto p-3 p-lg-4">
                    <div className="row">
                        <div className="col-lg-7 order-lg-1 order-2">
                            <InfiniteScroll
                                dataLength={posts?.length || 0}
                                next={() => setCurrentPage((prevPage) => prevPage + 1)}
                                hasMore={hasMore}
                                loader={<Loader />}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>You have seen all post.</p>
                                }
                            >
                                {posts?.map((item, i) => (
                                    <PostFeedCardMulti
                                        key={i}
                                        userName={
                                            item?.user?.role == "CLIENT"
                                                ? item?.user?.restaurantName
                                                : item?.user?.name
                                        }
                                        userImage={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item?.user?.profilePicture}`}
                                        postTime={item?.createdAt}
                                        content={item?.content}
                                        postContentImg={item?.media}
                                        post={item}
                                        handleUpdatePost={handleUpdatePost}
                                        setEdit={setEdit}
                                        setSelectedPost={setSelectedPost}
                                        setCurrentPage={setCurrentPage}
                                        handleDeletePosts={handleDeletePost}
                                    />
                                ))}
                            </InfiniteScroll>
                        </div>
                        <div className="col-lg-5 order-lg-2 order-1 mb-4">
                            <CreatePostCard
                                setCurrentPage={setCurrentPage}
                                edit={edit}
                                selectedPost={selectedPost}
                                setSelectedPost={setSelectedPost}
                                handleUpdatePost={handleUpdatePost}
                                fetchPostListData={fetchPostListData}
                                post={posts}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CreatePostCard({
    setCurrentPage,
    edit,
    selectedPost,
    setSelectedPost,
    handleUpdatePost,
    post,
    fetchPostListData,
}) {
    const jwtDecode = jwtTokenDecode();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [content, setContent] = useState("");
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    function getFileType(file) {
        if (!file) {
            return "unknown";
        }

        const fileType = file.type;

        if (fileType.startsWith("image/")) {
            return "image";
        } else if (fileType.startsWith("video/")) {
            return "video";
        }

        return "unknown";
    }

    const handleUpload = async (e) => {
        setLoading(true);
        const file = e.target.files[0];

        if (!file) {
            alert("No file selected.");
            return;
        }

        setSelectedFiles((prev) => [...prev, file]);

        const formData = new FormData();
        formData.append("file", file);

        await axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/commons/upload-file`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token()}`,
                    },
                }
            )
            .then((res) => {
                if (res.data?.status == "success") {
                    setFiles((prev) => [
                        ...prev,
                        { url: res.data?.data?.file, type: getFileType(file) },
                    ]);
                } else {
                    toast.error("Invalid File Type");
                }
            })
            .catch((err) => toast.error("Invalid File Type"));

        setLoading(false);
    };

    const handleUploadPost = async () => {
        setLoading(true);

        if (selectedPost && Object.keys(selectedPost).length > 0) {
            await axios
                .put(
                    `${process.env.REACT_APP_API_BASE_URL}/social-feed/update/${selectedPost?._id}`,
                    {
                        content: content,
                        media: files,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token()}`,
                        },
                    }
                )
                .then(async (res) => {
                    if (res.data?.status == "success") {
                        await fetchPostListData();
                        await toast.success("Post Updated");
                        // const updatedPost = {
                        //   ...post,
                        //   content: res.data?.socialFeed?.content,
                        //   media: res.data?.socialFeed?.media,
                        // };

                        // handleUpdatePost(updatedPost);
                        await setSelectedPost();
                        await setContent("");
                        await setFiles([]);
                        await setSelectedFiles([]);
                        await setCurrentPage(1);
                    }
                })
                .catch((err) => toast.error("Invalid File Type"));
        } else {
            await axios
                .post(
                    `${process.env.REACT_APP_API_BASE_URL}/social-feed/create`,
                    {
                        content: content,
                        media: files,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token()}`,
                        },
                    }
                )
                .then(async (res) => {
                    if (res.data?.status == "success") {
                        await setCurrentPage(1);
                        await fetchPostListData();
                        await toast.success("Post Uploaded");
                        await setSelectedPost();
                        await setContent("");
                        await setFiles([]);
                        await setSelectedFiles([]);
                    }
                })
                .catch((err) => toast.error("Invalid File Type"));
        }

        setLoading(false);
    };

    useEffect(() => {
        if (selectedPost && Object.keys(selectedPost).length > 0) {
            setContent(selectedPost?.content);
            setFiles(selectedPost?.media);
        } else {
            setContent("");
            setFiles([]);
        }
    }, [selectedPost]);
    return (
        <div style={styles.createPostCard}>
            <div style={styles.createPostHeader}>
                {jwtDecode?.profilePicture ? (
                    <img
                        src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`}
                        alt={
                            jwtDecode?.role == "CLIENT"
                                ? jwtDecode?.restaurantName
                                : jwtDecode?.name
                        }
                        style={styles.userImage}
                    />
                ) : (
                    <img
                        src={default_profile}
                        alt={
                            jwtDecode?.role == "CLIENT"
                                ? jwtDecode?.restaurantName
                                : jwtDecode?.name
                        }
                        style={styles.userImage}
                    />
                )}

                <span>
                    {jwtDecode?.role == "CLIENT"
                        ? jwtDecode?.restaurantName
                        : jwtDecode?.name}
                </span>
            </div>
            <textarea
                style={styles.textarea}
                placeholder="Type here..."
                onChange={(e) => setContent(e.target.value)}
                value={content}
            ></textarea>
            <div style={styles.createPostActions}>
                <ul className="list-group mb-3">
                    {files.map((file, index) => (
                        <li
                            style={{ fontSize: "12px", position: "relative" }}
                            key={index}
                            className="list-group-item d-flex align-items-center gap-2"
                        >
                            <div>
                                {file.type === "image" ? (
                                    <img
                                        src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${file.url}`}
                                        alt=""
                                        width={"30px"}
                                        height={"30px"}
                                    />
                                ) : (
                                    "📹"
                                )}
                            </div>{" "}
                            <div>{file.url}</div>
                            <div
                                style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: 0,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    const allfile = [...files];
                                    const upFile = allfile?.filter(
                                        (item) => item?.url != file.url
                                    );
                                    setFiles(upFile);
                                }}
                            >
                                x
                            </div>
                        </li>
                    ))}{" "}
                </ul>
                {files.length <= 5 && (
                    <label style={styles.uploadButton}>
                        <BiCamera
                            style={{
                                marginRight: "10px",
                                fontSize: "20px",
                            }}
                        />
                        {loading ? "Uploading..." : "Upload Photo / Video"}
                        <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleUpload(e)}
                            disabled={loading}
                            accept="image/*,video/*" // Allow image and video files
                        />
                    </label>
                )}

                {(content || files.length > 0 ) && (
                    <button
                        style={styles.publishButton}
                        type="button"
                        onClick={handleUploadPost}
                        disabled={loading}
                    >
                        {selectedPost && Object.keys(selectedPost).length > 0
                            ? "Update Now"
                            : "Publish Now"}
                    </button>
                )}
                
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "space-between",
        // padding: "20px",
        margin: "auto",
        border: "1px solid #A6A6A6",
        borderRadius: "15px",
        marginBottom: "20px",
        marginTop: "20px",
    },
    leftSection: {
        flex: 2,
        marginRight: "20px",
        padding: "40px",
    },
    rightSection: {
        flex: 1,
        padding: "40px",
    },
    feedCard: {
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: "15px",
        marginBottom: "20px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    },
    feedHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
    },
    userImage: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        marginRight: "10px",
    },
    timeText: {
        color: "#888",
        fontSize: "14px",
    },
    moreButton: {
        marginLeft: "auto",
        background: "none",
        border: "none",
        cursor: "pointer",
    },
    feedContent: {
        marginBottom: "10px",
    },
    contentImage: {
        width: "100%",
        borderRadius: "10px",
    },
    feedActions: {
        display: "flex",
        justifyContent: "space-around",
        color: "#888",
    },
    createPostCard: {
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: "20px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    },
    createPostHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
    },
    textarea: {
        width: "100%",
        height: "250px",
        padding: "10px",
        borderRadius: "10px",
        border: "1px solid #ccc",
        marginBottom: "15px",
        resize: "none",
    },
    createPostActions: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    uploadButton: {
        // backgroundColor: "#f0f0f0",
        background: "transparent",
        color: "#58C8C8",
        borderRadius: "10px",
        padding: "10px 15px",
        cursor: "pointer",
        marginBottom: "10px",
        border: "1px solid #58C8C8",
    },
    publishButton: {
        background: "linear-gradient(to right, #58C8C8, #3F8E9B)",
        color: "#fff",
        border: "none",
        borderRadius: "10px",
        padding: "10px 15px",
        cursor: "pointer",
    },
};
