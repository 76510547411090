import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const REACT_APP_ASSETS_BASE_URL = process.env.REACT_APP_ASSETS_FRONTEND_URL;

export default function PositionCarousel2({
  positions,
  setPositionfromCarousel,
  setTabs,
}) {
  const navigate = useNavigate();
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  //   console.log("selected position", selectedCardIndex);

  const sliderSettings = {
    slidesToShow: 10,
    slidesToScroll: 3,
    dots: false,
    arrows: false,
    className: "product-slick",
    swipe: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
    ],
  };

  const cardDesign = {
    position: "relative",
    backgroundColor: "white",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    margin: "0 auto",
    cursor: "pointer",
    border: "2px solid transparent",
    padding: "3px",
  };

  const handleCardClick = (position, index) => {
    // Toggle the selection
    // setPositionfromCarousel(position);
    setTabs("employees");
    navigate(`/client-dashboard2?position=${position._id}`);
  };

  const handleDeselectClick = (event) => {
    event.stopPropagation();
    setSelectedCardIndex(null);
  };
  console.log("selected position", selectedCardIndex);

  const partnerItems = positions.map((position, index) => (
    <div key={index} onClick={() => handleCardClick(position, index)}>
      <img
        style={cardDesign}
        src={`${REACT_APP_ASSETS_BASE_URL}/${position.logo}`}
        alt={position.name}
      />
      <div
        style={{
          textAlign: "center",
          fontSize: "12px",
          marginTop: "10px",
          fontWeight: "500",
        }}
      >
        {/* name length >10 will be ... */}
        {position.name.length > 10
          ? position.name.substring(0, 10) + "..."
          : position.name}
      </div>
    </div>
  ));

  return (
    <>
      <Slider {...sliderSettings}>{partnerItems}</Slider>
    </>
  );
}
