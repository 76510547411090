import React, { useRef, useState } from "react";
import "./social_feed_style.scss";
import { Carousel, Dropdown, Modal } from "antd";
import CommentComponents from "./CommentComponents";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import { jwtTokenDecode } from "../../../../utils/jwtDecode";
import axios from "axios";
import { token } from "../../../../utils/authentication";
import toast from "react-hot-toast";
import { BsThreeDots } from "react-icons/bs";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

export default function PostFeedCardMulti({
    userImage,
    userName,
    postTime,
    postContentImg,
    content,
    post,
    handleUpdatePost,
    setEdit,
    setSelectedPost,
    setCurrentPage,
    handleDeletePosts,
}) {
    const jwtDecode = jwtTokenDecode();
    const params = new URLSearchParams(window.location.search);
    const type = params.get("type");
    const [showComments, setShowComments] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [remark, setRemark] = useState("");
    const [viewMore, setViewMore] = useState(false);
    const [likes, setLikes] = useState(post.likes);
    // Toggle the view more state
    const handleViewMore = () => {
        setViewMore(!viewMore);
    };

    const handleRepostSubmit = async () => {
        await axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/social-feed/report`,
                {
                    postId: post?._id,
                    reason: remark,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token()}`,
                    },
                }
            )
            .then((res) => {
                if (res.data?.status == "success") {
                    setShowModal(false);
                    toast.success("Report Successfully");
                    setRemark("");
                }
            });
    };

    const likeUnlike = async () => {
        await axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/social-feed/like-unlike`,
                {
                    postId: post?._id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token()}`,
                    },
                }
            )
            .then((res) => {
                const isLiked = likes.some((like) => like._id === jwtDecode?._id);
                let updatedLikes;

                if (isLiked) {
                    // Remove like
                    updatedLikes = likes.filter((like) => like._id !== jwtDecode?._id);
                } else {
                    // Add like
                    const user = { _id: jwtDecode?._id, name: jwtDecode?.name }; // Replace with actual user info
                    updatedLikes = [...likes, user];
                }

                // Update state
                setLikes(updatedLikes);

                // Create a new post object with updated likes
                const updatedPost = { ...post, likes: updatedLikes };

                handleUpdatePost(updatedPost);
            });
    };

    function nFormatter(num, digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" },
        ];
        const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
        const item = lookup.findLast((item) => num >= item.value);
        return item
            ? (num / item.value)
                .toFixed(digits)
                .replace(regexp, "")
                .concat(item.symbol)
            : "0";
    }

    const handleUploadPost = async (data) => {
        await axios
            .put(
                `${process.env.REACT_APP_API_BASE_URL}/social-feed/update/${data?._id}`,
                {
                    active: !data?.active,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token()}`,
                    },
                }
            )
            .then(async (res) => {
                if (res.data?.status == "success") {
                    await toast.success("Post Updated");
                    await setCurrentPage(1);
                    const updatedPost = { ...post, active: res.data?.socialFeed?.active };

                    handleUpdatePost(updatedPost);
                }
            })
            .catch((err) => toast.error("Invalid File Type"));
    };

    const handleDeletePost = async (data) => {
        await axios
            .delete(
                `${process.env.REACT_APP_API_BASE_URL}/social-feed/${data?._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token()}`,
                    },
                }
            )
            .then(async (res) => {
                if (res.data?.status == "success") {
                    await toast.success("Post Deleted");
                    await setCurrentPage(1);
                    handleDeletePosts(data);
                }
            })
            .catch((err) => { });
    };

    const items = [
        {
            key: "1",
            label: (
                <button
                    className="btn btn-sm p-0"
                    type="button"
                    onClick={() => {
                        setSelectedPost(post);
                    }}
                >
                    Edit
                </button>
            ),
        },
        {
            key: "2",
            label: (
                <button
                    className="btn btn-sm p-0"
                    onClick={() => {
                        handleUploadPost(post);
                    }}
                >
                    {post?.active ? "Inactive" : "Active"}
                </button>
            ),
        },
        {
            key: "3",
            label: (
                <button
                    className="btn btn-sm p-0"
                    onClick={() => {
                        handleDeletePost(post);
                    }}
                >
                    Delete
                </button>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = useRef();  // Reference to the Carousel component

    const handleMediaClick = (index) => {
        setCurrentSlide(index);    // Set the clicked media index as the current slide
        setIsModalVisible(true);   // Open the modal
    };

    const handleCancel = () => {
        setIsModalVisible(false);  // Close the modal 
    };

    // Move to next slide
    const nextSlide = () => {
        carouselRef.current.next(); // Trigger the next() method of the Carousel
    };

    // Move to previous slide
    const prevSlide = () => {
        carouselRef.current.prev(); // Trigger the prev() method of the Carousel
    };

    return (
        <>
            <div className="post_feed_card">
                <div className="post_feed_header">
                    <img className="post_user_img" src={userImage} alt="User" />
                    <div>
                        <strong>{userName}</strong>
                        <span className="post_time">
                            {" "}
                            •{" "}
                            {post?.active ? (
                                <ReactTimeAgo date={postTime} locale="en-US" />
                            ) : (
                                "Hold"
                            )}
                        </span>
                    </div>
                    {type == "mypost" && (
                        <Dropdown
                            menu={{
                                items,
                            }}
                            placement="bottom"
                            className="ms-auto"
                        >
                            <button className="btn">
                                <BsThreeDots />
                            </button>
                        </Dropdown>
                    )}
                </div>
                <div className="post_feed_content">
                    {content && content.length > 0 && <p>{content} </p>}

                    {postContentImg && (
                        <div className="multiple_images">
                            {postContentImg.length === 1 && (
                                <div className="single_image">
                                    {postContentImg.slice(0, 1).map((item, index) => (
                                        item.type === 'image' ? (
                                            <img
                                                key={index}
                                                className="img-fluid w-100"
                                                src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                                                onClick={() => handleMediaClick(index)}  // Open modal on image click
                                            />
                                        ) : (
                                            <div
                                                key={index}
                                                className="media-thumbnail-wrapper w-100"
                                                onClick={() => handleMediaClick(index)}  // Open modal on video click
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img
                                                    src="https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg" // Optional video thumbnail
                                                    alt="video thumbnail"
                                                    className="multi_post_image w-100"
                                                    style={{ padding: "1px" }}
                                                />
                                            </div>
                                        )
                                    ))}
                                </div>
                            )}

                            {postContentImg.length === 2 && postContentImg.slice(0, 2).map((item, index) => (
                                item.type === 'image' ? (
                                    <img
                                        key={index}
                                        className="multi_post_image"
                                        src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                                        onClick={() => handleMediaClick(index)}  // Open modal on image click
                                    />
                                ) : (
                                    <div
                                        key={index}
                                        className="media-thumbnail-wrapper w-50"
                                        onClick={() => handleMediaClick(index)}  // Open modal on video click
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <img
                                            src="https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg" // Optional video thumbnail
                                            alt="video thumbnail"
                                            className="multi_post_image w-100"
                                            style={{ padding: "1px" }}
                                        />
                                    </div>
                                )
                            ))}

                            {postContentImg.length === 3 && (
                                <>
                                    {postContentImg?.slice(0, 1).map((item, index) => (
                                        item.type === 'image' ? (
                                            <img
                                                key={index}
                                                className="multi_post_image w-100"
                                                src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                                                onClick={() => handleMediaClick(index)}  // Open modal on image click
                                            />
                                        ) : (
                                            <div
                                                key={index}
                                                className="media-thumbnail-wrapper w-50"
                                                onClick={() => handleMediaClick(index)}  // Open modal on video click
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img
                                                    src="https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg" // Optional video thumbnail
                                                    alt="video thumbnail"
                                                    className="multi_post_image w-100"
                                                    style={{ padding: "1px" }}
                                                />
                                            </div>
                                        )
                                    ))}

                                    {postContentImg?.slice(1, 3).map((item, index) => (
                                        item.type === 'image' ? (
                                            <img
                                                key={index}
                                                className="multi_post_image"
                                                src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                                                onClick={() => handleMediaClick(index)}  // Open modal on image click
                                            />
                                        ) : (
                                            <div
                                                key={index}
                                                className="media-thumbnail-wrapper w-50"
                                                onClick={() => handleMediaClick(index)}  // Open modal on video click
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img
                                                    src="https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg" // Optional video thumbnail
                                                    alt="video thumbnail"
                                                    className="multi_post_image w-100"
                                                    style={{ padding: "1px" }}
                                                />
                                            </div>
                                        )
                                    ))}

                                </>
                            )}

                            {postContentImg.length === 4 && (
                                <>
                                    {postContentImg?.slice(0, 4).map((item, index) => (
                                        item.type === 'image' ? (
                                            <img
                                                key={index}
                                                className="multi_post_image"
                                                src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                                                onClick={() => handleMediaClick(index)}  // Open modal on image click
                                            />
                                        ) : (
                                            <div
                                                key={index}
                                                className="media-thumbnail-wrapper w-50"
                                                onClick={() => handleMediaClick(index)}  // Open modal on video click
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {/* 
                                                <video controls className="w-50">
                                                    <source
                                                        src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                                                        type="video/mp4"
                                                    />
                                                    Your browser does not support the video tag.
                                                </video> */}
                                                <img
                                                    src="https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg" // Optional video thumbnail
                                                    alt="video thumbnail"
                                                    className="multi_post_image w-100"
                                                    style={{ padding: "1px" }}
                                                />
                                                {/* <div className="play-icon">Play</div> */}
                                            </div>
                                        )
                                    ))}

                                </>
                            )}

                            {postContentImg.length > 4 && (
                                <>
                                    {postContentImg?.slice(0, 3).map((item, index) => (
                                        item.type === 'image' ? (
                                            <img
                                                key={index}
                                                className="multi_post_image"
                                                src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                                                onClick={() => handleMediaClick(index)}  // Open modal on image click
                                            />
                                        ) : (
                                            <div
                                                key={index}
                                                className="media-thumbnail-wrapper w-50"
                                                onClick={() => handleMediaClick(index)}  // Open modal on video click
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img
                                                    src="https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg" // Optional video thumbnail
                                                    alt="video thumbnail"
                                                    className="multi_post_image w-100"
                                                    style={{ padding: "1px" }}
                                                />
                                            </div>
                                        )
                                    ))}

                                    <div className="multi_post_image_wrapper">
                                        {postContentImg?.slice(3, 4).map((item, index) => (
                                            item.type === 'image' ? (
                                                <img
                                                    key={index}
                                                    className="multi_post_image w-100"
                                                    src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                                                    onClick={() => handleMediaClick(index)}  // Open modal on image click
                                                />
                                            ) : (
                                                <div
                                                    key={index}
                                                    className="media-thumbnail-wrapper w-50"
                                                    onClick={() => handleMediaClick(index)}  // Open modal on video click
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <img
                                                        src="https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg" // Optional video thumbnail
                                                        alt="video thumbnail"
                                                        className="multi_post_image w-100"
                                                        style={{ padding: "1px" }}
                                                    />
                                                </div>
                                            )
                                        ))}
                                        <div className="view_more_overlay">View More</div>
                                    </div>

                                </>
                            )}


                        </div>
                    )}



                    <Modal
                        visible={isModalVisible}
                        footer={null}
                        onCancel={handleCancel}
                        // closable={false}
                        centered
                        width={1000}
                        wrapClassName="post_preview_modal"
                    >


                        <LeftOutlined
                            onClick={prevSlide}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '10px',
                                zIndex: 10,
                                fontSize: '24px',
                                color: '#fff',
                                cursor: 'pointer',
                            }}
                        />

                        <RightOutlined
                            onClick={nextSlide}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                zIndex: 10,
                                fontSize: '24px',
                                color: '#fff',
                                cursor: 'pointer',
                            }}
                        />


                        <Carousel
                            ref={carouselRef}
                            initialSlide={currentSlide}
                            dots={false}
                            afterChange={(index) => setCurrentSlide(index)}
                            adaptiveHeight={true}
                        >
                            {postContentImg.map((item, index) => (
                                <div key={index}>
                                    {item.type === 'image' ? (
                                        <img
                                            src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                                            className="img-fluid w-100"
                                        />
                                    ) : (
                                        <video controls className="w-100">
                                            <source
                                                src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                                                type="video/mp4"
                                            />
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                </div>
                            ))}
                        </Carousel>
                    </Modal>
                </div >

                <hr style={{ border: "1.21px solid rgba(104, 118, 132, 0.3)" }} />

                <div className="post_feed_actions">
                    <button
                        className={
                            likes?.some((item) => item?._id === jwtDecode?._id)
                                ? "like-btn-active"
                                : ""
                        }
                        onClick={() => {
                            likeUnlike();
                        }}
                    >
                        👍 {nFormatter(post?.likes?.length, 1)} Like
                    </button>
                    <button onClick={() => setShowComments(!showComments)}>
                        💬 {nFormatter(post?.comments?.length, 1)}
                    </button>
                    <button onClick={() => setShowModal(true)}>🔄 Report</button>
                </div>

                {
                    showComments && (
                        <CommentComponents
                            comment={post?.comments}
                            handleUpdatePost={handleUpdatePost}
                            postId={post?._id}
                            post={post}
                        />
                    )
                }
            </div >

            <div className={`modal reason_modal fade ${showModal ? "show" : ""}`}
                tabIndex="-1"
                style={{ display: showModal ? "block" : "none" }}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Write a Reason</h5>
                            <button
                                type="button"
                                className="btn-close text-light"
                                onClick={() => setShowModal(false)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <textarea
                                className="form-control"
                                autoFocus
                                rows="10"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                                placeholder="Write Reason"
                            />

                            <button
                                type="button"
                                className="btn btn-primary reason_submit_btn"
                                onClick={handleRepostSubmit}
                            >
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
