import React from "react";
import { useTranslation } from "react-i18next";
import privacy from "./Privacy-Notice-01-CC.png";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <div>
      {/* Banner */}
      <div
        className="banner-header section-padding valign bg-img bg-fixed"
        style={{
          backgroundImage: `url(${privacy})`,
          minHeight: "400px",
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-md-12 caption align-middle"
              style={{ marginTop: "150px" }}
            >
              <h1 style={{ color: "white", fontSize: "70px" }}>
                {/* {t("privacy_notice")} */}
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy Policy Content */}
      <div className="container" style={{ marginTop: "60px" }}>
        <h6
          style={{
            marginBottom: "20px",
          }}
        >
          {t("privacy_notice_des_1")}
        </h6>
        <h4>{t("privacy_notice_title_1")}</h4>
        <p>{t("privacy_notice_title_1_des")}</p>

        <h5>{t("privacy_notice_title_sub_1_a")}</h5>
        <ul>
          <p>{t("privacy_notice_des_1_1")}</p>
          <p>{t("privacy_notice_des_1_2")}</p>
          <p>{t("privacy_notice_des_1_3")}</p>
          <p>{t("privacy_notice_des_1_4")}</p>
          <p>{t("privacy_notice_des_1_5")}</p>
          <p>{t("privacy_notice_des_1_6")}</p>
        </ul>

        <h5>{t("privacy_notice_title_sub_1_b")}</h5>
        <ul>
          <p>{t("privacy_notice_des_1_7")}</p>
          <p>{t("privacy_notice_des_1_8")}</p>
          <p>{t("privacy_notice_des_1_9")}</p>
        </ul>

        <h4>{t("privacy_notice_title_2")}</h4>
        <p>{t("privacy_notice_des_2")}</p>
        <h5>{t("privacy_notice_title_sub_2_a")}</h5>
        <ul>
          <p>{t("privacy_notice_des_2_1")}</p>
          <p>{t("privacy_notice_des_2_2")}</p>
          <p>{t("privacy_notice_des_2_3")}</p>
        </ul>

        <h5>{t("privacy_notice_title_sub_2_b")}</h5>
        <ul>
          <p>{t("privacy_notice_des_2_4")}</p>
          <p>{t("privacy_notice_des_2_5")}</p>
          <p>{t("privacy_notice_des_2_6")}</p>
        </ul>

        <h5>{t("privacy_notice_title_sub_2_c")}</h5>
        <ul>
          <p>{t("privacy_notice_des_2_7")}</p>
          <p>{t("privacy_notice_des_2_8")}</p>
          <p>{t("privacy_notice_des_2_9")}</p>
        </ul>

        {/* 3 */}
        <h4>{t("privacy_notice_title_3")}</h4>
        <p>{t("privacy_notice_des_3")}</p>
        <p
          style={{
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          {t("privacy_notice_title_sub_3_a")}
          {t("privacy_notice_des_3_1")}
        </p>
        <p
          style={{
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          {t("privacy_notice_title_sub_3_b")}
          {t("privacy_notice_des_3_2")}
        </p>
        <p
          style={{
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          {t("privacy_notice_title_sub_3_c")}
          {t("privacy_notice_des_3_3")}
        </p>

        {/* 4 */}
        <h4>{t("privacy_notice_title_4")}</h4>
        <p>{t("privacy_notice_des_4")}</p>
        <h6
          style={{
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          {t("privacy_notice_title_sub_4_a")}
          {t("privacy_notice_des_4_1")}
        </h6>
        <h6
          style={{
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          {t("privacy_notice_title_sub_4_b")}
          {t("privacy_notice_des_4_2")}
        </h6>
        <h6
          style={{
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          {t("privacy_notice_title_sub_4_c")}
          {t("privacy_notice_des_4_3")}
        </h6>

        {/* 5 */}
        <h4>{t("privacy_notice_title_5")}</h4>
        <p>{t("privacy_notice_des_5")}</p>

        {/* 6 */}
        <h4>{t("privacy_notice_title_6")}</h4>
        <p>{t("privacy_notice_des_6")}</p>

        {/* 7 */}
        <h4>{t("privacy_notice_title_7")}</h4>
        <p>{t("privacy_notice_des_7")}</p>

        {/* 8 */}
        <h4>{t("privacy_notice_title_8")}</h4>
        <p>{t("privacy_notice_des_8")}</p>
        <span
          style={{
            // font italic:
            // fontStyle: "italic",
            fontweight: "bold",
          }}
        >
          <p>{t("privacy_notice_des_8_1")}</p>
          <p>202 Souk Al Bahar Saaha C, Downtown Dubai</p>
          <p>{t("privacy_notice_des_8_3")}</p>
          <p>UK +44 7500146699 | UAE +971 521689006 | IT +39 3409565486</p>
        </span>
        <p>{t("privacy_notice_des_8_5")}</p>
      </div>
    </div>
  );
};

export default Privacy;
