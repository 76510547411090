import React from "react";
import { Link } from "react-router-dom";
import "./HistorySection.css";
import { Tabs } from "antd";
import EmployeeBookedHistory from "./EmployeeBookedHistory";
import EmployeeHiredHistory from "./EmployeeHiredHistory";

const tabComponent = [
  {
    key: 1,
    label: "Booked History",
    children: <EmployeeBookedHistory />,
  },
  {
    key: 2,
    label: "Hired History",
    children: <EmployeeHiredHistory />,
  },
];

export default function HistorySection() {
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <div className="container">
      <Tabs
        onChange={onChange}
        type="card"
        items={tabComponent.map((item, i) => {
          const id = String(i + 1);
          return {
            label: item.label,
            key: id,
            children: item.children,
          };
        })}
      />
      {/*<Link to="/employee-booked-history" className="history-card">
        <div className="card-content">
          <h3>Booked History</h3>
          <div className="card-details">
            <p>View details of all your booked histories</p>
            <button className="view-details-button">View Details</button>
          </div>
        </div>
      </Link>
      <Link to="/employee-hired-history" className="history-card">
        <div className="card-content">
          <h3>Hired History</h3>
          <div className="card-details">
            <p>View details of all your hired histories</p>
            <button className="view-details-button">View Details</button>
          </div>
        </div>
      </Link>*/}
    </div>
  );
}
