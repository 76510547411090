import React, { useCallback, useEffect, useState } from "react";

import { Form, Input } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import { clientRegisterHandler } from "../../../api/client";
import { responseNotification } from "../../../utils/notifcation";
import FilteredCountryWiseValidationRules from "../../../utils/static/filteredCountry";
import MapModal from "../profile/MapModal";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
    validatePhoneNumberLength,
} from "libphonenumber-js";
import CoverBg from "./images/banner_image.png";
import PlayStoreBtn from "./images/play_store_btn.png";
import AppleStoreBtn from "./images/apple_store_btn.png";
import AppsDownloadText from "./images/apps_download_text.png";
import PlagitLogo from "./images/plagit_logo.png";
import { Helmet } from "react-helmet";

function ClientRegister() {
    const { t } = useTranslation();
    const [referPerson, setReferPerson] = useState([]);
    const [sourceFrom, setSourceFrom] = useState([]);

    const [loading, setLoading] = useState(false);
    const [getError, setError] = useState();
    const [mapModal, setMapModal] = useState(false);
    const [myLocation, setMyLocation] = useState({});
    const [name, setName] = useState("");

    const [form] = Form.useForm();
    const navigate = useNavigate();

    //Fetch Refer Person list for dropdown
    const fetchReferPersonData = useCallback(async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/list?isReferPerson=YES`
            );
            setReferPerson(response?.data?.users);
        } catch (error) {
            // console.log("Error: ", error);
        }
    }, []);

    //Fetch source from list for dropdown
    const fetchSourceFromData = useCallback(async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/sources/list-for-dropdown`
            );
            setSourceFrom(response?.data?.sources);
        } catch (error) {
            // console.log("Error: ", error);
        }
    }, []);

    useEffect(() => {
        fetchSourceFromData();
        fetchReferPersonData();
    }, []);

    const getSessionId = async ({ email }) => {
        console.log("email", email);
        debugger;
        debugger;
        try {
            await axios
                .post(`${process.env.REACT_APP_API_BASE_URL}/users/get-session`, {
                    email: email,
                    returnUrl: `https://plagit.com/login`,
                })
                .then((res) => {
                    navigate("/card-verify?sessionId=" + res?.data?.details?.id);
                });
        } catch (error) {
            console.error("Error fetching session ID:", error);
        }
    };

    const onFinish = async (values) => {
        const data = form.getFieldsValue(true);
        const receivedClientRegisterFields = {
            restaurantName: values?.restaurantName,
            restaurantAddress: values?.restaurantAddress,
            sourceId: values?.sourceId,
            phoneNumber: values?.phoneNumber,
            email: values?.email,
            countryName: values?.countryName,
            password: values?.password,
            lat: data?.lat,
            long: data?.lng,
        };

        if (values?.referPersonId) {
            receivedClientRegisterFields.referPersonId = values?.referPersonId;
        }

        if (receivedClientRegisterFields) {
            setLoading(true);

            await clientRegisterHandler(receivedClientRegisterFields)
                .then((res) => res.json())
                .then((res) => {
                    if (res.statusCode === 201) {
                        responseNotification("Client registered successfully!", "success");
                        form.resetFields();
                        getSessionId({
                            email: values?.email,
                        });

                        // navigate("/register-welcome");

                        // window.location.reload();
                    } else if (res?.statusCode === 400) {
                        setError(res?.errors[0]?.msg);
                        responseNotification(res?.errors[0]?.msg, "error");
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setError(res?.message);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false);
                });
        }
    };

    return (
        <> 
            <Helmet>
                <meta
                    name="description"
                    content=" Access the PLAGIT portal. Log in to connect with top-tier talent, manage job postings, and ensure your company's recruitment success."
                />
                <title>Client Register | PLAGIT: Your Gateway to Talent Excellence</title>
            </Helmet>

            <section className="total_wrapper">
                <div className="container-fluid">
                    <div className="row custom_coloring_row">
                        <div className="col-lg-6 col-md-6 col-sm-12 Login_Page_left_part ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Link to="/">
                                            <img
                                                src="assets/frontend/images/registrationFormImages/clientAndEmployee/Group 685.png"
                                                className="img-fluid"
                                                alt="image"
                                            />
                                        </Link>
                                        <Link className="top_logo_left" to="/">
                                            <img src={PlagitLogo} className="img-fluid" alt="logo" />
                                        </Link>
                                    </div>
                                    <div className="col-lg-12 helps_you_hire">
                                        <h4>
                                            PLAGIT {t("helps_you_hire_great")} &amp;
                                            {t("experienced_workers_at_a_moment's_notice")}
                                        </h4>
                                    </div>

                                    <div className="col-lg-12 text-center">
                                        <img
                                            className="img-fluid cover_image"
                                            src={CoverBg}
                                            alt="cover"
                                        />
                                    </div>
                                </div>
                                <div className="row footer_apps_download_link_btn">
                                    <div className="col-lg-7">
                                        <img
                                            className="img-fluid apps_download_btn_image_text"
                                            src={AppsDownloadText}
                                            alt=""
                                        />
                                        {/* <div className="download_app_message_div">
                      <div className="plagit_app">
                          plagit app
                      </div>
                      <div className="available_now_text">
                          Is available now on android and ios
                      </div>
                  </div> */}
                                    </div>
                                    <div className="col-lg-5 apps_download_brn_group">
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.invain.mh&pli=1"
                                            target="_blank"
                                            className="play_store_btn"
                                        >
                                            <img className="img-fluid" src={PlayStoreBtn} alt="cover" />
                                        </a>
                                        <a
                                            href="https://apps.apple.com/us/app/plagit/id6446052294"
                                            target="_blank"
                                            className="apple_store_btn"
                                        >
                                            <img
                                                className="img-fluid"
                                                src={AppleStoreBtn}
                                                alt="cover"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 Login_page_right_part">
                            <div className="container custom_for_320">
                                <div className="row right_part_wrapper ">
                                    <div className="first_eclips_img">
                                        <img
                                            className="img-fluid"
                                            src="assets/frontend/images/registrationFormImages/clientAndEmployee/Ellipse 70.png"
                                            alt="image"
                                        />
                                    </div>
                                    <div className="eclipse_wrapper2">
                                        {/* <img
                className="img-fluid"
                src="assets/frontend/images/registrationFormImages/clientFormPictures/../clientAndEmployee/Ellipse 69.png"
                alt="image"
              /> */}
                                    </div>
                                    <div className="clientButtonWrapper d-flex justify-content-center align-items-center">
                                        <ul
                                            className="nav mobile_320  ClientButtonNavTabs nav-tabs"
                                            id="myTab"
                                            role="tablist"
                                        >
                                            <li
                                                className="nav-item ClientButtonNavItem"
                                                role="presentation"
                                            >
                                                <Link
                                                    to="/client-register"
                                                    className="text-decoration-none"
                                                >
                                                    <button className="nav-link active client_tab_button">
                                                        {t("client")}
                                                    </button>
                                                </Link>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <Link
                                                    to="/employee-register"
                                                    className="text-decoration-none"
                                                >
                                                    <button className="nav-link employee_tab_button">
                                                        {t("candidate")}
                                                    </button>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="formWrappper">
                                        <div className="tab-content">
                                            <Form
                                                className="ant-form ant-form-vertical"
                                                enableReinitialize={true}
                                                layout="vertical"
                                                onFinish={onFinish}
                                                form={form}
                                            >
                                                <div className="tab-pane fade show active">
                                                    <div className="container card_looking_container">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <Form.Item
                                                                    name="restaurantName"
                                                                    hasFeedback
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t("please_enter_restaurant_name"),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <div className="inputLogoWrapper">
                                                                            <img
                                                                                className="img-fluid"
                                                                                src="assets/frontend/images/registrationFormImages/clientFormPictures/Vector.png"
                                                                                alt="image"
                                                                            />
                                                                        </div>
                                                                        <input
                                                                            placeholder={t("enter_restaurant_name")}
                                                                            type="text"
                                                                            className="form-control custom_client_input_for_registration_page"
                                                                        />
                                                                    </div>
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name="email"
                                                                    hasFeedback
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t("please_enter_email_address"),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <div className="inputLogoWrapper">
                                                                            <img
                                                                                className="img-fluid"
                                                                                src="assets/frontend/images/registrationFormImages/clientFormPictures/Email.png"
                                                                                alt="image"
                                                                            />
                                                                        </div>
                                                                        <input
                                                                            placeholder={t("enter_email_address")}
                                                                            type="email"
                                                                            className="form-control custom_client_input_for_registration_page"
                                                                        />
                                                                    </div>
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className="col-lg-6"
                                                                style={{
                                                                    position: "relative",
                                                                }}
                                                            >
                                                                <Form.Item
                                                                    name="restaurantAddress"
                                                                    hasFeedback
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t(
                                                                                "please_enter_restaurant_address"
                                                                            ),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <div className="inputLogoWrapper">
                                                                            <img
                                                                                className="img-fluid"
                                                                                src="assets/frontend/images/registrationFormImages/clientFormPictures/Subtract.png"
                                                                                alt="image"
                                                                            />
                                                                        </div>
                                                                        <input
                                                                            onClick={() => {
                                                                                setMapModal(true);
                                                                            }}
                                                                            autoComplete="off"
                                                                            placeholder={t("enter_restaurant_address")}
                                                                            type="text"
                                                                            value={form?.getFieldValue(
                                                                                "restaurantAddress"
                                                                            )}
                                                                            className="form-control custom_client_input_for_registration_page"
                                                                        />
                                                                    </div>
                                                                </Form.Item>
                                                                {/* <Button
                            type="default"
                            onClick={() => {
                              setMapModal(true);
                            }}
                            style={{
                              position: "absolute",
                              top: "1px",
                              right: "13px",
                              padding: "22px 17px",
                            }}
                          >
                            <BiMap  style={{
                              fontSize: "25px",
                              color: "#000",
                            position: "absolute",
                            top: "10px",
                            right: "5px",
                            
                            }}/>
                          </Button> */}
                                                                <Form.Item
                                                                    name="phoneNumber"
                                                                    hasFeedback
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "",
                                                                        },
                                                                        {
                                                                            validator: (_, value) =>
                                                                                value && isValidPhoneNumber(value)
                                                                                    ? Promise.resolve()
                                                                                    : Promise.reject(
                                                                                        new Error(
                                                                                            t(
                                                                                                "please_enter_valid_phone_number_for_selected_country"
                                                                                            )
                                                                                        )
                                                                                    ),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <div className="inputLogoWrapper">
                                                                            <img
                                                                                className="img-fluid"
                                                                                src="assets/frontend/images/registrationFormImages/clientFormPictures/phone.png"
                                                                                alt="image"
                                                                            />
                                                                        </div>
                                                                        <PhoneInput
                                                                            onlyCountries={["ae", "it", "gb"]}
                                                                            placeholder="Enter phone number"
                                                                            isValid
                                                                            inputProps={{
                                                                                name: "phoneNumber",
                                                                                required: true,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <Form.Item
                                                                    name="countryName"
                                                                    hasFeedback
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t(
                                                                                "please_select_your_country_name"
                                                                            ),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div className="dropdownbuttonwrapper">
                                                                        <div className="dropdown">
                                                                            <div className="inputLogoWrapper">
                                                                                <img
                                                                                    className="img-fluid"
                                                                                    src="assets/frontend/images/registrationFormImages/clientFormPictures/selectFrom.png"
                                                                                    alt="image"
                                                                                />
                                                                            </div>
                                                                            <select
                                                                                className="form-select custom_select"
                                                                                aria-label="Select country"
                                                                            >
                                                                                <option selected disabled>
                                                                                    {t("please_select_country_name")}
                                                                                </option>
                                                                                {FilteredCountryWiseValidationRules?.map(
                                                                                    (item, index) => (
                                                                                        <option
                                                                                            key={index}
                                                                                            value={item?.name}
                                                                                        >
                                                                                            {item?.name}
                                                                                        </option>
                                                                                    )
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </Form.Item>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                {/* <Form.Item
                                                                name="password"
                                                                hasFeedback
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t("please_enter_password"),
                                                                    },
                                                                ]}
                                                            >
                                                                <div>
                                                                    <div className="inputLogoWrapper">
                                                                        <img
                                                                            className="img-fluid"
                                                                            src="assets/frontend/images/registrationFormImages/clientFormPictures/pass.png"
                                                                            alt="image"
                                                                        />
                                                                    </div>
                                                                    <input
                                                                        placeholder={t("enter_password")}
                                                                        type="password"
                                                                        className="form-control custom_client_input_for_registration_page"
                                                                    />
                                                                </div>
                                                            </Form.Item> */}

                                                                <Form.Item
                                                                    name="password"
                                                                    hasFeedback
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t("please_enter_password"),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input.Password
                                                                        size="large"
                                                                        prefix={
                                                                            <img
                                                                                src="assets/frontend/images/login_page_images/Icon.png"
                                                                                className="img-fluid password_logo"
                                                                                alt="Password Icon"
                                                                            />
                                                                        }
                                                                        placeholder={t("enter_password")}
                                                                    />
                                                                </Form.Item>

                                                            </div>
                                                            {/* <div className="col-lg-6">
                          <Form.Item
                            name="confirmPassword"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter confirm password",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/pass.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter confirm password"
                                type="password"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                        </div> */}
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-12 please_provide_wrapper text-center">
                                                                <p className="pleaseProvideText">
                                                                    {t("please_provide_the_following_info_too")}
                                                                </p>
                                                                <img
                                                                    className="img-fluid pleaseProvideImage"
                                                                    src="assets/frontend/images/registrationFormImages/clientFormPictures/rectangle.png"
                                                                    alt="image"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="container dropdownContainer">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <p className="how_youKnow_text">
                                                                        {t("how_you_know_about_us")}
                                                                    </p>

                                                                    <Form.Item
                                                                        name="sourceId"
                                                                        hasFeedback
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t(
                                                                                    "please_select_how_you_know_about_us"
                                                                                ),
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <div className="dropdownbuttonwrapper">
                                                                            <div className="dropdown">
                                                                                <div className="inputLogoWrapper">
                                                                                    <img
                                                                                        className="img-fluid"
                                                                                        src="assets/frontend/images/registrationFormImages/clientFormPictures/selectFrom.png"
                                                                                        alt="image"
                                                                                    />
                                                                                </div>

                                                                                <select
                                                                                    className="form-select custom_select"
                                                                                    aria-label="Select"
                                                                                >
                                                                                    <option value="" selected disabled>
                                                                                        {t("please_select")}{" "}
                                                                                    </option>
                                                                                    {sourceFrom?.map((item, index) => (
                                                                                        <option key={index} value={item?._id}>
                                                                                            {item?.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </Form.Item>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <p className="how_youKnow_text">
                                                                        {t("select_refer")}
                                                                    </p>

                                                                    <Form.Item
                                                                        name="referPersonId"
                                                                        hasFeedback
                                                                        rules={[
                                                                            {
                                                                                // required: true,
                                                                                message: "Please enter refer person",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <div className="dropdownbuttonwrapper">
                                                                            <div className="dropdown">
                                                                                <div className="inputLogoWrapper">
                                                                                    <img
                                                                                        className="img-fluid"
                                                                                        src="assets/frontend/images/registrationFormImages/clientFormPictures/EnterHere.png"
                                                                                        alt="image"
                                                                                    />
                                                                                </div>

                                                                                <select className="form-select custom_select">
                                                                                    <option selected disabled>
                                                                                        {t("please_select")}{" "}
                                                                                    </option>
                                                                                    {referPerson?.map((item, index) => (
                                                                                        <option key={index} value={item?._id}>
                                                                                            {item?.role == "CLIENT"
                                                                                                ? item?.restaurantName
                                                                                                : item?.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="row ">
                        <div className="col-lg-12 please_provide_wrapper text-center">
                          <p className="pleaseProvideText">
                            Please provide Bank Details
                          </p>
                          <img
                            className="img-fluid pleaseProvideImage"
                            src="assets/frontend/images/registrationFormImages/clientFormPictures/rectangle.png"
                            alt="iq"
                          />
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-6">
                          <Form.Item
                            name="accountNumber"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter account number",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/Vector.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter account number"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>

                          <Form.Item
                            name="cardCvvNumber"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter card cvv number",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/Email.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter card cvv number"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                        </div>
                        <div
                          className="col-lg-6"
                          style={{
                            position: "relative",
                          }}
                        >
                          <Form.Item
                            name="cardHolderName"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter card holder name",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/phone.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter card holder name"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                          <Form.Item
                            name="cardExpDate"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter card exp date",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/phone.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter card exp date"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-lg-6">
                          <Form.Item
                            name="bankCountry"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter account country",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/Vector.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter account country"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                        </div>
                        <div
                          className="col-lg-6"
                          style={{
                            position: "relative",
                          }}
                        >
                          <Form.Item
                            name="bankZipCode"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter account zip code",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/phone.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter account zip code"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                        </div>
                      </div> */}
                                                        <div className="registerButton text-center mt-5">
                                                            <Form.Item>
                                                                <button
                                                                    disabled={loading}
                                                                    className="btn employee_register_button"
                                                                    type="submit"
                                                                >
                                                                    {!loading && t("register")}
                                                                    {loading && (
                                                                        <span
                                                                            className="indicator-progress"
                                                                            style={{ display: "block" }}
                                                                        >
                                                                            {t("please_wait...")}
                                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="accountandregisterwrapper text-center">
                                        <span className="donthaveaccounttext">
                                            {t("already_have_an_account")}
                                        </span>
                                        <Link to="/login">{t("login")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MapModal
                    isModalOpen={mapModal}
                    setIsModalOpen={setMapModal}
                    myLocation={myLocation}
                    setMyLocation={setMyLocation}
                    name={name}
                    setName={setName}
                    form={form}
                />
            </section>

        </>
    );
}

export default ClientRegister;
