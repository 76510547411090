import PolicyPage from "./Policy";

const contentArray = [
  {
    id: 1,
    heading:
      "We at Plagit value your security and convenience. To ensure a seamless transaction process, we have established the following payment methods:",
    text: "",
    listItems: [
      "Online Payments: Customers can complete their purchases online, utilizing a secure and reliable payment system.",
    ],
  },
  {
    id: 2,
    heading: "Accepted Card Types",
    text: "Visa and MasterCard: We accept Visa and MasterCard credit/debit cards to accommodate many customers. We understand the importance of flexibility in payment options and have integrated these widely accepted card types into our payment system.",
  },
  {
    id: 3,
    heading: "Currency",
    text: "AED and Other Currencies: Transactions can be processed in AED, the currency of the United Arab Emirates. We are also open to processing payments in other agreed-upon currencies to accommodate our international customers.",
  },
  {
    id: 4,
    heading: "Secure Transactions",
    text: "Security Measures: The security of your financial information is paramount. Our website employs robust encryption and security protocols to protect all online transactions against fraud and unauthorized access.",
  },
  {
    id: 4,
    heading: "",
    text: " We would like to encourage our customers to review their payment options and contact our customer support for any assistance or clarification regarding payment methods and currency options.",
  },
];
const PaymentPolicy = () => {
  return (
    <PolicyPage
      title="Payment Methods, Accepted Cards, and Currency"
      data={contentArray}
    />
  );
};

export default PaymentPolicy;
