import { Card } from "antd";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import DynamicCarousel2 from "./DynamicCarousel/DynamicCarousel2";
const { Meta } = Card;

export default function LeaderShip({ leaderShipData }) {
  const cardStyle = {
    width: 300,
    margin: 10,
    display: "flex",
    flexDirection: "column",
    marginBottom: 40,
  };

  const imageContainerStyle = {
    width: "100%",
    height: "200px",
    position: "relative",
  };

  const redOverlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "50%",
    height: "calc(100% + 0px)",
    backgroundColor: "#58c8c8",
  };

  const whiteOverlayStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    width: "50%",
    height: "calc(100%)",
    backgroundColor: "#58c8c8",
  };

  const imageStyle = {
    height: "200px",
    width: "200px",
    position: "relative",
    zIndex: 2,
    top: "-40px",
    right: "-55px",
  };

  const leadershipItems = leaderShipData.list.map((leader, index) => (
    <Card key={index} bordered={false} style={cardStyle}>
      <div className="image-box" style={imageContainerStyle}>
        <div style={redOverlayStyle}></div>
        <div style={whiteOverlayStyle}></div>
        <img
          src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${leader.url}`}
          alt={leader.name}
          style={imageStyle}
        />
      </div>
      <div
        className="details-box"
        style={{
          padding: 16,
          backgroundColor: "#FFF",
          marginTop: "-40px",
          position: "relative",
          borderRadius: "0 0 20px 0px",
        }}
      >
        <Meta title={`Name: ${leader.name}`} />
        <p>Occupation: {leader.occupation}</p>
      </div>
    </Card>
  ));

  return (
    <div
      className="mt-5"
      style={{ backgroundColor: "#58c8c826", position: "relative" }}
    >
      <div className="container mx-auto ">
        <div className="text-center p-5">
          <h3>LEADERSHIP</h3>
          <p>
            At the helm of Plagit PLAGIT Dubai Branch is Mirko Picicco, a
            seasoned visionary with a profound impact on the success and growth
            of our establishment within the vast hospitality industry of Dubai.
          </p>
        </div>
        {leadershipItems.length > 4 ? (
          <DynamicCarousel2
            items={leadershipItems}
            slidesToShow={4}
            autoplaySpeed={2000}
          />
        ) : (
          <div className="d-flex">{leadershipItems}</div>
        )}
      </div>
    </div>
  );
}
