import PolicyPage from "./Policy";

const contentArray = [
  {
    id: 1,
    heading: "",
    text: "Plagit ensures that a complete and detailed description of all the goods or services offered is available and easily accessible to all visitors, including the Acquiring RISK & Fraud team conducting reviews:",
    listItems: [
      "Accessibility of Information: Detailed descriptions of our goods or services can be accessed directly on our website. This includes comprehensive information regarding each product or service's features, benefits, and specifications.",
      " Transparency in Descriptions: We ensure that all descriptions are honest and accurate, giving our customers a true representation of what they can expect.",
      "Review by RISK & Fraud Team: As our website undergoes review by the Acquiring RISK & Fraud team, we guarantee full cooperation by maintaining open access to all necessary documentation and descriptions that facilitate a thorough assessment.",
      "Availability: Our descriptions are kept up-to-date and always present on the website, immediately reflecting any changes or updates to our goods or services.",
      "Customer Support: If you have additional inquiries or need further explanation regarding our goods and services, our customer support team is available to provide assistance and clarify any information.",
      "Plagit is committed to upholding the highest standards of transparency and accuracy in presenting our goods and services, ensuring our customers and partners can always rely on the information provided.",
    ],
  },
];
const GoodsService = () => {
  return (
    <PolicyPage title="Goods and Services Description" data={contentArray} />
  );
};

export default GoodsService;
