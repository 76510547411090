import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./blog.css";

function Blog() {
  const [blogs, setblogs] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/blog/get-all-blogs`)
      .then((res) => res.json())
      .then((data) => {
        setblogs(data.details);
        console.log(data);
      });
  }, []);
  return (
    <>
      <div className="container">
        <h1 className="text-center">
          Welcome to <span className="text-warning">Blog</span> Page
        </h1>
        <Row xs={1} md={2} lg={3} className="g-4 mb-5 mt-5">
          {blogs.map((blog) => {
            return (
              <Col className="d-flex justify-content-center">
                {" "}
                {/* Use 'Col' to create responsive columns */}
                <div className="post-preview">
                  <div
                    style={{
                      width: "20rem",
                      height: "25rem",
                      borderRadius: "7px",
                      textAlign: "center",
                    }}
                  >
                    <Card.Body>
                      <Card.Img
                        variant="top"
                        src={blog.thumbnail}
                        className="img-fluid img-thumbnail"
                      />
                      <Card.Title
                        style={{
                          marginBottom: "0.5rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        {blog.title || "No title found"}
                      </Card.Title>

                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            blog?.subTitle?.slice(0, 50) ||
                            "No subtitle found" +
                              (blog?.subTitle?.length > 50 ? "..." : ""),
                        }}
                      />
                    </Card.Body>

                    <Card.Body>
                      <Link
                        style={{
                          textDecoration: "none",
                          backgroundColor: "#58c8c8",
                          color: "white",
                          padding: "0.5rem",
                          borderRadius: "5px",
                        }}
                        to={`/blog/${blog.slug}`}
                      >
                        Read More
                      </Link>
                    </Card.Body>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
}

export default Blog;
