/* eslint-disable react/prop-types */

const CouponCard = ({
  imageUrl,
  restaurantLogo,
  restaurantName,
  discount,
  offerDetails,
  expirationDate,
}) => {
  return (
    <div className="ticket-shape relative  border border-[#A6A6A6]  flex  h-[220px]   items-center ">
      {/* <div className="w-20 h-20 bg-red-900 rounded-full overflow-hidden top-0 absolute"></div>
      <div className="w-20 h-20 bg-red-900 rounded-full bottom-0 absolute"></div>
      */}
      <div className="ticket-shape-bg z-[-1] ticket-shape absolute bg-teal-800 h-[220px]  w-full"></div>

      <img
        src={imageUrl}
        alt={restaurantName}
        className="w-[143px] h-[165px] rounded-3xl m-3"
      />
      <img src="https://i.ibb.co.com/xS08RjJ/line.png" />

      <div className="p-4 w-full">
        <div className="flex items-center mb-2">
          <img src={restaurantLogo} alt={restaurantName} />
          <h2 className="text-sm truncate font-semibold ml-3">
            {restaurantName}
          </h2>
        </div>
        <p className="text-lg text-[#111111] my-2 font-bold">{discount}</p>
        <p className="text-[#7B7B7B]">{offerDetails}</p>
        <div className="flex justify-between items-center mt-3">
          <p className="text-gray-400 text-sm mt-2">
            Before <br /> {expirationDate}
          </p>
          <button className="bg-gradient-to-r from-teal-400 to-teal-700 text-white py-2 px-4 ml-2 rounded-tl-3xl rounded-br-3xl">
            Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default CouponCard;
