import { Switch } from "antd";
import jwtDecode from "jwt-decode";
import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { BsCreditCard2Front, BsPostcard } from "react-icons/bs";
import { CiCircleInfo, CiLock } from "react-icons/ci";
import { FaRegUserCircle } from "react-icons/fa";
import { FiPower } from "react-icons/fi";
import { IoMdNotifications } from "react-icons/io";
import {
    MdArrowForwardIos,
    MdModeNight,
    MdOutlinePassword,
} from "react-icons/md";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { token } from "../../../utils/authentication";
import default_profile from '../../../assets/images/Client.jpg';


const ProfileActionItem = ({
    icon,
    iconBg = "#58C8C8",
    name,
    onClick,
    link,
    icon2 = <MdArrowForwardIos />,
    actionType = "default",
}) => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleClick = () => {
        if (actionType === "link" && link) {
            navigate(link);
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <Button
            variant="outline-secondary"
            className="d-flex align-items-center justify-content-between mb-2"
            onClick={handleClick}
            style={{
                borderRadius: "12px",
                padding: "12px 16px",
                border: "1px solid #A6A6A6 !important",
            }}
        >
            <div className="d-flex align-items-center">
                <span
                    className="me-3"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "30px",
                        height: "30px",
                        background: iconBg,
                        borderRadius: "50%",
                        color: "#fff",
                        border: actionType === "logout" ? "1px solid #ff0000" : "none",
                    }}
                >
                    {icon}
                </span>
                <span>{name}</span>
            </div>

            {icon2 && (
                <div
                    className="ms-3"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {icon2}
                </div>
            )}
        </Button>
    );
};

const ClientProfileCard = ({ getSingleClientDetails, setProfileModalOpen }) => {
    const navigate = useNavigate(); 
    const profilePictureUrl = getSingleClientDetails.profilePicture ? `${process.env.REACT_APP_ASSETs_BASE_URL}/${getSingleClientDetails.profilePicture}` : default_profile;
    let localToken = token();
    let decodeData = null;
    if (localToken) {
        decodeData = jwtDecode(localToken);
    }
    async function handleLogout() {
        if (decodeData.role === "Client") {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const lat = position.coords.latitude;
                const long = position.coords.longitude;
                const updateLatLong = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/users/update-location`,
                    {
                        method: "PUT",
                        headers: {
                            Authorization: `Bearer ${localToken}`,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            id: decodeData._id,
                            lat,
                            long,
                        }),
                    }
                );
                console.log(updateLatLong);
                if (updateLatLong.status === 201) {
                    debugger;
                    localStorage.removeItem("accessToken");
                    window.location.href = "/";
                } else {
                    localStorage.removeItem("accessToken");
                    window.location.href = "/";
                }
            });
        } else {
            localStorage.removeItem("accessToken");
            window.location.href = "/";
        }
    }


    return (
        <Card className="p-4">
            <div className="text-center">
                <img
                    src={profilePictureUrl}
                    alt="Profile"
                    className="rounded-circle"
                    style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        marginBottom: "10px",
                        border: "3px solid #58c8c8",
                    }}
                />


                <Row
                    className="justify-content-between border"
                    style={{
                        borderRadius: "10px",
                        marginTop: useWindowWidth() < 576 ? "10px" : "-45px",
                        padding: "10px",
                    }}
                >
                    <Col
                        xs={6}
                        md={6}
                        className="text-center"
                        onClick={() => {
                            navigate("/client-dashboard2?type=mypost");
                            setProfileModalOpen(false);
                        }}
                    >
                        <BsCreditCard2Front />
                        <p className="mb-1">My Social Posts</p>
                        <h5>{getSingleClientDetails.socialPostsCount || 0}</h5>
                    </Col>
                    {/* <hr /> */}
                    <Col xs={6} md={6} className="text-center">
                        <BsPostcard />
                        <p className="mb-1">My Job Posts</p>
                        <h5>{getSingleClientDetails.jobPostsCount || 0}</h5>
                    </Col>
                </Row>
            </div>

            <hr />

            <ProfileActionItem
                icon={<FaRegUserCircle />}
                name="Edit Profile"
                link="/profile"
                actionType="link"
            />
            <ProfileActionItem
                icon={<MdOutlinePassword />}
                name="Change Password"
                link="/setting"
                actionType="link"
            />
            <ProfileActionItem
                icon={<MdModeNight />}
                name="Theme Mode"
                icon2={<Switch checked={getSingleClientDetails.isDarkMode} />}
            />
            <ProfileActionItem
                icon={<IoMdNotifications />}
                name="Notification Sound"
                icon2={<Switch checked={getSingleClientDetails.notificationSound} />}
            />
            <ProfileActionItem
                icon={<CiCircleInfo />}
                name="About Us"
                actionType="link"
                link="/about"
            />
            <ProfileActionItem
                icon={<CiLock />}
                name="Privacy Policy"
                actionType="link"
                link="/privacy"
            />
            <ProfileActionItem
                icon={<TfiHeadphoneAlt />}
                name="Contact Us"
                actionType="link"
                link="/contact-us"
            />
            <ProfileActionItem
                icon={
                    <FiPower
                        style={{
                            color: "#ff0000",
                            transform: "rotate(90deg)",
                        }}
                    />
                }
                iconBg="#FFEDEA"
                name="Logout"
                onClick={handleLogout}
                actionType="logout"
            />
        </Card>
    );
};

export default ClientProfileCard;
