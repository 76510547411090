/* eslint-disable react/prop-types */
import { FaMapMarkerAlt, FaCalendarAlt, FaClock } from "react-icons/fa";

const EventCard = ({
  title,
  location,
  date,
  time,
  entryFee,
  imageUrl,
  detailsUrl,
}) => {
  return (
    <div className="bg-white z-[100] rounded-[25px]">
      {/* Event Image */}
      <img
        className="w-full p-3 rounded-3xl mx-auto"
        src={imageUrl} // Dynamically render image URL
        alt={title} // Dynamically render event title as alt text
      />

      {/* Event Content */}
      <div className="p-2 flex flex-col items-between">
        <h2 className="text-xl font-semibold text-gray-900 truncate">
          {title}
        </h2>

        {/* Address */}
        <div className="flex items-center text-gray-600 mt-2">
          <FaMapMarkerAlt className="mr-2" />
          <span className="text-sm">{location?.address}</span>{" "}
          {/* Dynamically render address */}
        </div>

        {/* Date and Time */}
        <div className="flex items-center text-gray-600 mt-2">
          <FaCalendarAlt className="mr-2" />
          <span className="text-sm">{date}</span>{" "}
          {/* Dynamically render date */}
          <FaClock className="ml-4 mr-2" />
          <span className="text-sm">From {time.start}</span>{" "}
          {/* Dynamically render start time */}
        </div>

        {/* Entry Fee Label */}
        <div className="flex items-center justify-between mt-4">
          <span
            className={`font-bold py-1 px-2 rounded-full text-sm ${
              entryFee === "Free Entry"
                ? "bg-teal-100 text-teal-600"
                : "bg-yellow-100 text-yellow-600"
            }`}
          >
            {entryFee} {/* Dynamically render entry fee */}
          </span>
          {/* Footer: Button */}
          <div className="px-4 pb-4">
            <button className="bg-gradient-to-r from-teal-400 to-teal-700 text-white py-2 px-4 rounded-tl-3xl rounded-br-3xl">
              Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
