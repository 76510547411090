import PolicyPage from "./Policy";

const contentArry = [
  {
    id: 1,
    heading: "Governing Law",
    text: "Any purchase, dispute, or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of the United Arab Emirates (UAE).",
  },
  {
    id: 2,
    heading: "Jurisdiction",
    text: "",
    listItems: [
      " Exclusive Jurisdiction: All disputes or claims that arise under or relate to this website, its usability, or any transactions conducted through it, will be resolved exclusively in the competent courts of the United Arab Emirates. By using this website, you consent to the jurisdiction and venue of these courts in any such legal action or proceeding.",
      " Legal Compliance: Users are responsible for compliance with local laws, if and to the extent local laws are applicable. You agree not to access this website in any jurisdiction where doing so would be prohibited or illegal.",
      " Binding Agreement: This provision acts as a binding agreement between you and PLAGIT, determining the legal environment under which transactions and disputes are managed.",
    ],
  },
  {
    id: 3,
    heading: "",
    text: "This governing law and jurisdiction clause ensures that both the customer and the company adhere to the legal standards and practices upheld in the UAE, promoting a secure and reliable environment for online transactions. For any further clarification or legal assistance, please consult with a legal advisor who specializes in UAE law. ",
  },
];

const GovtPolicy = () => {
  return (
    <PolicyPage
      title="Governing Law and Jurisdiction"
      data={contentArry}
      update="Last Updated: 18/04/2024"
    />
  );
};

export default GovtPolicy;
