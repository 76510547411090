const FilteredCountryWiseValidationRules = [
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
    postCodeRegex: "^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
    postCodeRegex: "",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
    postCodeRegex: "^\\d{5}$",
  },
];

export default FilteredCountryWiseValidationRules;
