import PolicyPage from "./Policy";

const contentArry = [
  {
    id: 1,
    heading: "Policy Statement",
    text: "Plagit's policy is to handle refunds with integrity and transparency. If refunds are applicable based on our terms and conditions, they will be processed in the following manner",
    listItems: [
      "Refunds will be done only through the Original Mode of Payment",
      "This ensures that the funds are returned securely to the source from which they originated, maintaining a clear financial trail and protecting against fraud.",
    ],
  },
  {
    id: 2,
    heading: "No Refund Statement",
    text: "",
    listItems: [
      "Clear Communication: If Plagit does not offer refunds for its products or services, this will be clearly articulated on our company's website to prevent misunderstandings. The statement to be provided on the website will read",
      "Plagit does not provide refunds for any products or services. Please ensure you review your purchase carefully before proceeding with payment.",
    ],
  },
  {
    id: 3,
    heading: "Return Policy",
    text: "Conditions for Return: ",
    listItems: [
      "If Plagit allows returns, conditions for returns will be detailed, including timelines, product condition requirements, and the process for initiating a return.",
    ],
  },
  {
    id: 4,
    heading: "Customer Support",
    text: "Assistance and Queries",
    listItems: [
      "Customers can contact our customer service team for guidance and support regarding our refund/return policy.",
    ],
  },

  {
    id: 5,
    heading: "",
    text: "Plagit is committed to ensuring our customers are well-informed about their purchases and the policies that govern transactions on our website. We strive to provide clarity and convenience to enhance our customers' experience.",
  },
];

const RefundPolicy = () => {
  return (
    <PolicyPage
      title="Refund/Return Policy"
      data={contentArry}
      update="Last Updated: 18/04/2024"
    />
  );
};

export default RefundPolicy;
