import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import markerIcon from "../../../../../src/components/frontend/profile/mapMarker.png";
import { io } from "socket.io-client";
import { getUserInfo } from "../../../../utils/commonFunctions";
import { Link, useLocation, useParams } from "react-router-dom";
import { token } from "../../../../utils/authentication";
import axios from "axios";
import { Col, Row, Select } from "antd";
const Livelocation = () => {
  const [markers, setMarkers] = useState([]);
  const [directions, setDirections] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [initialLocation, setInitialLocation] = useState(null);
  const [mode, setMode] = useState("DRIVING"); // ["DRIVING", "WALKING"]
  const [modeList, setModeList] = useState([
    {
      label: "Driving",
      value: "DRIVING",
    },
    {
      label: "Walking",
      value: "WALKING",
    },
   /*  {
      label: "Bicycling",
      value: "BICYCLING",
    },
    {
      label: "Transit",
      value: "TRANSIT",
    },
    {
      label: "Ridesharing",
      value: "RIDING",
    },
    {
      label: "Flying",
      value: "FLYING",
    } */
  ]);
  const userInfo = getUserInfo();
  const { employee } = useParams();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lat = parseFloat(params.get("lat")) || 0;
    const long = parseFloat(params.get("long")) || 0;
    setInitialLocation({ lat, long });
  }, [location.search]);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
      withCredentials: false,
      transports: ["websocket", "polling", "flashsocket"],
    });
    socket.on("connect", () => {
      console.log("Socket connected");
      setMarkers([
        {
          position: {
            lat: +userInfo.lat,
            lng: +userInfo.long,
          },
          label: `${userInfo.restaurantName}`,
          icon: {
            url: userInfo?.profilePicture
              ? process.env.REACT_APP_ASSETs_BASE_URL +
                "/" +
                userInfo?.profilePicture
              : null,
            scaledSize: new window.google.maps.Size(30, 30),
          },
        },
        {
          position: {
            lat: initialLocation?.lat || +userInfo.lat,
            lng: initialLocation?.long || +userInfo.long,
          },
          label: employeeDetails?.name,
          icon: {
            url: employeeDetails?.profilePicture
              ? process.env.REACT_APP_ASSETs_BASE_URL +
                "/" +
                employeeDetails?.profilePicture
              : null,
            scaledSize: new window.google.maps.Size(30, 30),
          },
        },
      ]);
    });
    socket.on("location:move", (data) => {
      const parsedData = data;
      if (
        parsedData.reciever === userInfo?._id &&
        parsedData.sender === employee
      ) {
        setMarkers([
          {
            position: {
              lat: +userInfo.lat,
              lng: +userInfo.long,
            },
            label: `${userInfo.restaurantName}`,
            icon: {
              url: userInfo?.profilePicture
                ? process.env.REACT_APP_ASSETs_BASE_URL +
                  "/" +
                  userInfo?.profilePicture
                : null,
              scaledSize: new window.google.maps.Size(30, 30),
            },
          },
          {
            position: {
              lat: parsedData.cords.latitude || initialLocation?.lat,
              lng: parsedData.cords.longitude || initialLocation?.long,
            },
            label: employeeDetails?.name,
            icon: {
              url: employeeDetails?.profilePicture
                ? process.env.REACT_APP_ASSETs_BASE_URL +
                  "/" +
                  employeeDetails?.profilePicture
                : null,
              scaledSize: new window.google.maps.Size(30, 30),
            },
          },
        ]);
      }
    });
    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
    return () => {
      console.log("Socket disconnecting");
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeDetails, initialLocation]);
  useEffect(() => {
    if (window.google && window.google.maps && markers.length === 2) {
      const directionsService = new window.google.maps.DirectionsService();
      const origin = markers[0].position;
      const destination = markers[1].position;
      console.log("origin", origin);
      console.log("destination", destination);
      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode[mode],
        },
        (response, status) => {
          if (status === "OK") {
            setDirections(response);
          } else {
            console.error(`Directions request failed due to ${status}`);
          }
        }
      );
    }
  }, [markers, window.google, mode]);

  useEffect(() => {
    if (employee) {
      try {
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/users/${employee}`, {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          })
          .then((res) => {
            console.log("res", res.data.details);
            setEmployeeDetails(res.data.details);
          });
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [employee]);

  const getFormattedTime = (time) => {
    if (time < 60) {
      return time + " sec";
    } else if (time > 60 && time < 3600) {
      return Math.floor(time / 60) + " min";
    } else {
      return Math.floor(time / 3600) + " hr";
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center my-2">
        <div className="innerDashSearchItems d-flex align-items-center">
          <Link to="/client-myemployee">
            <button className="innerdashboardBackButton">
              <img
                src="/assets/frontend/images/InnerDashboard/arrow.png"
                className="img-fluid"
                alt="arrow"
              />
            </button>
          </Link>
          <h5 className="innerDashboardTitle">Live Location</h5>
        </div>
        {/* // select driving mode or walking mode */}
        <Select
          showSearch={true}
          placeholder="Select Mode"
          optionFilterProp="children"
          defaultValue={{
            label: "Driving",
            value: "DRIVING",
          }}
          size="large"
          allowClear
          style={{
            minWidth: "250px",
          }}
          onChange={(value) => {
            setMode(value);
          }}
        >
          {modeList.map((item, index) => {
            return (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <LoadScript googleMapsApiKey="AIzaSyDj8C1VcZWPlnRrHMc_2VjMLVZ3HmVxdWw">
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "80vh" }}
          center={{
            lat: markers[1]?.position.lat || +userInfo.lat,
            lng: markers[1]?.position.lng || +userInfo.long,
          }}
          /* center={{
            lat: +userInfo.lat,
            lng: +userInfo.long,
          }} */
          zoom={15}
        >
          {markers.map((marker, index) => {
            if (window.google) {
              return (
                <Marker
                  key={index}
                  position={marker.position}
                  label={marker.label}
                  icon={{
                    url: marker?.icon?.url || markerIcon,
                    scaledSize: new window.google.maps.Size(25, 25),
                  }}
                />
              );
            }
          })}
          {directions && (
            <>
              <Polyline
                path={directions.routes[0].overview_path}
                options={{ strokeColor: "green" }}
              />
            </>
          )}
        </GoogleMap>
      </LoadScript>

      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          marginTop: "-155px",
          marginBottom: "80px",
        }}
      >
        {directions ? (
          <Row
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#58c8c8",
              zIndex: "1",
              padding: "10px",
              borderRadius: "10px",
              color: "white",
            }}
          >
            <Col sm={12} md={6} className="text-center">
              <h5>Restuarant</h5>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  style={{
                    borderRadius: "50%",
                    backgroundSize: "cover",
                    height: "32px",
                    width: "32px",
                    marginRight: "10px",
                  }}
                  src={
                    userInfo?.profilePicture
                      ? process.env.REACT_APP_ASSETs_BASE_URL +
                        "/" +
                        userInfo?.profilePicture
                      : null
                  }
                  className="img-fluid"
                  alt="custom-image1"
                />
                <div>{userInfo?.restaurantName}</div>
              </div>
            </Col>
            <Col sm={12} md={6} className="text-center">
              <h5>Employee</h5>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  style={{
                    borderRadius: "50%",
                    backgroundSize: "cover",
                    height: "32px",
                    width: "32px",
                    marginRight: "10px",
                  }}
                  src={
                    employeeDetails?.profilePicture
                      ? process.env.REACT_APP_ASSETs_BASE_URL +
                        "/" +
                        employeeDetails?.profilePicture
                      : null
                  }
                  className="img-fluid"
                  alt="custom-image1"
                />
                <div>{employeeDetails?.name}</div>
              </div>
            </Col>
            <Col sm={12} md={6} className="text-center">
              <h5>Distance</h5>
              <p>
                {
                  // km to mile
                  (
                    +directions?.routes[0].legs[0].distance.text.split(" ")[0] *
                    0.621371
                  ).toFixed(2) + " miles"
                }
                {/* {directions?.routes[0].legs[0].distance.value} m) */}
              </p>
            </Col>
            <Col sm={12} md={6} className="text-center">
              <h5>Duration</h5>
              <p>
                {getFormattedTime(directions?.routes[0].legs[0].duration.value)}
              </p>
            </Col>
          </Row>
        ) : null}
      </div>
    </div>
  );
};

export default Livelocation;
