import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Loader from "../../../common/Loader";
import { ContextApi } from "../../../hooks/ContextApi";
import { token } from "../../../utils/authentication";
import logo from "./Logo.png";
import "./print-invoice.css";
import dayjs from "dayjs";
export default function InvoiceDetails() {
  const { id } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const { currencyType } = useContext(ContextApi);
  const componentRef = React.createRef();

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/check-in-check-out-histories//${id}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token()}`, // Call the function to get the token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoiceData(data.details);
        } else {
          console.error("Failed to fetch invoice details");
        }
      })
      .catch((error) => {
        console.error("Error while fetching invoice details: ", error);
      });
  }, [id]);
  console.log("invoiceData: ", invoiceData);
  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    <div>
      {invoiceData ? (
        <>
          <div
            className="text-center"
            style={{
              // backgroundColor: "#58c8c8",
              color: "white",
              // padding: "10px",
            }}
          >
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  className="mt-5 px-4 text-center rounded"
                  style={{
                    backgroundColor: "#58c8c8",
                    color: "white",
                    border: "1px solid #58c8c8",
                    textDecoration: "none",
                  }}
                >
                  Print Now
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div
            className="container mx-auto p-5 m-5 rounded"
            style={{
              border: "1px solid #58c8c8",
              width: "700px",
              backgroundColor: "#FFF",
            }}
            ref={componentRef}
          >
            <div>
              <div className="text-center">
                <img
                  src={logo}
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                  alt="Logo"
                />
                <h5
                  style={{
                    color: "#58c8c8",
                    fontSize: "20px",
                  }}
                >
                  PLAGIT
                </h5>
              </div>
              <div
                className="to"
                style={{
                  lineHeight: "0.9",
                }}
              >
                <h5>To,</h5>
                <p>{invoiceData?.restaurantDetails?.restaurantName}</p>
                <p>{invoiceData?.restaurantDetails?.restaurantAddress}</p>
                <p>{invoiceData?.restaurantDetails?.email}</p>
                {/* <p>{invoiceData?.restaurantDetails?.restaurantPhone}</p> */}
              </div>
              <div
                className="d-flex flex-column align-items-end mb-5"
                style={{
                  lineHeight: "0.9",
                }}
              >
                <p>Invoice No: {invoiceData?.invoiceNumber}</p>
                <p>Invoice Date: {formatDate(invoiceData?.createdAt)}</p>
              </div>
              {/* <div className="title text-center m-2">
                <h5>
                  {invoiceData?.restaurantName} week from{" "}
                  {formatDate(invoiceData?.fromWeekDate)} to{" "}
                  {formatDate(invoiceData?.toWeekDate)}
                </h5>
                <div
                  style={{
                    lineHeight: "0.9",
                    marginTop: "20px",
                  }}
                >
                  <p>
                    Amount:
                    {invoiceData
                      ? ` ${currencyType} ${invoiceData?.clientAmount?.toFixed(2)}`
                      : ""}
                  </p>
                  <p>Vat:{invoiceData ? ` ${invoiceData.vat}%` : ""}</p>
                  <p>
                    Vat amount:
                    {invoiceData
                      ? ` ${currencyType}  ${invoiceData.vatAmount.toFixed(2)}`
                      : ""}
                  </p>
                  <p>
                    Platform fee:
                    {invoiceData
                      ? ` ${invoiceData.platformFee.toFixed(2)}`
                      : ""}
                  </p>
                </div>
                <hr
                  style={{
                    border: "1px solid black",
                  }}
                />
                <h5>
                  Total Amount:
                  {invoiceData
                    ? ` ${currencyType} ${invoiceData.totalAmount.toFixed(2)}`
                    : ""}
                </h5>
              </div> */}
              <div className="table-responsive">
                <table className="table">
                  <thead
                    style={{
                      backgroundColor: "#FFF2CC",
                      padding: "10px",
                    }}
                  >
                    <tr className="text-center">
                      <th>QUANTITY</th>
                      <th>DESCRIPTION</th>
                      <th>DATE</th>
                      <th
                        style={{
                          textAlign: "right",
                        }}
                      >
                        TOTAL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <small>One</small>
                      </td>
                      <td>
                        <small>Event Management Services staff members</small>
                      </td>
                      <td>
                        <small>
                          {" "}
                          {/* {formatDateRange(
                          invoiceData?.fromWeekDate,
                          invoiceData?.toWeekDate
                        )} */}
                          {dayjs(invoiceData?.createdAt).format("MMM D, YYYY")}
                        </small>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {invoiceData
                          ? `${currencyType}  ${invoiceData?.clientAmount?.toFixed(
                              2
                            )}`
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="2" className="text-end">
                        <small> VAT</small>
                      </td>
                      <td className="text-center">
                        <small>
                          {" "}
                          {invoiceData ? ` ${invoiceData.vat}%` : ""}
                        </small>
                      </td>
                      <td className="text-end">
                        {invoiceData
                          ? ` ${currencyType} ${invoiceData?.vatAmount?.toFixed(
                              2
                            )}`
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="text-end">
                        <small>Platform fee</small>
                      </td>
                      <td></td>
                      <td className="text-end">
                        {invoiceData
                          ? ` ${currencyType} ${invoiceData?.platformFee?.toFixed(
                              2
                            )}`
                          : ""}
                      </td>
                    </tr>

                    <tr
                      style={{
                        backgroundColor: "#FFF2CC",
                      }}
                    >
                      <td colSpan="3" className="text-end">
                        Total
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {invoiceData
                          ? `${currencyType}${invoiceData?.totalAmount?.toFixed(
                              2
                            )}`
                          : ""}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div
                className="bank-transfer mt-5"
                style={{
                  lineHeight: "0.9",
                }}
              >
                <h5>Bank Transfer</h5>
                <p>PLAGIT</p>
                <p>48 Warwick St Regent Street W1B 5AW</p>
                <p>London</p>
                <p>+44 20 3980 9360</p>
                <p>info@plagit.com</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
