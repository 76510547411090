import { useParams } from "react-router-dom";
import "./vc.css";
import { BiLogoPlayStore } from "react-icons/bi";
import { FaAppStore, FaAppStoreIos } from "react-icons/fa";
export default function VisitingCard() {
  const { name } = useParams();
  const imageBaseUrl = process.env.REACT_APP_ASSETs_BASE_URL;

  // Sample dataset of users
  const users = [
    {
      link: "mirko",
      name: "Mirko Picicco",
      designation: "CEO",
      company: "Plagit",
      email: "info@plagit.com",
      location: "202 Souk Al Bahar Saaha C, Downtown Dubai",
      image: `${imageBaseUrl}/S5wmmldcr-WhatsApp-Image-2024-05-14-at-2.51.53-AM.jpeg`,
    },
  ];
  const user = users.find(
    (user) => user.link.toLowerCase() === name.toLowerCase()
  );

  return (
    <div className="visiting-card-unique-container mt-5">
      {user ? (
        <>
          <img
            src={user.image}
            alt={user.name}
            className="unique-profile-image"
          />
          <div className="unique-profile-info">
            <h1>{user.name}</h1>
            <h2>
              {user.designation} at {user.company}
            </h2>
          </div>
          <div className="unique-profile-contact">
            <p>
              <strong>Email:</strong> {user.email}
            </p>
            <p>
              <strong>Location:</strong> {user.location}
            </p>
          </div>
          <div className="px-4 text-center">
            <p>
              <strong>
                Download our app:
                <a
                  href="https://apps.apple.com/us/app/plagit/id6446052294"
                  className="text-decoration-none text-black"
                >
                  {" "}
                  <FaAppStoreIos className="m-2 h4" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.invain.mh"
                  className="text-decoration-none text-black"
                >
                  {" "}
                  <BiLogoPlayStore className="m-2 h4" />
                </a>
              </strong>
            </p>
          </div>
        </>
      ) : (
        <div className="p-3 text-danger fw-bold">
          User not found with the name: {name}
        </div>
      )}
    </div>
  );
}
