import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import ourmission2 from "./our-mission2.png";
const Mission = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="At Plagit Premier Staffing, we prioritize shared success. Entrust us with your hospitality, hotel, and restaurant staffing needs, and witness our commitment to excellence.
          "
        />
        <title>PLAGIT: Our Mission in Hospitality Recruitment</title>
      </Helmet>

      <div class="banner-header section-padding valign bg-img bg-fixed img-fluid container">
        <img
          src="assets/frontend/images/aboutUs/Mission-&-Values-01-cc.png"
          className="banner-header section-padding valign bg-img bg-fixed img-fluid container"
          alt="our services"
          style={{
            minHeight: "400px",
            width: "100%",
            objectFit: "cover",
          }}
        />
      </div>

      <div class="container" style={{ marginTop: "60px" }}>
        <div class="row justify-content-end">
          <div class="col-12 col-md-6 col-lg-6">
            <h2 class="my-5">{t("our_mission")} </h2>
            <p className="about_us_section_paragraph">
              {t("our_mission_description")}
            </p>
          </div>
          <div class="col-12 col-md-6 col-lg-6 mt-5">
            <img src={ourmission2} alt="the mission" class="img-fluid" />
          </div>
        </div>
        {/* <div class="row justify-content-end mt-5">
          <div class="col-12 col-md-6 col-lg-6 mt-5">
            <img
              src="assets/frontend/images/mission/vission.jpg"
              alt="the mission"
              class="img-fluid"
            />
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <h2 class="my-5">{t("our_vision")}</h2>
            <p className="about_us_section_paragraph">
              {t("our_vision_description")}
            </p>
          </div>
        </div>{" "} */}
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Mission;
