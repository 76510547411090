import { Form, Input } from "antd";
import React, { useState } from "react";
import { loginHandler, updatePasswordHandler } from "../../../api/auth";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { responseNotification } from "../../../utils/notifcation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ChangeSetting() {
  const { t } = useTranslation();
  const jwtdecode = jwtTokenDecode();

  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const id = jwtdecode._id;
    if (values.newPassword !== values.newRepeatPassword) {
      setError("New password and repeat password does not match");
      return;
    }
    if (id) {
      const updatePasswordReceivedFields = {
        id: id,
        newPassword: values?.newPassword,
        currentPassword: values?.currentPassword,
      };

      if (updatePasswordReceivedFields) {
        setLoading(true);
        updatePasswordHandler(updatePasswordReceivedFields)
          .then((res) => res.json())
          .then((res) => {
            if (res?.statusCode === 200) {
              setError(undefined);
              setLoading(false);
              responseNotification("Password updated successfully!", "success");
              loginHandler({
                email: jwtdecode.email,
                password: values?.newPassword,
              })
                .then((res) => res.json())
                .then((res) => {
                  if (res?.statusCode === 200) {
                    localStorage.setItem("token", res?.token);
                    form.resetFields();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else if (res?.statusCode === 400) {
              setError(res?.errors?.[0].msg);
              setLoading(false);
            } else if (res?.statusCode === 500) {
              setError(res?.message);
              setLoading(false);
            }
          });
      }
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid col-md-6 col-sm-24 mb-3">
      <div className="row mt-4">
        <div className="d-flex mb-4 align-items-md-center">
          <button className="commonBackButton" onClick={goBack}>
            <img
              src="assets/frontend/images/InnerDashboard/arrow.png"
              className="img-fluid"
              alt="arrow"
            />
          </button>
          <h4 className="title mt-2">{t("setting")}</h4>
        </div>
      </div>
      <div className="card">
        <div className="card-header">{t("change_password")}</div>
        <div className="card-body">
          <Form
            className="ant-form ant-form-vertical"
            layout="vertical"
            onFinish={onFinish}
            form={form}
          >
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <Form.Item
                    label={t("current_password")}
                    name="currentPassword"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("please_enter_current_password")
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t("enter_current_password")}
                      className="ant-input ant-input-lg"
                      visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-12">
                  <Form.Item
                    label={t("new_password")}
                    name="newPassword"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("please_enter_new_password")
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t("enter_new_password")}
                      className="ant-input ant-input-lg"
                      visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-12">
                  <Form.Item
                    label={t("repeat_new_password")}
                    name="newRepeatPassword"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("repeat_new_password")
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t("enter_repeat_new_password")}
                      className="ant-input ant-input-lg"
                      visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                      }}
                    />
                  </Form.Item>
                </div>

                {getError ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="error-message">
                        <p className="error-text-color text-danger">
                          {getError}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : undefined}

                <div className="col-md-6">
                  <Form.Item>
                    <button
                      disabled={loading}
                      className="btn"
                      style={{ background: "#58c8c8", color: "white" }}
                      type="submit"
                    >
                      {!loading && t("submit")}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {t("please_wait...")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ChangeSetting;
