import React, { useContext, useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { AiOutlineMessage } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { changeLanguageFunc, getUserId, getUserInfo } from "../../../../utils/commonFunctions";
import { Dropdown } from "antd";
import { DownOutlined } from '@ant-design/icons';
import Notification from "../../../../layouts/frontend/dashboard/Notification/Notification";
import notificationSound from "../../../../layouts/frontend/dashboard/Notification/notification-sound.mp3";
import { ContextApi } from "../../../../hooks/ContextApi";
import { io } from "socket.io-client";
import { responseNotification } from "../../../../utils/notifcation";
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { FiPower } from "react-icons/fi";
import { useTranslation } from "react-i18next";
export default function EmployeeNav() {
    const windowWidth = useWindowWidth();
    const [selected, setSelected] = useState(
        localStorage.getItem("countryCode") || "GB"
    );
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userInfo = getUserInfo();
    const {
        notificationCount,
        setNotificationCount,
    } = useContext(ContextApi);
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
        withCredentials: false,
        transports: ["websocket", "polling", "flashsocket"],
    });


    useEffect(() => {
        console.log("WebSocket connection initiated.");

        socket.on("notification", (dataFromServer) => {
            console.log("Received notification:", dataFromServer);

            const logedInUserId = getUserId();
            if (dataFromServer.userInfo._id === logedInUserId) {
                setNotificationCount(notificationCount + 1);
                responseNotification(dataFromServer.body, "success");
                new Audio(notificationSound).play();
            }
        });

        socket.on("new_message", (data) => {
            var currentUrl = window.location.href;
            // CHECK IF THE MESSAGE IS FROM THE ADMIN
            if (
                data.message.receiverDetails?.length > 1 &&
                data.message.receiverDetails
                    .map((item) => item.receiverId)
                    .includes(getUserId()) &&
                !currentUrl.includes("/client-help-support")
            ) {
                const message = `Admin: ${data.message.text}`;
                responseNotification(message, "success");
                new Audio(notificationSound).play();
                return;
            }

            // CHECK IF THE MESSAGE IS FROM THE USER
            if (data.message.receiverDetails[0].receiverId === getUserId()) {
                const message = `${data.message.senderDetails.name} : ${data.message.text}`;
                responseNotification(message, "success");
                new Audio(notificationSound).play();
            }
        });

        return () => {
            socket.off("notification");
            socket.off("new_message");
            console.log("WebSocket connection closed.");
        };
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("loginData");
        navigate("/");
        window.location.reload();
    };



    const [message, setMessage] = useState("");

    const notificationMenu = (
        <Notification setNotificationCount={setNotificationCount} />
    );

    const profilePictureUrl =
        userInfo.profilePicture && userInfo.profilePicture != ""
            ? `${process.env.REACT_APP_ASSETs_BASE_URL}/${userInfo.profilePicture}`
            : "/assets/client.jpg";

    const items = [
        {
            label: (
                <Link to="/profile" className="profile-item">
                    <FaRegUserCircle className="pro-icons" />
                    Edit Profile
                </Link>
            ),
            key: "0",
        },
        {
            label: (
                <Link to="/setting" className="profile-item">
                    <MdOutlinePassword className="change-password" />
                    Setting
                </Link>
            ),
            key: "1",
        },
        {
            label: (
                <a href="" onClick={handleLogout} className="profile-item">
                    <FiPower className="close-btn" />
                    Logout
                </a>
            ),
            key: "2",
        },
    ];


    return (
        <>
            <div className="bg-white p-3">
                <div className="container header_container">

                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar DashboardNavban navbar-expand-md navbar-light">
                                <a className="navbar-brand " href="/">
                                    <img
                                        className="img-fluid Dashboard_nav_logo"
                                        style={{
                                            height: "38px",
                                        }}
                                        src="/assets/frontend/images/Dashboardimages/logo_full.png"
                                        alt="Default"
                                    />
                                </a>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon" />
                                </button>
                                <div
                                    className="Dash_navb_custom collapse navbar-collapse"
                                    id="navbarSupportedContent"
                                >
                                    <ul className="Dashboard_navbar_custom navbar-nav ms-auto mb-2 mb-lg-0">


                                        <li className="nav-item">
                                            <Dropdown overlay={notificationMenu} trigger={["click"]}>
                                                <a
                                                    className="Dashboard_navbar_custom_hover nav-link"
                                                    href="#"
                                                >
                                                    <div
                                                        className="navLinkImageTextWraper"
                                                        style={{ position: "relative" }}
                                                    >
                                                        <IoMdNotificationsOutline
                                                            style={{
                                                                fontSize: "30px",
                                                            }}
                                                        />
                                                        {notificationCount > 0 && (
                                                            <span className="bage_count">
                                                                {notificationCount > 20
                                                                    ? "20+"
                                                                    : notificationCount}
                                                            </span>
                                                        )}
                                                    </div>
                                                </a>
                                            </Dropdown>
                                        </li>

                                        <li className="nav-item">
                                            <Link
                                                className="Dashboard_navbar_custom_hover nav-link"
                                                to="/client-help-support"
                                            >
                                                <div
                                                    className="navLinkImageTextWraper"
                                                    style={{ position: "relative" }}
                                                >
                                                    <AiOutlineMessage
                                                        style={{
                                                            fontSize: "30px",
                                                        }}
                                                    />

                                                    <span className="bage_count">
                                                        {/* {message == "" ? 0 : message} */} 0
                                                    </span>
                                                </div>
                                            </Link>
                                        </li>

                                        <li>
                                            <ReactFlagsSelect
                                                defaultCountry="GB"
                                                countries={["GB", "AE", "IT"]}
                                                selected={selected || "GB"}
                                                customLabels={{
                                                    US: "Eng",
                                                    GB: "Eng",
                                                    AE: "Ara",
                                                    IT: "Ita",
                                                }}
                                                onSelect={(e) => {
                                                    setSelected(e);
                                                    changeLanguageFunc(e);
                                                }}
                                            />
                                        </li>


                                        <li className="nav-item">
                                            <Dropdown
                                                menu={{
                                                    items,
                                                }}
                                                trigger={["click"]}
                                            >
                                                <a
                                                    className="profile-icon-menu"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img
                                                        src={profilePictureUrl}
                                                        alt="Profile"
                                                        className="rounded-circle user-icon-btn"
                                                    />
                                                    <DownOutlined className="down-arrow" />
                                                </a>
                                            </Dropdown>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>




                    {/* <div className="">
                        <Link to="/">
                            <img
                                src={`assets/frontend/images/indexImages/logo_full.png`}
                                className="img-fluid"
                                style={{
                                    height: windowWidth < 576 ? "30px" : "50px",
                                    width: windowWidth < 576 ? "100px" : "150px",
                                }}
                                alt="Plagit Logo"
                            />
                        </Link>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">

                        <IoMdNotificationsOutline
                            style={{
                                fontSize: "20px",
                                color: "#000",
                                cursor: "pointer",
                            }}
                        />

                        <AiOutlineMessage
                            style={{
                                fontSize: "17px",
                                color: "#000",
                                cursor: "pointer",
                                marginLeft: "20px",
                            }}
                        />
                        <ReactFlagsSelect
                            defaultCountry="GB"
                            className="mt-1"
                            countries={["GB", "AE", "IT"]}
                            selected={selected || "GB"}
                            customLabels={{
                                US: "Eng",
                                GB: "Eng",
                                AE: "Ara",
                                IT: "Ita",
                            }}
                            onSelect={(e) => {
                                setSelected(e);
                                changeLanguageFunc(e);
                            }}
                        />
                    </div> */}
                </div>
            </div>
        </>
    );
}
