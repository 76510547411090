import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillArrowLeftCircleFill, BsTelephoneFill } from "react-icons/bs";
import { IoLocation } from "react-icons/io5";
import { Link } from "react-router-dom";
import img1 from "./images/1.png";
import img2 from "./images/2.png";
import img3 from "./images/3.png";
import g4 from "./images/g4.png";
import s1 from "./images/s1.png";
import bg2 from "./images/school-bg.png";
import school from "./images/school.png";
import t1 from "./images/t1.png";
const School = () => {
  const { t } = useTranslation();

  return (
    <div className="my-5">
      <div
        className="card  py-5 pt-3 container "
        style={{ borderRadius: "20px", marginBottom: "-70px" }}
      >
        <div className="d-flex justify-content-between my-5">
          <Link
            to="/mh-academy"
            style={{ textDecoration: "none", color: "gray" }}
          >
            <p className="mt-3">
              <BsFillArrowLeftCircleFill
                style={{ color: "#58c8c8", fontSize: "30px" }}
              />{" "}
              Back
            </p>
          </Link>
          <div className="d-flex">
            <img src={t1} alt="the" height={50} />
            <p
              className="mt-3 mx-1"
              style={{ fontSize: "20px", fontWeight: 500 }}
            >
              {t("mh_academy")}
            </p>
          </div>
          <p></p>
        </div>
        <p
          className="text-center"
          style={{ color: "#58c8c8", fontSize: "17px", fontWeight: 600 }}
        >
          {t("hospitality_education_and_training_school")}
        </p>
        {/* <p style={{ color: "#7B7B7B" }} className="text-center">
          Welcome to our comprehensive and dynamic Waiter Course! Whether you
          are an aspiring hospitality professional or looking to enhance your
          existing skills, our course is designed to equip you with the
          knowledge and expertise needed to excel in the exciting world of
          waitstaff and hospitality. Our Waiter Course is a carefully crafted
          program that covers a wide range of essential topics to prepare you
          for a successful career in the food and beverage industry.
        </p> */}
      </div>

      <div style={{ backgroundImage: `url(${bg2})` }} className="py-5 mb-5">
        <div
          className="d-lg-flex justify-content-between container "
          style={{ marginTop: "7%", marginBottom: "5%" }}
        >
          <div className="d-flex mt-5">
            <img
              src={img1}
              alt="the"
              className="mx-5 mt-4"
              height={80}
              width={80}
            />
            <p
              style={{
                color: "#7B7B7B",
                marginRight: "5%",
                lineHeight: "36px",
              }}
            >
              {t("school_desc1")}
            </p>
          </div>

          <div
            className="d-flex bg-white mx-5 "
            style={{ borderRadius: "20px", padding: "5%" }}
          >
            <img
              src={g4}
              alt="thw"
              style={{
                marginTop: "-40px",
              }}
              height={50}
            />
            <img
              src={s1}
              style={{
                marginLeft: "-40px",
                transform: "scale(1.1)",
              }}
              alt="thw"
            />
          </div>
        </div>

        <div className="d-lg-flex justify-content-between container">
          <div
            className="d-flex bg-white  mx-5"
            style={{ borderRadius: "20px", padding: "5%" }}
          >
            {/* <img
              src={g4}
              alt="thw"
              style={{
                marginTop: "-40px",
                marginRight: "20px",
              }}
              height={60}
            /> */}

            <img
              src={school}
              style={{
                marginLeft: "-40px",
                transform: "scale(1.3)",
              }}
              height={190}
              alt="thw"
              className="mx-2 img-fluid"
            />
            {/* <img
              src={s3}
              alt="thw"
              style={{
                marginTop: "-40px",
                marginLeft: "40px",
              }}
              height={60}
            /> */}
          </div>
          <div className="d-flex mt-5">
            <img
              src={img2}
              alt="the"
              className="mx-5 mt-4"
              height={80}
              width={80}
            />
            <p
              style={{
                color: "#7B7B7B",
                marginRight: "3%",
                lineHeight: "36px",
              }}
            >
              {t("school_desc2")}
            </p>
          </div>
        </div>

        <div
          className="d-lg-flex justify-content-between container"
          style={{ marginTop: "5%", marginBottom: "5%" }}
        >
          <div className="d-flex mt-5 w-100 w-lg-50">
            <img
              src={img3}
              alt="the"
              className="mx-5 mt-4"
              height={80}
              width={80}
            />
            <p
              style={{
                color: "#7B7B7B",
                marginRight: "5%",
                lineHeight: "36px",
              }}
            >
              {t("school_desc3")}

              <br />
              <IoLocation
                style={{
                  color: "black",
                  marginRight: "5px",
                }}
              />
              <span
                className="fw-bold"
                style={{
                  color: "black",
                  // fontSize: "13px",
                }}
              >
                202 Souk Al Bahar Saaha C, Downtown Dubai
              </span>
            </p>
          </div>

          <div
            className="d-flex bg-white mx-5"
            style={{ borderRadius: "20px", padding: "" }}
          >
            {/* <img src={g4} alt="thw" className="mx-3 mt-3" height={60} /> */}
            {/* <img src={s6} alt="thw" height={250} width={400} /> */}
            <iframe
              className="img-fluid"
              style={{
                border: "0",
                borderRadius: "20px",
                width: "500px",
                height: "305px",
              }}
              id="gmap_canvas"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.280882565924!2d55.27389507483741!3d25.193748481841432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f68299c6b1377%3A0x9ac95c9c94112b25!2sSouk%20Al%20Bahar%20-%20Downtown%20Dubai%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sbd!4v1713608771903!5m2!1sen!2sbd"
            ></iframe>
          </div>
        </div>
      </div>

      <div
        className="container my-5  pb-3 d-lg-flex justify-content-around"
        style={{ backgroundColor: "black", borderRadius: "20px" }}
      >
        <div className="py-5">
          <h5 className="text-white"> {t("info_message")}</h5>
          <p
            className="text-white mt-2"
            style={{ color: "gray", fontSize: "13px" }}
          >
            {t("contact_us_message")}
          </p>
          <div className="me-5 mt-4 d-flex ">
            <div className=" d-flex">
              <BsTelephoneFill style={{ marginTop: "4px", color: "white" }} />
              <p
                className="ms-3"
                style={{ color: "white", fontWeight: 500, fontSize: "15px" }}
              >
                +971 521689006
              </p>
            </div>
            <div className=" d-flex ms-5">
              <AiOutlineMail style={{ marginTop: "4px", color: "white" }} />
              <p
                className="ms-2"
                style={{ color: "white", fontWeight: 500, fontSize: "15px" }}
              >
                info@plagit.com
              </p>
            </div>
          </div>
        </div>

        <div
          className=""
          style={{
            borderRadius: "15px",
            backgroundColor: "white",
            margin: "5%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <input
            className="px-5 "
            style={{
              borderRadius: "10px",
              backgroundColor: "white",
              border: "1px solid white",
            }}
            placeholder="Enter Email Address"
          />
          <a
            href="mailto:info@plagit.com?subject=Hello%20MH%20Team"
            style={{
              backgroundColor: "#58c8c8",
              color: "white",
              border: "1px solid #58c8c8",
              // marginLeft: "-150px",
              borderRadius: "15px",
              textDecoration: "none",
            }}
            className="px-4 mx-3 mt-3 text-center mb-3"
          >
            Continue
          </a>
        </div>
      </div>
    </div>
  );
};

export default School;
