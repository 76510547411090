import React from 'react'
import './coming_style.scss'

export default function ComingSoonPage() {
    return (
        <div className="wrapper">
            <h1>Coming soon<span className="dot">.</span></h1>
            <p>We are building our Premium Features.</p>
            {/* <div className="icons">
                <a href=""><i className="fa fa-twitter"></i></a>
                <a href=""><i className="fa fa-youtube-play"></i></a>
                <a href=""><i className="fa fa-paper-plane"></i></a>
            </div> */}
        </div>
    )
} 