import { Form } from "antd";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { FiPhoneOutgoing } from "react-icons/fi";
import { MdPlace } from "react-icons/md";
import { TfiEmail } from "react-icons/tfi";

import { addContactHandler } from "../../../api/contact";
import { responseNotification } from "../../../utils/notifcation";
import contact_us from "./Contact-Us-01-cc.png";
const Contact = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const name = values?.name;
    const email = values?.email;
    const message = values?.message;

    const addContactFields = { name, email, message };

    if (addContactFields) {
      setLoading(true);
      addContactHandler(addContactFields)
        .then((res) => res.json())
        .then((res) => {
          if (res?.statusCode === 201) {
            setError(undefined);
            setLoading(false);
            responseNotification(
              "Contact information received successfully!",
              "success"
            );
            form.resetFields();
          } else if (res?.statusCode === 400) {
            setError(res?.errors?.[0].msg);
            setLoading(false);
          } else if (res?.statusCode === 500) {
            setError(res?.message);
            setLoading(false);
          }
        });
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        className="banner-header section-padding valign bg-img bg-fixed img-fluid "
        style={{
          backgroundImage: `url(${contact_us})`,
          minHeight: "400px",
          width: "100%",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundColor: "white",
        }}
      >
        <div className="container">
          {/* <div className="row">
            <div
              className="col-md-12  caption align-middle"
              style={{ marginTop: "150px" }}
            >
              <h5 style={{ color: "white", fontSize: "18px" }}>
                PLAGIT PREMIER STAFFING SOLUTIONS
              </h5>
              <h1 style={{ color: "white", fontSize: "70px" }}>Contact Us</h1>
            </div>
          </div> */}
        </div>
      </div>

      <div className="container " style={{ marginTop: "60px" }}>
        <div className="row justify-content-end">
          <div className="col-12 col-md-6 col-lg-6 ">
            <div className="about_us_section_title">PLAGIT</div>
            {/* <div className="about_us_section_sub_title">
              <strong>PREMIER STAFFING SOLUTIONS</strong>
            </div> */}
            <br />
            {/* <h5 className="about_us_section_paragraph">
              {t(
                "mh_is_a_premium_staffing_solution_with_years_of_hospitality_industry_experience._we_understand_the_challenges_our_clients_have_in_recruiting_competent_and_experienced_staff."
              )}
            </h5> */}

            <div className="d-flex mt-4">
              <a href="tel:+4407500146699">
                <FiPhoneOutgoing
                  style={{
                    fontSize: "45px",
                    color: "#58c8c8",
                    marginTop: "15px",
                  }}
                />
              </a>
              <div className="mx-5">
                <p>{t("reservation")}</p>
                <h5 style={{ fontSize: "20px", color: "#58c8c8" }}>
                  UK +44 7500146699
                </h5>
                <h5 style={{ fontSize: "20px", color: "#58c8c8" }}>
                  UAE +971 521689006
                </h5>
                <h5 style={{ fontSize: "20px", color: "#58c8c8" }}>
                  IT +39 3409565486
                </h5>
              </div>
            </div>
            <div className="d-flex mt-4">
              <a href="mailto:info@plagit.com">
                <TfiEmail
                  style={{
                    fontSize: "45px",
                    color: "#58c8c8",
                    marginTop: "15px",
                  }}
                />
              </a>
              <div className="mx-5">
                <p>{t("email_info")}</p>
                <h5 style={{ fontSize: "15px", color: "#58c8c8" }}>
                  info@plagit.com
                </h5>
              </div>
            </div>

            <div className="d-flex mt-4">
              <MdPlace
                style={{
                  fontSize: "45px",
                  color: "#58c8c8",
                  marginTop: "15px",
                }}
              />
              <div className="mx-5">
                {/* <p>{t("address")}</p> */}
                <p>Address</p>
                <h5 style={{ fontSize: "15px", color: "#58c8c8" }}>
                  202 Souk Al Bahar Saaha C, Downtown Dubai
                </h5>
                {/*  <div style={{ fontSize: "12px", color: "#58c8c8" }}>
                  {t(
                    "the_property_is_5_minutes_walk_(0.2_miles)_to_chancery_lane_underground_station_or_8_minutes’_walk_(0.7_miles)_from_farringdon_station",
                  )}
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-5">
            <h3 className="mb-3">{t("get_in_touch")}</h3>
            <Form
              className="ant-form ant-form-vertical"
              layout="vertical"
              onFinish={onFinish}
              form={form}
            >
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6  form-group">
                  <Form.Item
                    name="name"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("please_enter_name"),
                      },
                    ]}
                  >
                    <input
                      name="name"
                      type="text"
                      placeholder={t("enter_name")}
                      required=""
                      className="form-control"
                      style={{ outline: "none" }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-md-6 col-lg-6 form-group">
                  <Form.Item
                    name="email"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("please_enter_email"),
                      },
                    ]}
                  >
                    <input
                      name="email"
                      type="email"
                      placeholder={t("enter_email")}
                      required=""
                      className="form-control"
                      style={{ outline: "none" }}
                    />
                  </Form.Item>
                </div>

                <div className="col-12 col-md-12 col-lg-12 form-group">
                  <Form.Item
                    name="message"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("please_enter_message"),
                      },
                    ]}
                  >
                    <textarea
                      style={{ height: 100 }}
                      placeholder={t("enter_message")}
                      className="form-control"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-12">
                  <button
                    disabled={loading}
                    className="btn"
                    style={{
                      width: "152px",
                      height: "40px",
                      backgroundColor: "#58c8c8",
                      color: "#ffffff",
                      fontWeight: "bold",
                      marginBottom: "50px",
                    }}
                    type="submit"
                  >
                    {!loading && (
                      <div> {t("home_contact_form_send_button")}</div>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {t("please_wait...")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              width="100%"
              height="500px"
              id="gmap_canvas"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.280882565924!2d55.27389507483741!3d25.193748481841432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f68299c6b1377%3A0x9ac95c9c94112b25!2sSouk%20Al%20Bahar%20-%20Downtown%20Dubai%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sbd!4v1713608771903!5m2!1sen!2sbd"
              // frameborder="0"
              // scrolling="no"
              // marginheight="0"
              // marginwidth="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
