import ImgCrop from "antd-img-crop";
import React, { useCallback, useEffect, useState } from "react";

import { Form, Upload } from "antd";
import axios from "axios";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { employeeRegisterHandler } from "../../../api/employee";
import { fetchPositionListForDropdownHandler } from "../../../api/position";
import { responseNotification } from "../../../utils/notifcation";
import FilteredCountryWiseValidationRules from "../../../utils/static/filteredCountry";
import PhoneInput from "react-phone-input-2";
import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
    validatePhoneNumberLength,
} from "libphonenumber-js";

import PlagitLogo from "../login/images/plagit_logo.png";
import CoverBg from "../login/images/banner_image.png";
import PlayStoreBtn from "../login/images/play_store_btn.png";
import AppleStoreBtn from "../login/images/apple_store_btn.png";
import AppsDownloadText from "../login/images/apps_download_text.png";
import { Helmet } from "react-helmet";

function EmployeeRegister() {
    const { t } = useTranslation();
    const [referPerson, setReferPerson] = useState([]);
    const [sourceFrom, setSourceFrom] = useState([]);
    const [position, setPosition] = useState([]);

    const [loading, setLoading] = useState(false);
    const [getError, setError] = useState();

    const [profilePicture, setProfilePicture] = useState([]);
    const [summaryPdf, setSummaryPdf] = useState([]);
    const [summaryPdfFileShow, setSummaryPdfFileShow] = useState(undefined);

    const [emiratesIdFile, setEmiratesIdFile] = useState([]);
    const [visaFile, setVisaFile] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [extraFields, setExtraFields] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [documentUrl, setDocumentUrl] = useState({}); // State variable to store the document URL
    const [fileLists, setFileLists] = useState({}); // State variable to store file lists for each field

    // console.log("selectedCountry", selectedCountry);

    const handleFileChange = (info, fieldName) => {
        if (info.file.status === "done") {
            // File upload is complete
            const updatedFileLists = { ...fileLists }; // Make a copy of the current state
            updatedFileLists[fieldName] = info.fileList; // Update the state for the specific field
            setFileLists(updatedFileLists); // Set the updated state
        }

        // Create a payload for the API request
        const payload = new FormData();
        payload.append("document", info.file.originFileObj);
        fetch(
            "http://server.mhpremierstaffingsolutions.com/api/v1/document/upload-document",
            {
                method: "POST",
                body: payload,
            }
        )
            .then((response) => response.json())
            .then((data) => {
                // Handle the API response here
                console.log("data -----", data);
                setDocumentUrl((prev) => {
                    return {
                        ...prev,
                        [fieldName]: data?.details?.document,
                    };
                });
                setUploadedFiles((prevUploadedFiles) => ({
                    ...prevUploadedFiles,
                    [fieldName]: data?.document?._id,
                }));
            })
            .catch((error) => {
                console.error("API error:", error);
            });
    };

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const fetchDocumentFields = async () => {
        const data = { country: selectedCountry };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/document/get-fields`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            return responseData?.details?.fields;
        } catch (error) {
            // Handle errors here
            console.error("Error:", error);
            return [];
        }
    };

    // if selectedCountry is not null then fetch document fields
    useEffect(() => {
        if (selectedCountry) {
            fetchDocumentFields().then((fields) => {
                setExtraFields(fields);
            });
        }
    }, [selectedCountry]);

    // console.log(extraFields);

    const onProfileChange = ({ fileList: newFileList }) => {
        setProfilePicture(newFileList);
    };

    // image preview
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        if (imgWindow) {
            imgWindow.document.write(image.outerHTML);

            // Display the file name below the image
            imgWindow.document.write(`<p>${file.name}</p>`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/users/list?isReferPerson=YES`
                );
                setReferPerson(response?.data?.users);
            } catch (error) { }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchSourceFromData = async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/sources/list-for-dropdown`
            );
            setSourceFrom(response?.data?.sources);
        };

        fetchSourceFromData();
    }, []);

    //Fetch position list for dropdown
    const fetchPositionData = useCallback(async () => {
        await fetchPositionListForDropdownHandler().then((res) => {
            setPosition(res?.data?.positions);
        });
    }, []);

    useEffect(() => {
        fetchPositionData();
    }, []);

    const onFinish = async (values) => {
        if (true) {
            setLoading(true);

            const file = new FormData();

            file.append("firstName", values?.firstName);
            file.append("lastName", values?.lastName);
            file.append("email", values?.email);
            file.append("phoneNumber", values?.phoneNumber);
            file.append("countryName", values?.countryName);
            file.append("positionId", values?.positionId);

            if (_.size(summaryPdf)) {
                file.append("cv", summaryPdf?.[0].originFileObj);
            }

            if (_.size(profilePicture)) {
                file.append("profilePicture", profilePicture?.[0].originFileObj);
            }

            const transformedData = [];

            for (const fieldName in documentUrl) {
                const fileNames = documentUrl[fieldName];
                for (const fileName of fileNames) {
                    transformedData.push({
                        inputType: "file",
                        label: "",
                        placeholder: "",
                        disabled: false,
                        required: true, // You can adjust this as needed
                        value: fileName,
                    });
                }
            }

            const transformedPayload = JSON.stringify(transformedData);

            // Append the array as "documents"
            file.append("documents", JSON.stringify(transformedPayload));

            await employeeRegisterHandler(file)
                .then((res) => res.json())
                .then((res) => {
                    if (res.statusCode === 201) {
                        responseNotification(
                            "Candidate registered successfully!",
                            "success"
                        );
                        // form.resetFields();
                        setSummaryPdf([]);
                        navigate("/register-welcome");
                    } else if (res?.statusCode === 400) {
                        // toast.error(res?.message);
                        responseNotification(res?.message, "error");
                        setError(res?.message);
                        console.log("res: ", res);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setError(res?.message);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false);
                });
        }
    };

    //CV onchange
    const summaryPdfChange = ({ fileList: newFileList }) => {
        setSummaryPdf(newFileList);
        setSummaryPdfFileShow(newFileList[0]?.originFileObj?.name);
    };

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content=" Access the PLAGIT portal. Log in to connect with top-tier talent, manage job postings, and ensure your company's recruitment success."
                />
                <title>Candidate Register | PLAGIT: Your Gateway to Talent Excellence</title>
            </Helmet>

            <section className="total_wrapper">
                <div className="container-fluid">
                    <div className="row custom_coloring_row">
                        {/*leftpart start*/}

                        <div className="col-lg-6 col-md-6 Login_Page_left_part Registration_left_part">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Link to="/">
                                            <img
                                                src="assets/frontend/images/registrationFormImages/clientAndEmployee/Group 685.png"
                                                className="img-fluid"
                                                alt="image"
                                            />
                                        </Link>

                                        <Link className="top_logo_left" to="/">
                                            <img src={PlagitLogo} className="img-fluid" alt="logo" />
                                        </Link>
                                    </div>
                                    <div className="col-lg-12 helps_you_hire">
                                        <h4>
                                            PLAGIT {t("helps_you_hire_great")} &amp; {t("experienced_workers_at_a_moment's_notice")}
                                        </h4>
                                    </div>

                                    <div className="col-lg-12 text-center">
                                        <img
                                            className="img-fluid cover_image"
                                            src={CoverBg}
                                            alt="cover"
                                        />
                                    </div>
                                </div>
                                <div className="row footer_apps_download_link_btn">
                                    <div className="col-lg-7">
                                        <img
                                            className="img-fluid apps_download_btn_image_text"
                                            src={AppsDownloadText}
                                            alt=""
                                        />
                                        {/* <div className="download_app_message_div">
                                        <div className="plagit_app">
                                            plagit app
                                        </div>
                                        <div className="available_now_text">
                                            Is available now on android and ios
                                        </div>
                                    </div> */}
                                    </div>
                                    <div className="col-lg-5 apps_download_brn_group">
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.invain.mh&pli=1"
                                            target="_blank"
                                            className="play_store_btn"
                                        >
                                            <img className="img-fluid" src={PlayStoreBtn} alt="cover" />
                                        </a>
                                        <a
                                            href="https://apps.apple.com/us/app/plagit/id6446052294"
                                            target="_blank"
                                            className="apple_store_btn"
                                        >
                                            <img
                                                className="img-fluid"
                                                src={AppleStoreBtn}
                                                alt="cover"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* 
                    <div className="col-lg-6 col-md-6 col-sm-12 Registration_left_part ">
                        <div className="row left_part_wrapper">
                            <div className="button_wrapper">
                                <Link to="/login">
                                    <img
                                        src="assets/frontend/images/registrationFormImages/clientAndEmployee/Group 685.png"
                                        className="img-fluid"
                                        alt="image"
                                    />
                                </Link>
                            </div>
                            <div className="logo_wrapper text-center">
                                <img
                                    className="img-fluid"
                                    src="assets/frontend/images/registrationFormImages/clientAndEmployee/Logo.png"
                                    alt="image"
                                />
                            </div>
                            <div className="premier_stuffing_wrapper text-center">
                                <h1>PLAGIT</h1>
                            </div>
                            <div className="mh_premier_wrapping text-center">
                                <p>
                                    PLAGIT {t("helps_you_hire_great")} &amp;
                                    {t("experienced_workers_at_a_moment's_notice")}
                                </p>
                            </div>
                        </div>
                    </div> */}
                        {/*leftpart end*/}

                        {/*Right Part Start*/}
                        <div className="col-lg-6 col-md-6 col-sm-12 Registration_page_right_part">
                            <div className="eclips_wrapper1">
                                <img
                                    className="img-fluid"
                                    src="assets/frontend/images/registrationFormImages/clientAndEmployee/Ellipse 70.png"
                                />
                            </div>
                            <div className="eclipse_wrapper2">
                                <img
                                    className="img-fluid"
                                    src="assets/frontend/images/registrationFormImages/clientFormPictures/../clientAndEmployee/Ellipse 69.png"
                                    alt="image"
                                />
                            </div>
                            <div className="clientButtonWrapper d-flex justify-content-center align-items-center">
                                <ul className="nav mobile_320  ClientButtonNavTabs nav-tabs">
                                    <li className="nav-item ClientButtonNavItem">
                                        <Link to="/client-register" className="text-decoration-none">
                                            <button className="nav-link client_tab_button">
                                                Client
                                            </button>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            to="/employee-register"
                                            className="text-decoration-none"
                                        >
                                            <button className="nav-link active employee_tab_button">
                                                Candidate
                                            </button>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="formWrappper">
                                <div className="tab-content">
                                    <Form
                                        className="ant-form ant-form-vertical"
                                        layout="vertical"
                                        onFinish={onFinish}
                                        form={form}
                                    >
                                        <div className="tab-pane fade show active">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="uploadImageWrapper d-flex justify-content-center align-items-center">
                                                            <Form.Item
                                                                name="profilePicture"
                                                                hasFeedback
                                                                rules={[
                                                                    {
                                                                        // required: true,
                                                                        message: "Please enter your profile picture",
                                                                    },
                                                                ]}
                                                            >
                                                                <div>
                                                                    <ImgCrop rotate aspect={500 / 600}>
                                                                        <Upload
                                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                                            // fileList={profilePicture}
                                                                            onChange={onProfileChange}
                                                                            onPreview={onPreview}
                                                                        >
                                                                            {profilePicture?.length < 1 && (
                                                                                <>
                                                                                    <img
                                                                                        style={{
                                                                                            height: "100px",
                                                                                            width: "100px",
                                                                                            marginTop: "10px",
                                                                                        }}
                                                                                        src="assets/frontend/images/registrationFormImages/employeeFormPictures/uploadeImage.png"
                                                                                        alt="Default Image"
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        </Upload>
                                                                    </ImgCrop>
                                                                </div>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <Form.Item
                                                            name="firstName"
                                                            hasFeedback
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("please_enter_your_first_name"),
                                                                },
                                                                {
                                                                    pattern: new RegExp(
                                                                        /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                                                                    ),
                                                                    message: t(
                                                                        "first_name_field_does_not_accept_numbers"
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <div>
                                                                <div className="inputLogoWrapper">
                                                                    <img
                                                                        className="img-fluid"
                                                                        src="assets/frontend/images/registrationFormImages/employeeFormPictures/Icon.png"
                                                                        alt="image"
                                                                    />
                                                                </div>
                                                                <input
                                                                    placeholder={t("enter_first_name")}
                                                                    type="text"
                                                                    className="form-control custom_client_input_for_registration_page"
                                                                />
                                                            </div>
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="email"
                                                            hasFeedback
                                                            rules={[
                                                                {
                                                                    type: "email",
                                                                    message: t(
                                                                        "please_enter_a_valid_email_address"
                                                                    ),
                                                                },
                                                                {
                                                                    required: true,
                                                                    message: t("please_enter_your_email"),
                                                                },
                                                            ]}
                                                        >
                                                            <div>
                                                                <div className="inputLogoWrapper">
                                                                    <img
                                                                        className="img-fluid"
                                                                        src="assets/frontend/images/registrationFormImages/clientFormPictures/Email.png"
                                                                        alt="image"
                                                                    />
                                                                </div>
                                                                <input
                                                                    placeholder={t("enter_email")}
                                                                    type="email"
                                                                    className="form-control custom_client_input_for_registration_page"
                                                                />
                                                            </div>
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="positionId"
                                                            hasFeedback
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("please_select_your_position"),
                                                                },
                                                            ]}
                                                        >
                                                            <div className="dropdownbuttonwrapper">
                                                                <div className="dropdown">
                                                                    <div className="inputLogoWrapper">
                                                                        <img
                                                                            className="img-fluid"
                                                                            src="assets/frontend/images/registrationFormImages/clientFormPictures/selectFrom.png"
                                                                            alt="image"
                                                                        />
                                                                    </div>
                                                                    <select
                                                                        className="form-select custom_select"
                                                                        aria-label="Select country"
                                                                    >
                                                                        <option selected disabled>
                                                                            {t("please_select_position")}
                                                                        </option>
                                                                        {position?.map((item, index) => (
                                                                            <option key={index} value={item?._id}>
                                                                                {item?.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </Form.Item>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <Form.Item
                                                            name="lastName"
                                                            hasFeedback
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("please_enter_your_last_name"),
                                                                },
                                                                {
                                                                    pattern: new RegExp(
                                                                        /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                                                                    ),
                                                                    message: t(
                                                                        "last_name_field_does_not_accept_numbers"
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <div>
                                                                <div className="inputLogoWrapper">
                                                                    <img
                                                                        className="img-fluid"
                                                                        src="assets/frontend/images/registrationFormImages/employeeFormPictures/Icon.png"
                                                                        alt="image"
                                                                    />
                                                                </div>
                                                                <input
                                                                    placeholder={t("enter_last_name")}
                                                                    type="text"
                                                                    className="form-control custom_client_input_for_registration_page"
                                                                />
                                                            </div>
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="phoneNumber"
                                                            hasFeedback
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "",
                                                                },
                                                                {
                                                                    validator: (_, value) =>
                                                                        value && isValidPhoneNumber(value)
                                                                            ? Promise.resolve()
                                                                            : Promise.reject(
                                                                                new Error(
                                                                                    t(
                                                                                        "please_enter_valid_phone_number_for_selected_country"
                                                                                    )
                                                                                )
                                                                            ),
                                                                },
                                                            ]}
                                                        >
                                                            <div>
                                                                <div className="inputLogoWrapper">
                                                                    <img
                                                                        className="img-fluid"
                                                                        src="assets/frontend/images/registrationFormImages/clientFormPictures/phone.png"
                                                                        alt="image"
                                                                    />
                                                                </div>
                                                                <PhoneInput
                                                                    onlyCountries={["ae", "it", "gb"]}
                                                                    placeholder="Enter phone number"
                                                                    isValid
                                                                    inputProps={{
                                                                        name: "phoneNumber",
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="countryName"
                                                            hasFeedback
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("please_select_your_country_name"),
                                                                },
                                                            ]}
                                                        >
                                                            <div className="dropdownbuttonwrapper">
                                                                <div className="dropdown">
                                                                    <div className="inputLogoWrapper">
                                                                        <img
                                                                            className="img-fluid"
                                                                            src="assets/frontend/images/registrationFormImages/clientFormPictures/selectFrom.png"
                                                                            alt="image"
                                                                        />
                                                                    </div>

                                                                    <select
                                                                        className="form-select custom_select"
                                                                        aria-label={t("select_country")}
                                                                        onChange={(e) => {
                                                                            setSelectedCountry(e.target.value);
                                                                        }}
                                                                    >
                                                                        <option selected disabled>
                                                                            {t("please_select_country_name")}
                                                                        </option>
                                                                        {FilteredCountryWiseValidationRules?.map(
                                                                            (item, index) => (
                                                                                <option key={index} value={item?.name}>
                                                                                    {item?.name}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                {/*   <div className="row ">
                        <div className="col-lg-12 please_provide_wrapper text-center">
                          <p className="pleaseProvideText">
                            Please provide Bank Details
                          </p>
                          <img
                            className="img-fluid pleaseProvideImage"
                            src="assets/frontend/images/registrationFormImages/clientFormPictures/rectangle.png"
                            alt="iq"
                          />
                        </div>
                      </div> */}
                                                {/* <div className="row mt-5">
                        <div className="col-lg-6">
                          <Form.Item
                            name="accountNumber"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter account number",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/Vector.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter account number"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>

                          <Form.Item
                            name="cardCvvNumber"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter card cvv number",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/Email.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter card cvv number"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                        </div>
                        <div
                          className="col-lg-6"
                          style={{
                            position: "relative",
                          }}
                        >
                          <Form.Item
                            name="cardHolderName"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter card holder name",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/phone.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter card holder name"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                          <Form.Item
                            name="cardExpDate"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter card exp date",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/phone.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter card exp date"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                        </div>
                      </div> */}

                                                {/* <div className="row ">
                        <div className="col-lg-6">
                          <Form.Item
                            name="bankCountry"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter account country",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/Vector.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter account country"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                        </div>
                        <div
                          className="col-lg-6"
                          style={{
                            position: "relative",
                          }}
                        >
                          <Form.Item
                            name="bankZipCode"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please enter account zip code",
                              },
                            ]}
                          >
                            <div>
                              <div className="inputLogoWrapper">
                                <img
                                  className="img-fluid"
                                  src="assets/frontend/images/registrationFormImages/clientFormPictures/phone.png"
                                  alt="image"
                                />
                              </div>
                              <input
                                placeholder="Enter account zip code"
                                type="text"
                                className="form-control custom_client_input_for_registration_page"
                              />
                            </div>
                          </Form.Item>
                        </div>
                      </div> */}
                                                <Form.Item
                                                    name="summaryPdf"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: `CV is required`,
                                                        },
                                                    ]}
                                                    style={{ textAlign: "center" }}
                                                >
                                                    <div className="row">
                                                        <div className="col-lg-12 d-flex justify-content-center align-items-center">
                                                            {/* Logo Upload Button */}
                                                            <label className="logo-upload-btn">
                                                                <div
                                                                    style={{
                                                                        background: "##A7DFDF",
                                                                        padding: "7px",
                                                                        borderRadius: "10px",
                                                                        borderStyle: "dashed",
                                                                        borderColor: "#58c8c8",
                                                                        borderWidth: "2px",
                                                                        width: "270px",
                                                                        color: "#58c8c8",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    {t("upload_your_curriculum_vitae")} (cv)
                                                                    <AiOutlineCloudUpload
                                                                        style={{
                                                                            marginLeft: "10px",
                                                                            fontSize: "20px",
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div style={{ width: "270px" }}>
                                                                    <Upload
                                                                        listType="picture"
                                                                        defaultFileList={[...summaryPdf]}
                                                                        fileList={summaryPdf}
                                                                        onChange={summaryPdfChange}
                                                                        maxCount={1}
                                                                        accept=".pdf, .PDF, docs, DOCS, .doc, .DOC, .docx"
                                                                    ></Upload>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Form.Item>

                                                {extraFields.length > 0 &&
                                                    extraFields?.map((field, index) => (
                                                        <div
                                                            className="uploadImageWrapper d-flex justify-content-center align-items-center"
                                                            key={index}
                                                        >
                                                            <Form.Item
                                                                name={field.label}
                                                                rules={[
                                                                    {
                                                                        required: field.required,
                                                                        message: `${field.placeholder} is required`,
                                                                    },
                                                                ]}
                                                            >
                                                                <div className="row">
                                                                    <div className="col-lg-12 d-flex justify-content-center align-items-center">
                                                                        {/* Your Upload component for extra fields */}
                                                                        <label className="logo-upload-btn">
                                                                            <div
                                                                                style={{
                                                                                    background: "#A7DFDF",
                                                                                    padding: "7px",
                                                                                    borderRadius: "10px",
                                                                                    borderStyle: "dashed",
                                                                                    borderColor: "#58c8c8",
                                                                                    borderWidth: "2px",
                                                                                    width: "270px",
                                                                                    color: "#58c8c8",
                                                                                    textAlign: "center",
                                                                                }}
                                                                            >
                                                                                {field.label}
                                                                                <AiOutlineCloudUpload
                                                                                    style={{
                                                                                        marginLeft: "10px",
                                                                                        fontSize: "20px",
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div style={{ width: "270px" }}>
                                                                                <Upload
                                                                                    listType="picture"
                                                                                    maxCount={1}
                                                                                    accept=".pdf, .PDF, .doc, .DOC, .docx"
                                                                                    fileList={fileLists[field.label] || []}
                                                                                    onChange={(info) =>
                                                                                        handleFileChange(info, field.label)
                                                                                    }
                                                                                ></Upload>

                                                                                {documentUrl[field.label] && (
                                                                                    <>{documentUrl[field.label]}</>
                                                                                )}
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Form.Item>
                                                        </div>
                                                    ))}

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        {/* Register Button */}
                                                        <div className="registerButton text-center">
                                                            <Form.Item>
                                                                <button
                                                                    disabled={loading}
                                                                    className="btn employee_register_button"
                                                                    type="submit"
                                                                    style={{
                                                                        width: "270px",
                                                                    }}
                                                                >
                                                                    {!loading && t("register")}
                                                                    {loading && (
                                                                        <span
                                                                            className="indicator-progress"
                                                                            style={{ display: "block" }}
                                                                        >
                                                                            {t("please_wait...")}
                                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            </Form.Item>
                                                        </div>
                                                        {/* Register Button End */}

                                                        <div className="accountandregisterwrapper text-center">
                                                            <span className="donthaveaccounttext">
                                                                {t("already_have_an_account")}
                                                            </span>
                                                            <Link to="/login" style={{ color: "#58c8c8" }}>
                                                                {t("login")}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            {/* Have an account Section start*/}
                            {/* <div className="accountandregisterwrapper text-center">
              <span className="donthaveaccounttext">
                Already have an account?
              </span>
              <Link to="/login" style={{ color: "#ceb26e" }}>
                Login
              </Link>
            </div> */}
                            {/* Have an account Section end*/}
                        </div>
                        {/* <div class="eclipse_wrapper2">
          <img class="img-fluid"
              src="images/registrationFormImages/clientFormPictures/../clientAndEmployee/Ellipse 69.png" alt="">
      </div> */}
                    </div>
                    {/*Right Part End*/}
                </div>
            </section>

        </>
    );
}

export default EmployeeRegister;
