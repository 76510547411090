import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Popconfirm,
  Row,
  Select,
  notification,
  theme,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { token } from "../../../utils/authentication";
import { getUserId } from "../../../utils/commonFunctions";
import Loader from "../../loadar/Loader";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

const { Header, Content, Footer, Sider } = Layout;

const GetMsgType = ({ message, myRole }) => {
  if (!message.myMessage) {
    return (
      <li className="clearfix">
        <div className="message-data align-left">
          <span className="message-data-time">{message.time}</span>{" "}
        </div>
        <div className="message my-message float-left">{message.message}</div>
      </li>
    );
  } else {
    return (
      <li className="clearfix">
        <div className="message-data align-right">
          <span className="message-data-time">{message.time}</span>{" "}
        </div>
        <div className="message other-message float-right">
          {message.message}
        </div>
      </li>
    );
  }
};

const SupportList = () => {
  const { t } = useTranslation();
  const [createMessageModal, setCreateMessageModal] = useState(false);
  const [form] = Form.useForm();
  const chatContainerRef = useRef(null);
  const [chatList, setChatList] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [socketData, setSocketData] = useState({});
  const [sendDisabled, setSendDisabled] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [loadMoreDisabled, setLoadMoreDisabled] = useState(true);
  const [type, setType] = useState("client"); // [client, employee
  const { Option } = Select;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const sendMessage = () => {
    if (!newMessage) {
      notification.error({
        message: "Message cannot be empty",
      });
      return;
    }
    setSendDisabled(true);
    const payload = {
      senderId: getUserId(),
      conversationId: activeChat._id,
      dateTime: new Date(),
      text: newMessage,
    };
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
      withCredentials: false,
      transports: ["websocket", "polling", "flashsocket"],
    });
    socket.emit("message", payload);
    setNewMessage("");
    setSendDisabled(false);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/messages/create`, payload, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        if (res.data.next) {
          setLoadMoreDisabled(false);
        }
        getConversations();
      })
      .catch((err) => {});
  };

  const moveToTop = () => {
    chatContainerRef.current.scrollTop = 0;
  };

  const moveToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
      withCredentials: false,
      transports: ["websocket", "polling", "flashsocket"],
    });
    socket.on("connect", () => {
      console.log("Socket connected");
    });
    socket.on("message", (data) => {
      setSocketData(data);
    });
    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
    return () => {
      console.log("Socket disconnecting");
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("Socket data", socketData);
    if (socketData && socketData?.conversationId === activeChat?._id) {
      setMessages([
        ...messages,
        {
          id: socketData._id,
          myMessage: socketData.senderId === getUserId(),
          senderName: socketData.senderDetails?.name,
          message: socketData.text,
          time: moment(socketData.dateTime).format("DD MMM YY @ h:mm A"),
        },
      ]);
      moveToBottom();
    }
    getConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  // conversation list
  const getConversations = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/conversations`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        const sortByUpdatedAt = res.data.conversations.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setChatList(sortByUpdatedAt);
        console.log(res?.data?.conversations);
      });
  };
  useEffect(() => {
    getConversations();
  }, []);

  const LoadMore = () => {
    setLoadMoreDisabled(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/messages?conversationId=${
          activeChat?._id
        }&limit=${limit}&page=${skip + 1}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.next) {
          setLoadMoreDisabled(false);
        }
        setSkip(skip + 1);
        const descendingMessages = res.data.messages.reverse();
        const modifiedMessages = descendingMessages.map((message) => {
          return {
            id: message._id,
            myMessage:
              message.senderDetails?.role === "ADMIN" ||
              message.senderDetails?.role === "SUPER_ADMIN"
                ? true
                : false,
            senderName: message.senderDetails?.name,
            message: message.text,
            time: moment(message.dateTime).format("DD MMM YY @ h:mm A"),
          };
        });
        setMessages([...modifiedMessages, ...messages]);
        moveToTop();
      });
  };

  // get messages by conversation id
  useEffect(() => {
    if (activeChat?._id) {
      setLoading(true);
      setMessages([]);
      setLoadMoreDisabled(true);
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/messages?conversationId=${activeChat?._id}&limit=${limit}&page=${skip}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.next) {
            setLoadMoreDisabled(false);
          }
          const descendingMessages = res.data.messages.reverse();
          const modifiedMessages = descendingMessages.map((message) => {
            return {
              id: message._id,
              myMessage:
                message.senderDetails?.role === "ADMIN" ||
                message.senderDetails?.role === "SUPER_ADMIN"
                  ? true
                  : false,
              senderName: message.senderDetails?.name,
              message: message.text,
              time: moment(message.dateTime).format("DD MMM YY @ h:mm A"),
            };
          });
          setMessages(modifiedMessages);
          moveToBottom();
        });
    }
  }, [activeChat]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/users?requestType=CLIENT`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        setClientList(res.data.users);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/users?requestType=EMPLOYEE`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        setEmployeeList(res.data.users);
      });
  }, []);

  const handleCreateMessage = async () => {
    const values = form.getFieldsValue();
    if (values.type === "client" && !values.selectedClient) {
      notification.error({
        message: "Please fill all fields",
      });
      return;
    }
    if (values.type === "employee" && !values.selectedEmployee) {
      notification.error({
        message: "Please fill all fields",
      });
      return;
    }
    const payload = {
      senderId:
        values.type === "client"
          ? values.selectedClient
          : values.selectedEmployee,
      isAdmin: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        getConversations();
        setCreateMessageModal(false);
        form.resetFields();
      });
  };

  return (
    <Layout className="chat">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        style={{
          overflow: "auto",
          height: "calc(100vh - 64px)", // "100vh",
          minWidth: "300px !important",
          left: 0,
          background: "#FFFFFF",
        }}
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div
          style={{
            padding: "10px",
            background: "#58C8C8", // '#E5F0F0
            cursor: "pointer",
            textAlign: "center",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={() => {
            setCreateMessageModal(true);
          }}
        >
          Create
        </div>
        {chatList.map((item) => {
          return (
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  color: "black",
                  padding: "10px",
                  background:
                    activeChat?._id === item._id ? "#E5F0F0" : "white",
                  cursor: "pointer",
                }}
                className="d-flex align-items-center"
                onClick={() => {
                  setMessages([]);
                  setSkip(1);
                  setLimit(10);
                  setActiveChat(item);
                }}
              >
                <img
                  style={{
                    width: 50,
                    height: 50,
                    objectFit: "cover",
                    borderRadius: "50%",
                    margin: "0px",
                  }}
                  src={
                    item?.members[0]?.profilePicture
                      ? process.env.REACT_APP_ASSETs_BASE_URL +
                        "/" +
                        item?.members[0]?.profilePicture
                      : "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"
                  }
                  className="Dashboard2-card-img-top"
                  alt="profilePicture"
                />
                <div>
                  <div style={{ fontSize: "16px" }}>
                    {item?.members[0].name
                      ? item?.members[0].name
                      : item?.members[0].restaurantName}{" "}
                    <span
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      ({item?.members[0].role})
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        fontWeight:
                          item?.latestMessage?.read === false
                            ? "bold"
                            : "normal",
                      }}
                    >
                      {item?.latestMessage?.text
                        ? item?.latestMessage?.text
                        : "start conversation"}
                    </div>
                    {/* Delete button  */}
                  </div>
                </div>
              </div>
              {activeChat?._id === item._id ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "white",
                    background: "#E5F0F0",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <Popconfirm
                    title="Are you sure to delete this About Message?"
                    placement="rightTop"
                    onConfirm={() => {
                      axios
                        .delete(
                          `${process.env.REACT_APP_API_BASE_URL}/conversations/${item._id}`,
                          {
                            headers: {
                              Authorization: `Bearer ${token()}`,
                            },
                          }
                        )
                        .then((res) => {
                          getConversations();
                          setActiveChat(null);
                        });
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                  <Button
                    style={{
                      background: "red",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      Delete
                    </span>
                  </Button>
                  </Popconfirm>
                </div>
              ) : null}
            </div>
          );
        })}
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          {activeChat ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 20px",
              }}
            >
              <img
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "cover",
                  borderRadius: "50%",
                  margin: "0px",
                }}
                src={
                  activeChat?.members[0]?.profilePicture
                    ? process.env.REACT_APP_ASSETs_BASE_URL +
                      "/" +
                      activeChat?.members[0]?.profilePicture
                    : "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"
                }
                className="Dashboard2-card-img-top"
                alt="profilePicture"
              />
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  {activeChat?.members[0]?.name
                    ? activeChat?.members[0]?.name
                    : activeChat?.members[0]?.restaurantName}
                </div>
                <div>
                  <Button
                    style={{
                      background: "#58C8C8",
                      color: "white",
                    }}
                    disabled={loadMoreDisabled}
                    onClick={LoadMore}
                  >
                    Load More
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          <div></div>
        </Header>
        <Content style={{ margin: "2px 1px 0" }}>
          <div
            style={{
              minHeight: "calc(100vh - 280px)",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {loading && <Loader />}
            {/* {loading && !messages[0]?.id && <div>No messages found</div>} */}
            {messages.length > 0 && messages[0]?.id ? (
              <ul
                className="chat-history"
                ref={chatContainerRef}
                style={
                  {
                    // padding: "0px 5px 0px 5px",
                  }
                }
              >
                {messages.map((message, index) => {
                  return (
                    <GetMsgType
                      key={message?.id}
                      message={message}
                      myRole={"ADMIN"}
                    />
                  );
                })}
              </ul>
            ) : null}
          </div>
        </Content>
        <div
          className="chat-message clearfix"
          style={{
            padding: "5px 15px 0px 15px",
          }}
        >
          <textarea
            name="message-to-send"
            id="message-to-send"
            placeholder="Type your message"
            rows="3"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          ></textarea>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              loading={sendDisabled}
              onClick={sendMessage}
              style={{
                color: "#6DACE4",
                display: "flex",
                justifyContent: "end",
              }}
            >
              Send
            </Button>
          </div>
        </div>
        <Footer style={{ textAlign: "center" }}>
          Ant Design ©{new Date().getFullYear()} Created by Ant UED
        </Footer>
      </Layout>

      <Modal
        title="Create Message"
        open={createMessageModal}
        width={700}
        onCancel={() => {
          setCreateMessageModal(false);
        }}
        footer={
          <>
            <button
              className="btn btn-danger"
              style={{
                marginRight: "10px",
              }}
              onClick={() => {
                setCreateMessageModal(false);
              }}
            >
              {t("cancel")}
            </button>
            <button className="btn btn-primary" onClick={handleCreateMessage}>
              {t("create")}
            </button>
          </>
        }
      >
        <Form layout="vertical" form={form}>
          <Row gutter={[10, 8]} className="mt-5 mb-4">
            <Col sm={24} md={12} lg={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Please select a Type",
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch={true}
                  placeholder={"Select Type"}
                  defaultValue={type}
                  onChange={(value) => {
                    setType(value);
                    form.setFieldsValue({ type: value });
                    if (value === "client") {
                      form.setFieldsValue({ selectedEmployee: undefined });
                    } else {
                      form.setFieldsValue({ selectedClient: undefined });
                    }
                  }}
                >
                  <Option value="client">Client</Option>
                  <Option value="employee">Employee</Option>
                </Select>
              </Form.Item>
            </Col>

            {type === "client" ? (
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  name="selectedClient"
                  label="Select Client"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Client",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    allowClear
                    showSearch={true}
                    placeholder={"Select Client"}
                    onChange={(value) => {
                      console.log("Selected Client:", value);
                    }}
                  >
                    {clientList.map((client) => (
                      <Option key={client._id} value={client._id}>
                        {client.restaurantName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  name="selectedEmployee"
                  label="Select Employee"
                  rules={[
                    {
                      required: true,
                      message: "Please select an Employee",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    allowClear
                    showSearch={true}
                    placeholder={"Select Employee"}
                    onChange={(value) => {
                      console.log("Selected Employee:", value);
                    }}
                  >
                    {employeeList.map((employee) => (
                      <Option key={employee._id} value={employee._id}>
                        {employee.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </Layout>
  );
};

export default SupportList;
