import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./academy.css";

import axios from "axios";

function Courses() {
  const [isHovered1, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovere4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);

  const [category, setCategory] = useState([]);
  const [hoverId, setHoverId] = useState();

  const handleMouseEnter1 = () => {
    setIsHovered(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };
  const handleMouseEnter4 = () => {
    setIsHovered4(true);
  };

  const handleMouseLeave4 = () => {
    setIsHovered4(false);
  };
  const handleMouseEnter5 = () => {
    setIsHovered5(true);
  };

  const handleMouseLeave5 = () => {
    setIsHovered5(false);
  };

  const cardStyle1 = {
    border: "0.74px solid #58c8c8",
    borderRadius: "15px",
    transition: "border-color 0.3s ease",
    borderColor: isHovered1 ? "#58c8c8" : "#D2D2D2",
    background: isHovered1 ? "#fff" : "#FAFAFA",
  };
  const hoverStyle = {
    border: "0.74px solid #58c8c8",
    borderRadius: "15px",
    transition: "border-color 0.3s ease",
    borderColor: "#58c8c8",
    background: "#fff",
  };
  const normalStyle = {
    border: "0.74px solid #58c8c8",
    borderRadius: "15px",
    transition: "border-color 0.3s ease",
    borderColor: "#D2D2D2",
    background: "#FAFAFA",
  };
  const cardStyle3 = {
    border: "0.74px solid #58c8c8",
    borderRadius: "15px",
    transition: "border-color 0.3s ease",
    borderColor: isHovered3 ? "#58c8c8" : "#D2D2D2",
    background: isHovered3 ? "#fff" : "#FAFAFA",
  };
  const cardStyle4 = {
    border: "0.74px solid #58c8c8",
    borderRadius: "15px",
    transition: "border-color 0.3s ease",
    borderColor: isHovere4 ? "#58c8c8" : "#D2D2D2",
    background: isHovere4 ? "#fff" : "#FAFAFA",
  };
  const cardStyle5 = {
    border: "0.74px solid #58c8c8",
    borderRadius: "15px",
    transition: "border-color 0.3s ease",
    borderColor: isHovered5 ? "#58c8c8" : "#D2D2D2",
    background: isHovered5 ? "#fff" : "#FAFAFA",
  };

  const getCourseCategory = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/course-category/list-for-dropdown`
      )
      .then((res) => {
        if (res.data?.statusCode == 200) {
          console.log(res.data?.courseCategorys);
          setCategory(res.data?.courseCategorys);
        }
      });
  };

  useEffect(() => {
    getCourseCategory();
  }, []);

  return (
    <section className="slider bg-white">
      <div className="container">
        <div className="row bg-white">
          <div className="col-lg-12 mt-1 mb-0">
            <div
            // id="carouselExampleIndicators"
            // className="carousel slide"
            // data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className=" custom Gutterspace d-flex justify-content-center row row-cols-lg-6 row-cols-md-4 my-5 ">
                    {/* <Link to='/school' style={{ textDecoration: 'none' }}>
                      
                      <div
                        className='px-5 ms-3 mt-3 d-flex justify-content-center'
                        style={cardStyle1}
                        onMouseEnter={handleMouseEnter1}
                        onMouseLeave={handleMouseLeave1}
                      >
                        <div>
                          <img src={t1} alt="the" className='mt-3' height={60} width={70} />
                          <p style={{ color: '#252525', fontWeight: 600, fontSize: '11px', font: 'Montserrat' }} className='mt-1'>Plagit Academy</p>
                        </div>
                      </div>
                    </Link> */}

                    {category &&
                      category.length > 0 &&
                      category
                        ?.sort((a, b) => a.sortOrder - b.sortOrder)
                        .map((item, i) => (
                          <Link
                            to={
                              item?.slug == "school"
                                ? `/school`
                                : `/course-category/${item._id}`
                            }
                            style={{ textDecoration: "none" }}
                            key={i}
                          >
                            <div
                              style={
                                hoverId == item._id ? hoverStyle : normalStyle
                              }
                              onMouseOver={() => setHoverId(item._id)}
                              onMouseEnter={handleMouseEnter2}
                              onMouseLeave={handleMouseLeave2}
                              className="mt-3  d-flex justify-content-center"
                            >
                              <div>
                                <div className="d-flex text-center justify-content-center mx-auto">
                                  <img
                                    src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${item.logo}`}
                                    alt={item.name}
                                    className="mt-3"
                                    height={60}
                                    width={70}
                                  />
                                </div>
                                <p
                                  style={{
                                    color: "#252525",
                                    fontWeight: 600,
                                    fontSize: "10px",
                                    font: "Montserrat",
                                  }}
                                  className="mt-1 text-center"
                                >
                                  {item.name}
                                </p>
                              </div>
                            </div>
                          </Link>
                        ))}
                  </div>
                </div>

                {/*<div className="carousel-item">
                  <div className=" custom Gutterspace d-flex justify-content-center row row-cols-lg-6 row-cols-md-4 my-5 ">
                    <Link to="/school" style={{ textDecoration: "none" }}>
                      <div
                        className="px-5 ms-3 mt-3 d-flex justify-content-center"
                        style={cardStyle1}
                        onMouseEnter={handleMouseEnter1}
                        onMouseLeave={handleMouseLeave1}
                      >
                        <div>
                          <img
                            src={t1}
                            alt="the"
                            className="mt-3"
                            height={60}
                            width={70}
                          />
                          <p
                            style={{
                              color: "#252525",
                              fontWeight: 600,
                              fontSize: "11px",
                              font: "Montserrat",
                            }}
                            className="mt-1"
                          >
                            Plagit Academy
                          </p>
                        </div>
                      </div>
                    </Link>

                    <Link to="/expensive" style={{ textDecoration: "none" }}>
                      <div
                        style={cardStyle2}
                        onMouseEnter={handleMouseEnter2}
                        onMouseLeave={handleMouseLeave2}
                        className="  mt-3  d-flex justify-content-center"
                      >
                        <div>
                          <img
                            src={t2}
                            alt="the"
                            className="mt-3 ms-4"
                            height={60}
                            width={70}
                          />
                          <p
                            style={{
                              color: "#252525",
                              fontWeight: 600,
                              fontSize: "10px",
                              font: "Montserrat",
                            }}
                            className="mt-1 "
                          >
                            Expressive & Efficient
                          </p>
                        </div>
                      </div>
                    </Link>

                    <Link to="/bar" style={{ textDecoration: "none" }}>
                      <div
                        style={cardStyle3}
                        onMouseEnter={handleMouseEnter3}
                        onMouseLeave={handleMouseLeave3}
                        className="  mt-3  d-flex justify-content-center"
                      >
                        <div>
                          <img
                            src={t4}
                            alt="the"
                            className="mt-3"
                            height={60}
                            width={70}
                            style={{ marginLeft: "28%" }}
                          />
                          <p
                            style={{
                              color: "#252525",
                              fontWeight: 600,
                              fontSize: "11px",
                              font: "Montserrat",
                            }}
                            className="ms-4 mt-1"
                          >
                            Bartending Courses
                          </p>
                        </div>
                      </div>
                    </Link>

                    <Link to="/waiter" style={{ textDecoration: "none" }}>
                      <div
                        style={cardStyle4}
                        onMouseEnter={handleMouseEnter4}
                        onMouseLeave={handleMouseLeave4}
                        className="  mt-3  d-flex justify-content-center"
                      >
                        <div>
                          <img
                            src={t3}
                            alt="the"
                            className="mt-3 ms-2"
                            height={60}
                            width={70}
                          />
                          <p
                            style={{
                              color: "#252525",
                              fontWeight: 600,
                              fontSize: "11px",
                              font: "Montserrat",
                            }}
                            className="mt-1"
                          >
                            Waiter Courses
                          </p>
                        </div>
                      </div>
                    </Link>

                    <Link to="/chief" style={{ textDecoration: "none" }}>
                      <div
                        style={cardStyle5}
                        onMouseEnter={handleMouseEnter5}
                        onMouseLeave={handleMouseLeave5}
                        className="  mt-3  d-flex justify-content-center"
                      >
                        <div>
                          <img
                            src={t5}
                            alt="the"
                            className="mt-3 ms-3"
                            height={60}
                            width={70}
                          />
                          <p
                            style={{
                              color: "#252525",
                              fontWeight: 600,
                              fontSize: "11px",
                              font: "Montserrat",
                            }}
                            className="mt-1 ms-2"
                          >
                            {" "}
                            Chef Courses
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                          </div>*/}
              </div>
            </div>
            {/* <div className="carousel-indicators customSliderIndicator">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={0}
                className="active rounded-left"
                aria-current="true"
                aria-label="Slide 1"
                
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={1}
                aria-label="Slide 2"
              />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Courses;
