import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.js";
import "react-day-picker/dist/style.css";
import "react-phone-input-2/lib/style.css";
import "./custom.scss";
import { HelmetProvider } from "react-helmet-async";
import "./assets/css/_main.css";
import ContextProvider from "./hooks/ContextApi";
import { QueryClient, QueryClientProvider } from "react-query";
import ScrollToTop from "./common/ScrollToTop";
const helmetContext = {};
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <HelmetProvider context={helmetContext}>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <ScrollToTop />
          <App />
        </ContextProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </BrowserRouter>
);
