import React from "react";
import chef from "./chef.jpg";
import moment from "moment";
import { Link } from "react-router-dom";

export default function JobCard({ job, jwtDecode }) {
  return (
    <>
      <div style={styles.card}>
        <div style={styles.cardHeader}>
          <img
            src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${job?.positionId?.logo}`}
            alt={job?.positionId?.name}
            style={styles.icon}
          />
          <h3 style={styles.cardTitle}>{job.positionId?.name}</h3>
        </div>
        <div style={styles.cardBody}>
          <p style={styles.cardText}>
            <span role="img" aria-label="rate" style={styles.emoji}>
              💰
            </span>
            <span style={{ color: "#7B7B7B" }}>Rate:</span> {job.minRatePerHour}
            /h - {job.maxRatePerHour}/H
            <span role="img" aria-label="experience" style={styles.emoji}>
              💼
            </span>
            <span style={{ color: "#7B7B7B" }}>Experience:</span>{" "}
            {job.minExperience} - {job?.maxExperience} Yr.
          </p>
          <p style={styles.cardText}>
            <span role="img" aria-label="date" style={styles.emoji}>
              🗓️
            </span>
            <span style={{ color: "#7B7B7B" }}>Date:</span>{" "}
            {moment(job.publishedDate).format("DD-MM-YYYY")} -{" "}
            {moment(job.endDate).format("DD-MM-YYYY")}
          </p>
        </div>
        <Link
          style={styles.button}
          to={
            jwtDecode?.role == "CLIENT"
              ? `/edit-job/${job?._id}`
              : `/job-details/${job?._id}`
          }
        >
          View Job Details
        </Link>
      </div>
    </>
  );
}

const styles = {
  container: {
    padding: "20px",
    borderRadius: "15px",
    border: "1px solid #E0E0E0",
    // maxWidth: "1200px", // Adjusted for wider containers
    margin: "auto",
    backgroundColor: "white",
    marginTop: "20px",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "15px",
    color: "#333",
  },
  jobGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gap: "20px",
  },
  card: {
    borderRadius: "15px",
    border: "1px solid #E0E0E0", // Added border to match the original design
    padding: "20px",
    backgroundColor: "#fff",
    position: "relative", // For positioning button inside card
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  icon: {
    width: "40px", // Adjusted size for better visual
    height: "40px",
    marginRight: "10px",
  },
  cardTitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#333",
  },
  cardBody: {
    fontSize: "14px",
    color: "#555",
    marginBottom: "40px",
  },
  cardText: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    color: "#333", // Adjusted text color
    fontSize: "15px",
  },
  emoji: {
    marginRight: "8px",
    marginLeft: "7px",
  },
  button: {
    background: "linear-gradient(to right, #58C8C8, #3F8E9B)",
    color: "#fff",
    border: "none",
    borderRadius: "15px 0px 15px 0px", // Adjusted border radius for custom corners
    padding: "5px 15px", // Adjusted padding for better spacing
    cursor: "pointer",
    textAlign: "center",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    textDecoration: "none",
  },
};
