import React from "react";
import logo from './Logo.png';

export default function Loader() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
        background: "linear-gradient(90deg, #ffffff, #58c8c8, #ffffff)",
        backgroundSize: "200% 100%",
        animation: "slide 2s linear infinite",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={logo} alt="logo" style={{ zIndex: 10000 }} />
      <style>{`
        @keyframes slide {
          0% {
            background-position: 200% 0;
          }
          100% {
            background-position: -200% 0;
          }
        }
      `}</style>
    </div>
  );
}
