import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { token } from "../../../utils/authentication";

function Position() {
  const [positionfromApi, setPositionfromApi] = useState([]);
  const fileNames = [
    "Group 117169.png",
    "bartender-01.png",
    "Group 117177.png",
    "barista-01.png",
    "Group 117176.png",
    "Group 117174.png",
    "Bar back-01.png",
    "Receptionist-01.png",
    "Group 117170.png",
    "Group 117180.png",
    "Group 117178.png",
    "Sous Chef-01.png",
    "Pastry chef-01.png",
    "Group 117175.png",
    "room made-01.png",
    "Group 117171.png",
    "Group 117179.png",
    "toilet assistant-01.png",
    "assistant manager-01.png",
    "security-01.png",
  ];

  useEffect(() => {
    const fetchPositionData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/positions?skipLimit=YES`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          //  console.log(`data`, data.positions);
          setPositionfromApi(data.positions);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };

    fetchPositionData();
  }, []);
  // console.log(`positionfromApi`, positionfromApi);
  // const basePath = "assets/frontend/images/indexImages/card/";

  const renderCard = (position, index) => (
    <div
      className="col-lg-2 mb-3"
      key={index}
      style={{
        maxWidth: "250px",
        maxHeight: "200px",
      }}
    >
      <Link
        to="/login"
        style={{
          textDecoration: "none",
          color: "black",
        }}
      >
        <div
          className="text-center shadow p-2 m-2 bg-white "
          style={{
            borderRadius: "10px",
          }}
        >
          <img
            className="card-img-top"
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
            }}
            src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${position.logo}`}
            alt={position.name}
          />
          <p className=" mt-2">{position.name}</p>
        </div>
      </Link>
    </div>
  );

  return (
    <section
      className="card-section"
      style={{
        backgroundColor: "#58c8c87f",
        marginBottom: "20px",
      }}
    >
      <div className="container">
        <div
          className="row d-flex justify-content-center"
          style={{
            marginBottom: "50px",
          }}
        >
          {positionfromApi.map((position, index) =>
            renderCard(position, index)
          )}
        </div>
      </div>
      <style jsx>{`
        .cards {
          margin: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          transition: box-shadow 0.3s ease;
        }
        .card-img-top {
          width: 100%;
          height: auto;
        }
      `}</style>
    </section>
  );
}

export default Position;
