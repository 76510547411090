import React, { useState } from "react";
import { Form, Select, Input } from "antd";
import { addHandler } from "../../../api/addMHEmployee";
import { responseNotification } from "../../../utils/notifcation";
import { useNavigate } from "react-router-dom";
import { staticMenuPermission } from "../../../utils/static/menuPermission";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function AddMHEmployee() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const active = values?.active === "YES" ? true : false;

    const addMhEmployeeReceivedFields = {
      name: values?.name,
      email: values?.email,
      phoneNumber: values?.phoneNumber,
      password: values?.password,
      role: values?.role,
      countryName: values?.countryName,
      active: active,
      permissions: values?.permission,
    };

    if (addMhEmployeeReceivedFields) {
      setLoading(true);
      addHandler(addMhEmployeeReceivedFields)
        .then((res) => res.json())
        .then((res) => {
          if (res?.statusCode === 201) {
            setError(undefined);
            setLoading(false);
            responseNotification(
              "Plagit employee created successfully!",
              "success"
            );

            navigate("/admin/mh-employee-list");
            // form.resetFields();
          } else if (res?.statusCode === 400) {
            setError(res?.errors?.[0].msg);
            setLoading(false);
          } else if (res?.statusCode === 500) {
            setError(res?.message);
            setLoading(false);
          }
        });
    }
  };

  return (
    <div className="container-fluid px-4">
      <div className="row mt-4">
        <div className="d-flex justify-content-between">
          <h3 className="mb-4 title">{t("add_mh_employee")}</h3>
        </div>
      </div>

      <div className="card">
        <div className="card-header">{t("add_mh_employee")}</div>
        <div className="card-body">
          <div className="m-2">
            <Form
              className="ant-form ant-form-vertical"
              layout="vertical"
              onFinish={onFinish}
              form={form}
            >
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <Form.Item
                      label={t("name")}
                      name="name"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_name"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_name")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("email")}
                      name="email"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_email"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_email")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("phone_number")}
                      name="phoneNumber"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_phone_number"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_phone_number")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("password")}
                      name="password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_password"),
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={t("enter_password")}
                        className="ant-input ant-input-lg"
                        visibilityToggle={{
                          visible: passwordVisible,
                          onVisibleChange: setPasswordVisible,
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("role_name")}
                      name="role"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("role_name_is_required"),
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_role_name")}
                        optionFilterProp="children"
                      >
                        <Option value="SUPER_ADMIN">SUPER ADMIN</Option>
                        <Option value="ADMIN">ADMIN</Option>
                        <Option value="HR">HR</Option>
                        <Option value="MARKETING">MARKETING</Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("country_name")}
                      name="countryName"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("country_name_is_required"),
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_country_name")}
                        optionFilterProp="children"
                      >
                        <Option value="United Kingdom">United Kingdom</Option>
                        <Option value="United Arab Emirates">
                          United Arab Emirates
                        </Option>
                        <Option value="OTHERS">OTHERS</Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("active")}
                      name="active"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("status_is_required"),
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_active_yes_or_no")}
                        optionFilterProp="children"
                      >
                        <Option value="YES">YES</Option>
                        <Option value="NO">NO</Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-12">
                    <Form.Item
                      label={t("menu_permission_list")}
                      name="permission"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("menu_permission_is_required"),
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_permission")}
                        optionFilterProp="children"
                        mode="multiple"
                        allowClear
                      >
                        {staticMenuPermission?.map((item, index) => (
                          <Option key={index} value={item?.name}>
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  {getError ? (
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="error-message">
                          <p className="error-text-color text-danger">
                            {getError}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : undefined}

                  <div className="col-md-6">
                    <Form.Item>
                      <button
                        disabled={loading}
                        className="btn"
                        style={{ background: "#58c8c8", color: "white" }}
                        type="submit"
                      >
                        {!loading && t("save")}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            {t("please_wait...")}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMHEmployee;
