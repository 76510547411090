import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const PolicyPage = ({ title, data, update }) => {
  const renderContent = (item) => {
    if (item.listItems) {
      return (
        <>
          {item.text && <p>{item.text}</p>}{" "}
          <ul className="flex flex-col">
            {item.listItems.map((listItem, index) => (
              <li key={index} className="my-2">
                {listItem}
              </li>
            ))}
          </ul>
        </>
      );
    }
    return (
      <>
        {Object.entries(item)
          .filter(([key]) => key.startsWith("text") || key === "link")
          .map(([key, value], index) => (
            <React.Fragment key={index}>
              {key === "link" ? (
                <a href={`mailto:${value}`} className="text-primary d-block">
                  {value}
                </a>
              ) : (
                <p className="my-2">{value}</p>
              )}
            </React.Fragment>
          ))}
      </>
    );
  };

  return (
    <section className="p-0">
      <Container>
        <Row>
          <Col xs="12" className="my-5 text-center">
            <h4 className=" text-center ">
              {title}
              {update && <p>{update}</p>}
            </h4>
          </Col>
          {data.map((item, index) => (
            <Col xs="12" key={index} className="my-2  py-2">
              <h4>{item.heading}</h4>
              {renderContent(item)}
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PolicyPage;
