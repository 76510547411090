import PolicyPage from "./Policy";

const contentArray = [
  {
    id: 1,
    heading: "1. Cancellation Policy",
    text: "a.  Time Frame",
    listItems: [
      " You can cancel your order within 24 hours of purchase. Please note that once the 24-hour window has passed, your order will be processed for shipment and cannot be cancelled",
    ],
  },
  {
    id: 2,
    heading: " ",
    text: "b.  Process",
    listItems: [
      " Log in to your account.",
      "Go to the 'Orders' section.",
      "Select the order you wish to cancel.",
      "Click on 'Cancel Order' button.",
      " Confirm your cancellation",
    ],
  },
  {
    id: 3,
    heading: " ",
    text: "c. Refund",
    listItems: [
      " After cancellation, a full refund will be issued to your original method of payment within 7-10 business days.",
    ],
  },
  {
    id: 4,
    heading: " ",
    text: "d. Exceptions",
    listItems: [
      " Certain products may not be eligible for cancellation due to their nature. This includes perishable goods, custom-made items, or services that have commenced execution with your agreement before the end of the 24-hour cancellation period.",
    ],
  },
  {
    id: 4,
    heading: "2. Replacement Policy",
    text: "a.  Conditions for Replacement:",
    listItems: [
      " Incorrect Product: If the product delivered is not what was ordered.",
      "Damaged or Defective Product: If the product arrives damaged or has a manufacturing defect.",
      "Expiry Date: If the product received is expired or near its expiration date.",
    ],
  },
  {
    id: 2,
    heading: " ",
    text: "b.  Time Frame",
    listItems: [
      " Replacement requests must be made within 7 days from the date of delivery",
    ],
  },
  {
    id: 3,
    heading: " ",
    text: "c. Process:",
    listItems: [
      " Contact our Customer Service to initiate a replacement request.",
      "Provide a detailed description of the issue and attach photographic evidence if applicable.",
      "Once the replacement request is validated, the incorrect/damaged product must be returned in its original packaging.",
      "Replacement will be processed only after the received item has been inspected and deemed eligible.",
    ],
  },
  {
    id: 4,
    heading: " ",
    text: "d.  Non-replaceable Products:",
    listItems: [
      " Some products may be non-replaceable due to their nature. This includes products that are consumable, personalized, or part of a promotion",
    ],
  },
  {
    id: 4,
    heading: " 3. General Terms",
    text: "a. Shipping Cost",
    listItems: [
      " For both cancelled orders and replacements, shipping costs will be borne by us if the error is ours (incorrect or defective item). In all other cases, the customer may be responsible for shipping costs",
    ],
  },
  {
    id: 4,
    heading: "",
    text: "b.Policy Amendments",
    listItems: [
      "  We reserve the right to modify any provisions of the cancellation and replacement policy without any prior notification. Any changes will be reflected in the terms of the policy here.",
    ],
  },
  {
    id: 4,
    heading: "",
    text: "c. Applicability",
    listItems: [
      "  This policy applies to all products purchased via our website, subject to specific product/service exceptions noted within the policy.",
    ],
  },
  {
    id: 4,
    heading: "",
    text: "    This cancellation and replacement policy ensures that our customers receive products in their best condition and as per their expectations. If you have any questions or require further assistance, please contact our customer service team.",
  },
];
const CancelPolicy = () => {
  return (
    <PolicyPage title="Cancellation & Replacement Policy" data={contentArray} />
  );
};

export default CancelPolicy;
