import React, { useState } from "react";
import "./social_feed_style.scss";
import CommentComponents from "./CommentComponents";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import { jwtTokenDecode } from "../../../../utils/jwtDecode";
import axios from "axios";
import { token } from "../../../../utils/authentication";
import { Button, Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";
import toast from "react-hot-toast";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

export default function PostFeedCard({
    userImage,
    userName,
    postTime,
    postContentImg,
    content,
    post,
    handleUpdatePost,
    setEdit,
    setSelectedPost,
    setCurrentPage,
    handleDeletePosts,
}) {
    const jwtDecode = jwtTokenDecode();
    const params = new URLSearchParams(window.location.search);
    const type = params.get("type");
    const [showComments, setShowComments] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [remark, setRemark] = useState("");
    const [likes, setLikes] = useState(post.likes);

    console.log(post);

    const handleRepostSubmit = () => {
        console.log("Remark:", remark);
        setShowModal(false);
    };

    function nFormatter(num, digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" },
        ];
        const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
        const item = lookup.findLast((item) => num >= item.value);
        return item
            ? (num / item.value)
                .toFixed(digits)
                .replace(regexp, "")
                .concat(item.symbol)
            : "0";
    }

    const likeUnlike = async () => {
        await axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/social-feed/like-unlike`,
                {
                    postId: post?._id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token()}`,
                    },
                }
            )
            .then((res) => {
                const isLiked = likes.some((like) => like._id === jwtDecode?._id);
                let updatedLikes;

                if (isLiked) {
                    // Remove like
                    updatedLikes = likes.filter((like) => like._id !== jwtDecode?._id);
                } else {
                    // Add like
                    const user = { _id: jwtDecode?._id, name: jwtDecode?.name }; // Replace with actual user info
                    updatedLikes = [...likes, user];
                }

                // Update state
                setLikes(updatedLikes);

                // Create a new post object with updated likes
                const updatedPost = { ...post, likes: updatedLikes };

                handleUpdatePost(updatedPost);
            });
    };

    const handleUploadPost = async (data) => {
        await axios
            .put(
                `${process.env.REACT_APP_API_BASE_URL}/social-feed/update/${data?._id}`,
                {
                    active: !data?.active,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token()}`,
                    },
                }
            )
            .then(async (res) => {
                if (res.data?.status == "success") {
                    await toast.success("Post Updated");
                    await setCurrentPage(1);
                    const updatedPost = { ...post, active: res.data?.socialFeed?.active };

                    handleUpdatePost(updatedPost);
                }
            })
            .catch((err) => toast.error("Invalid File Type"));
    };

    const handleDeletePost = async (data) => {
        await axios
            .delete(
                `${process.env.REACT_APP_API_BASE_URL}/social-feed/${data?._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token()}`,
                    },
                }
            )
            .then(async (res) => {
                if (res.data?.status == "success") {
                    await toast.success("Post Deleted");
                    await setCurrentPage(1);
                    handleDeletePosts(data);
                }
            })
            .catch((err) => { });
    };

    const items = [
        {
            key: "1",
            label: (
                <button
                    className="btn btn-sm p-0"
                    type="button"
                    onClick={() => {
                        setSelectedPost(post);
                    }}
                >
                    Edit
                </button>
            ),
        },
        {
            key: "2",
            label: (
                <button
                    className="btn btn-sm p-0"
                    onClick={() => {
                        handleUploadPost(post);
                    }}
                >
                    {post?.active ? "Inactive" : "Active"}
                </button>
            ),
        },
        {
            key: "3",
            label: (
                <button
                    className="btn btn-sm p-0"
                    onClick={() => {
                        handleDeletePost(post);
                    }}
                >
                    Delete
                </button>
            ),
        },
    ];

    return (
        <>
            <div className="post_feed_card">
                <div className="post_feed_header">
                    <img className="post_user_img" src={userImage} alt="User" />
                    <div>
                        <strong>{userName}</strong>
                        <span className="post_time">
                            {" "}
                            •{" "}
                            {post?.active ? (
                                <ReactTimeAgo date={postTime} locale="en-US" />
                            ) : (
                                "Hold"
                            )}
                        </span>
                    </div>
                    {type == "mypost" && (
                        <Dropdown
                            menu={{
                                items,
                            }}
                            placement="bottom"
                            className="ms-auto"
                        >
                            <button className="btn">
                                <BsThreeDots />
                            </button>
                        </Dropdown>
                    )}
                </div>
                <div className="post_feed_content">
                    {content && content.length > 0 && <p>{content} </p>}
                    {postContentImg && postContentImg.length > 0 ? (
                        postContentImg[0]?.type == "video" ? (
                            <video
                                className="post_image"
                                controls
                                src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${postContentImg[0]?.url}`}
                            />
                        ) : (
                            <img
                                className="post_image"
                                src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${postContentImg[0]?.url}`}
                                alt="Content"
                            />
                        )
                    ) : null}
                </div>

                <hr style={{ border: "1.21px solid rgba(104, 118, 132, 0.3)" }} />

                <div className="post_feed_actions">
                    <button
                        className={
                            post?.likes?.some((item) => item?._id === jwtDecode?._id)
                                ? "like-btn-active"
                                : ""
                        }
                        onClick={() => {
                            likeUnlike();
                        }}
                    >
                        👍 {nFormatter(post?.likes?.length, 1)}
                    </button>
                    <button onClick={() => setShowComments(!showComments)}>
                        💬 {nFormatter(post?.comments?.length, 1)}
                    </button>
                    <button onClick={() => setShowModal(true)}>🔄 Report</button>
                </div>

                {showComments && (
                    <CommentComponents
                        comment={post?.comments}
                        postId={post?._id}
                        handleUpdatePost={handleUpdatePost}
                        post={post}
                    />
                )}
            </div>

            {/* Modal for Repost */}
            <div className={`modal reason_modal fade ${showModal ? "show" : ""}`}
                tabIndex="-1"
                style={{ display: showModal ? "block" : "none" }}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Write a Reason</h5>
                            <button
                                type="button"
                                className="btn-close text-light"
                                onClick={() => setShowModal(false)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <textarea
                                className="form-control"
                                autoFocus
                                rows="10"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                                placeholder="Write Reason"
                            />

                            <button
                                type="button"
                                className="btn btn-primary reason_submit_btn"
                                onClick={handleRepostSubmit}
                            >
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
