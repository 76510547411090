export const getTermsCondition = () => {
  const country = localStorage.getItem("countryCode") || "GB";
  const data = {
    GB: [
      "In order to create an account on the app and use the services, the candidate must be at least 18 years old and have the capacity to enter into an effective agreement.",
      "During the registration process the candidate is required to produce truthful data. If found to be otherwise, Plagit has the right to block the account and de-register the candidate from the app, of which the candidate will be informed over a mail.Plagit or its 'Agency Affiliate' shall not be liable under any circumstances for the candidate to produce any law-abiding documents which may or may not be a requirement for the job.",
      "Plagit or its 'Agency Affiliate' shall not be liable under any circumstances for any loss, expense, damage, delay, costs or compensation (whether direct, indirect or consequential) which may be suffered or incurred by the Client arising from or in any way connected with the app.",
      "Plagit or its “Agency Affiliate” shall not be liable for any dispute, difference, controversy, or claim arising out of or in connection with this app, including (but not limited to) any question regarding its existence, validity, interpretation, performance,services, discharge, and applicable remedies.",
      "The client at any point in time will not have a restrictive covenant with its workforce causing prevention from using the services of this app.",
      "If your website or mobile app allows users to create content and make that content public to other users, a Content clause will inform users that they own the rights to the content they have created. This clause usually mentions that users must give you (the website or mobile app developer/owner) a license so that you can share this content on your website/mobile app and to make it available to other users.",
      "A Links to Other Websites clause will inform users that you are not responsible for any third party websites that you link to. This kind of clause will generally inform users that they are responsible for reading and agreeing (or disagreeing) with the Terms and Conditions or Privacy Policies of these third parties.",
      " A Governing Law clause will inform users which laws govern the agreement. These laws should come from the country in which your company is headquartered or the country from which you operate your website and mobile app",
      "A Termination clause will inform users that any accounts on your website and mobile app, or users' access to your website and app, can be terminated in case of abuses or at your sole discretion.",
      "An Intellectual Property clause will inform users that the contents, logo and other visual media you created is your property and is protected by copyright laws.",
      "If you sell products or services, you could cancel specific orders if a product price is incorrect. Your Terms and Conditions can include a clause to inform users that certain orders, at your sole discretion, can be canceled if the products ordered have incorrect prices due to various errors.",
      " If users can post content on your website or mobile app (create content and share it on your platform), you can remove any content they created if it infringes copyright. Your Terms and Conditions will inform users that they can only create and/or share content they own rights to. Similarly, if users can register for an account and choose a username, you can inform users that they are not allowed to choose usernames that may infringe trademarks, i.e. usernames like Google, Facebook, and so on.",
      "If users abuse your website or mobile app in any way, you can terminate their account. Your Termination clause can inform users that their accounts would be terminated if they abuse your service",
    ],
    AE: [
      "من أجل إنشاء حساب على التطبيق واستخدام الخدمات ، يجب أن يكون عمر المرشح 18 عامًا على الأقل ولديه القدرة على الدخول في اتفاق فعال.",
      "أثناء عملية التسجيل ، يتعين على المرشح إنتاج بيانات صادقة.إذا وجد أنه خلاف ذلك ، فإن Plagit له الحق في منع الحساب وإلغاء تسجيل المرشح من التطبيق ، والذي سيتم إبلاغه بالمرشح عبر البريد.المرشح لإنتاج أي مستندات ملتصقة بالقانون قد تكون أو لا تكون شرطًا للوظيفة.",
      `يجب ألا تكون Plagit أو "تابعة الوكالة" مسؤولة تحت أي ظرف من الظروف عن أي خسارة أو نفقات أو أضرار أو تأخير أو تكاليف أو تعويضات (سواء كانت مباشرة أو غير مباشرة أو تبعية) والتي قد تتعرض لها أو تكبدها من قبل العميل الناشئ عن أو بأي شكل من الأشكالمتصل بالتطبيق.`,
      `لن تكون Plagit أو "تابعة الوكالة" مسؤولة عن أي نزاع أو اختلاف أو جدل أو مطالبة ناشئة عن هذا التطبيق أو فيما يتعلق بهذا التطبيق ، بما في ذلك (على سبيل المثال لا الحصر) أي سؤال يتعلق بوجوده وصلاحيته وتفسيره وأداءه ،الخدمات ، التفريغ ، والعلاجات المعمول بها.`,
      "لن يكون للعميل في أي وقت من الأوقات عهد تقييدي مع قوتها العاملة مما تسبب في الوقاية من استخدام خدمات هذا التطبيق.",
      "إذا كان موقع الويب الخاص بك أو تطبيق الهاتف المحمول يتيح للمستخدمين إنشاء محتوى وجعل هذا المحتوى علنيًا للمستخدمين الآخرين ، فسيقوم بند المحتوى بإبلاغ المستخدمين بأنهم يمتلكون حقوق المحتوى الذي قاموا بإنشائه.يذكر هذا البند عادةً أنه يجب على المستخدمين منحك (موقع الويب/مالك تطبيقات الهاتف المحمول) ترخيصًا حتى تتمكن من مشاركة هذا المحتوى على موقع الويب الخاص بك/تطبيق الهاتف المحمول ولإتاحته للمستخدمين الآخرين.",
      "ستعمل روابط على شرط مواقع الويب الأخرى على إبلاغ المستخدمين بأنك لست مسؤولاً عن أي مواقع الويب الثالثة التي ترتبط بها.سيبلغ هذا النوع من البند المستخدمين بشكل عام أنهم مسؤولون عن القراءة والموافقة (أو عدم الموافقة) مع الشروط والأحكام أو سياسات الخصوصية لهذه الأطراف الثالثة.",
      " سوف يقوم شرط القانون الحاكم بإبلاغ المستخدمين عن القوانين التي تحكم الاتفاقية.يجب أن تأتي هذه القوانين من البلد الذي يقع فيه شركتك في المقر الرئيسي أو البلد الذي تدير منه موقع الويب الخاص بك وتطبيق الهاتف المحمول",
      "ستُبلغ شرط الإنهاء المستخدمين بأن أي حسابات على موقع الويب الخاص بك وتطبيق الهاتف المحمول ، أو وصول المستخدمين إلى موقع الويب الخاص بك وتطبيقك ، يمكن إنهاءها في حالة حدوث انتهاكات أو وفقًا لتقديرك الخاص.",
      "سوف يقوم شرط الملكية الفكرية بإبلاغ المستخدمين بأن المحتويات والشعار والوسائط المرئية الأخرى التي أنشأتها هي ممتلكاتك وهي محمية بموجب قوانين حقوق الطبع والنشر.",
      "إذا قمت ببيع المنتجات أو الخدمات ، فيمكنك إلغاء طلبات محددة إذا كان سعر المنتج غير صحيح.يمكن أن تتضمن الشروط والأحكام شرطًا لإبلاغ المستخدمين بأن بعض الطلبات ، وفقًا لتقديرك الخاص ، يمكن إلغاؤها إذا كان للمنتجات المطلوبة أسعار غير صحيحة بسبب أخطاء مختلفة.",
      " إذا كان بإمكان المستخدمين نشر المحتوى على موقع الويب الخاص بك أو تطبيق الهاتف المحمول (قم بإنشاء محتوى ومشاركته على النظام الأساسي الخاص بك) ، فيمكنك إزالة أي محتوى قاموا بإنشائه إذا انتهك حقوق الطبع والنشر.ستُعلم الشروط والأحكام المستخدمين أنه يمكنهم فقط إنشاء و/أو تبادل المحتوى الذي يمتلكونه.وبالمثل ، إذا كان بإمكان المستخدمين التسجيل للحصول على حساب واختيار اسم مستخدم ، فيمكنك إبلاغ المستخدمين أنه لا يُسمح لهم باختيار أسماء مستخدمين قد تنتهك العلامات التجارية ، أي أسماء المستخدمين مثل Google و Facebook وما إلى ذلك.",
      "إذا قام المستخدمون بإساءة استخدام موقع الويب الخاص بك أو تطبيق الهاتف المحمول بأي شكل من الأشكال ، فيمكنك إنهاء حسابهم.يمكن أن يبلغ شرط الإنهاء الخاص بك المستخدمين بأن حساباتهم سيتم إنهاءها إذا أسيء استخدام خدمتك",
    ],
    IT: [
      "Al fine di creare un account sull'app e utilizzare i servizi, il candidato deve avere almeno 18 anni e avere la capacità di stipulare un accordo efficace.",
      "Durante il processo di registrazione il candidato è tenuto a produrre dati veritieri.Se riscontrato essere diversamente, Plagit ha il diritto di bloccare l'account e di registrare il candidato dall'app, di cui il candidato sarà informato su una postail candidato a produrre documenti rispettosi della legge che possano o meno essere un requisito per il lavoro.",
      "Plagit o la sua 'affiliata di agenzia' non saranno responsabili in alcuna circostanza per eventuali perdite, spese, danni, ritardo, costi o compensazione (direttamente, indiretto o consequenziale) che possono essere subiti o sostenuti dal cliente derivante da o in alcun modoconnesso con l'app.",
      "Plagit o il suo 'affiliato di agenzia' non saranno responsabili per eventuali controversie, differenze, controversie o reclami derivanti da o in relazione a questa app, incluso (ma non limitato a) qualsiasi domanda relativa alla sua esistenza, validità, interpretazione, performance,Servizi, scarico e rimedi applicabili.",
      "Il cliente in qualsiasi momento non avrà un patto restrittivo con la sua forza lavoro che provoca la prevenzione dell'utilizzo dei servizi di questa app.",
      "Se il tuo sito Web o l'app mobile consente agli utenti di creare contenuti e rendere pubblici tali contenuti ad altri utenti, una clausola di contenuti informerà gli utenti di possedere i diritti per i contenuti che hanno creato.Questa clausola di solito menziona che gli utenti devono offrire una licenza (il sito Web o il proprietario dell'app mobile) in modo da poter condividere questi contenuti sul tuo sito Web/app mobile e renderlo disponibile per altri utenti.",
      "Un collegamento ad altri siti Web clausole informerà gli utenti che non sei responsabile per i siti Web di terze parti a cui si collega.Questo tipo di clausola informerà generalmente gli utenti di essere responsabili della lettura e dell'accordo (o in disaccordo) con i termini e le condizioni o le politiche sulla privacy di queste terze parti.",
      " Una clausola legale al governo informerà gli utenti quali leggi regolano l'accordo.Queste leggi dovrebbero provenire dal paese in cui la tua azienda ha sede o dal paese da cui gestisci il tuo sito Web e l'app mobile",
      "Una clausola di terminazione informerà agli utenti che qualsiasi account sul tuo sito Web e app mobile o l'accesso degli utenti al tuo sito Web e all'app può essere chiuso in caso di abusi o a vostro discrezione.",
      "Una clausola di proprietà intellettuale informerà gli utenti che il contenuto, il logo e altri media visivi che hai creato sono la tua proprietà ed è protetto dalle leggi sul copyright.",
      "Se vendi prodotti o servizi, è possibile annullare ordini specifici se un prezzo del prodotto non è corretto.I tuoi termini e condizioni possono includere una clausola per informare gli utenti che determinati ordini, a vostra discrezione, possono essere annullati se i prodotti ordinati hanno prezzi errati a causa di vari errori.",
      "Se gli utenti possono pubblicare contenuti sul tuo sito Web o sull'app mobile (creare contenuti e condividerli sulla tua piattaforma), è possibile rimuovere qualsiasi contenuto creato se viola il copyright.I tuoi termini e condizioni informeranno gli utenti che possono solo creare e/o condividere contenuti a cui possiedono i diritti.Allo stesso modo, se gli utenti possono registrarsi per un account e scegliere un nome utente, puoi informare gli utenti che non sono autorizzati a scegliere nomi utente che possono violare marchi, ovvero nomi utente come Google, Facebook e così via.",
      "Se gli utenti abusano del tuo sito Web o dell'app mobile in qualche modo, è possibile interrompere il proprio account.La clausola di terminazione può informare gli utenti che i loro account sarebbero terminati se abusano del tuo servizio",
    ],
  };
  const terms = data[country];
  console.log("terms =====================>>>>", terms);
  return terms;
};
