import PolicyPage from "./Policy";

const contentArray = [
  {
    id: 1,
    heading: "1. Scope of Delivery",
    text: "Plagit is committed to complying with international trade regulations and laws, including those about the United Arab Emirates (UAE). In alignment with our commitment to corporate responsibility and legal compliance, the following policy applies to our delivery and shipping services:",
  },
  {
    id: 2,
    heading: "2. OFAC Sanctioned Countries",
    text: "Website Listings: Our website lists all products and services available for purchase and shipping. Each product or service's availability and delivery options are subject to compliance with our Delivery & Shipping Policy.",
  },
  {
    id: 3,
    heading: "3. Shipping Restrictions",
    text: "Transparent Communication: Any shipping restrictions related to specific products or regions will be clearly communicated on our product pages and during checkout.",
  },
  {
    id: 4,
    heading: "4.Legal Framework",
    text: "UAE Law: We operate under the strict legal framework of the UAE and ensure that all our shipping and delivery practices comply fully with the relevant laws and regulations.",
  },
  {
    id: 5,
    heading: "5. Customer Support",
    text: "",
    listItems: [
      "Queries and Clarifications: Customers are encouraged to contact our customer support team with any questions or clarifications related to our Delivery and Shipping policy.",
    ],
  },
  {
    id: 6,
    heading: "",
    text: "Plagit endeavours to provide efficient delivery services while upholding legal and ethical standards. We appreciate our customers' trust and strive to meet their expectations responsibly.",
  },
];
const DeliveryPolicy = () => {
  return <PolicyPage title="Delivery & Shipping Policy" data={contentArray} />;
};

export default DeliveryPolicy;
