import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import styles from "./Footer.module.css";
import visaLogo from "./visa.png";
import masterLogo from "./master.png";

function Footer() {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-2">
            <div className="leftPartLinks">
              <ul>
                <li onClick={scrollToTop}>
                  <Link to="/"> {t("home_footer_home")} </Link>
                  <Link to="/contact-us">{t("home_footer_contact")}</Link>
                  {/* <Link to="/career">{t("home_footer_career")}</Link> */}
                  <Link to="/about">{t("home_footer_about_us")}</Link>
                  <Link to="/our-services">
                    {t("home_footer_our_services")}
                  </Link>
                  <Link to="/meet-the-team">
                    {t("home_footer_meet_the_team")}
                  </Link>
                  <Link to="/faq">{t("home_footer_faq")}</Link>
                  {/*<Link to="/mh-dubai">{t("home_footer_dubai")}</Link>*/}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-8">
            <div className="middlePart">
              <h3>{t("home_footer_need_help_contact_us")}</h3>
              <h4> {t("home_footer_phone_number")}</h4>
              <h5>{t("home_footer_email")}</h5>
              <div className="placeImg">
                <h6>
                  {/*<img
                    src="/assets/frontend/images/indexImages/place.png"
                    alt="image"
                  />*/}
                  {t("home_footer_company_address")}
                  {/*  <small
                    style={{
                      fontSize: "10px",
                      display: "block",
                    }}
                  >
                    {" "}
                    {t("home_footer_company_address_2")}
                  </small> */}
                </h6>
              </div>
              <span>{t("home_footer_follow_us_on")}</span>
              <div className="socialMediaIcons">
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=61559738663892"
                  rel="noreferrer"
                >
                  <img
                    className="img-fluid"
                    src="/assets/frontend/images/indexImages/socialMedia/Group 116168.png"
                    alt="image"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/plagit.ae"
                  rel="noreferrer"
                >
                  <img
                    className="img-fluid"
                    src="/assets/frontend/images/indexImages/socialMedia/Group 116169.png"
                    alt="image"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/102924386/admin/dashboard"
                  rel="noreferrer"
                >
                  <img
                    className="img-fluid"
                    src="/assets/frontend/images/indexImages/socialMedia/Group 116170.png"
                    alt="image"
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@plagitmh"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-fluid"
                    src="/assets/frontend/images/indexImages/socialMedia/Group 116171.png"
                    alt="image"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-2">
            <div className="rightPartLinks">
              <ul>
                <li onClick={scrollToTop}>
                  <Link to="/corporate-information">
                    {t("home_footer_corporate_information")}
                  </Link>
                  <Link to="/terms-of-use">
                    {" "}
                    {t("home_footer_website_term_of_use")}
                  </Link>
                  <Link to="/privacy">{t("home_footer_privacy_notice")}</Link>
                  <Link to="/"> {t("home_footer_cookies")}</Link>
                  <a href="#">{t("home_footer_download_the_app")}</a>
                </li>
              </ul>
              <div className="rightPartLogoWrapper text-end">
                <a
                  href="https://apps.apple.com/us/app/mh/id6446052294"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-fluid me-2"
                    src="/assets/frontend/images/indexImages/socialMedia/Group 117303.png"
                    alt="image"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.invain.mh"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-fluid"
                    src="/assets/frontend/images/indexImages/socialMedia/Group 117304.png"
                    alt="image"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col className="text-center">
            <div className={`${styles.footerLink}`}>
              <div className={styles.footerContant}>
                <ul>
                  <li onClick={scrollToTop}>
                    <Link to={`/privacy-policy`}>Privacy Policy</Link>
                  </li>
                  <li onClick={scrollToTop}>
                    <Link to={`/pricing-policy`}>Pricing Policy</Link>
                  </li>
                  <li onClick={scrollToTop}>
                    <Link to={`/delivery-policy`}>
                      Delivery & Shipping Policy
                    </Link>
                  </li>
                  <li onClick={scrollToTop}>
                    <Link to={`/return-policy`}>Refund Policy</Link>
                  </li>
                  <li onClick={scrollToTop}>
                    <Link to={`/goods-service`}>Goods & Services</Link>
                  </li>
                  <li onClick={scrollToTop}>
                    <Link to={`/payment-policy`}>Payment Policy</Link>
                  </li>
                  <li onClick={scrollToTop}>
                    <Link to={`/cancel-policy`}>Cancellation Policy</Link>
                  </li>
                  <li onClick={scrollToTop}>
                    <Link to={`/govt-laws`}>Governing Law</Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>

        <div className="row">
          <div className="col-lg-12 footerFinal">
            <div className="footerTextWrpper d-flex justify-content-between">
              <span>{t("home_footer_copy_right_all_reserved")}</span>
              <img
                height={50}
                width={50}
                className="img-fluid footerFinalLogo"
                src="/assets/frontend/images/indexImages/footerLogo.png"
                alt="image"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <p className="text-white mt-2 fw-bold"> Pay with</p>
          <div className="cardImage">
            <img
              src={visaLogo}
              style={{ width: "150px", height: "40px" }}
              alt="visa"
            />
            <img
              src={masterLogo}
              style={{ width: "150px", height: "100px" }}
              alt="mastercard"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
