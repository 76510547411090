import React from "react";
import DynamicCarousel2 from "./DynamicCarousel/DynamicCarousel2";

const TrustedPartner = ({ partners }) => {
  const cardDesign = {
    backgroundColor: "white",
    borderRadius: "15px",
    width: "150px",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
  };

  const partnerItems = partners.list.map((partner, index) => (
    <div key={index}>
      <img
        style={cardDesign}
        src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${partner.url}`}
        alt={partner.title}
      />
      {/* <div>{partner.title}</div> */}
    </div>
  ));
  return (
    <div
      style={{
        backgroundColor: "#58c8c826",
        marginTop: "100px",
        // marginBottom: "100px",
        padding: "20px",
      }}
    >
      <div className="container mx-auto">
        <div className="text-center">
          <h3 className="text-uppercase">{partners.title}</h3>
          <p>{partners.subTitle}</p>
        </div>
        <div className="mt-5">
          {partnerItems.length > 3 ? (
            <DynamicCarousel2
              items={partnerItems}
              slidesToShow={4}
              autoplaySpeed={2000}
            />
          ) : (
            <div className="d-flex">{partnerItems}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrustedPartner;
