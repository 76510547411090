import PolicyPage from "./Policy";

const contentArray = [
  {
    id: 1,
    heading: "1. Introduction",
    text: "At Plagit, we believe in transparency and straightforwardness when pricing our services and products. Our pricing structure is designed to reflect our offerings' value and quality while being competitive and fair.",
  },
  {
    id: 2,
    heading: "2. Hourly Service Rates",
    text: "Our services are priced on an hourly basis. This ensures that you only pay for the time you need and the services you use. Here are the key points about our hourly service rates:",
    listItems: [
      "We staff our services to provide efficient and high-quality results, ensuring the best value for the time you pay for.",
      "Our hourly rates are determined by the service's complexity and nature.",
      "We provide detailed breakdowns of our hourly rates upon request to ensure complete transparency.",
    ],
  },
  {
    id: 3,
    heading: "3. Pricing for Items and Products",
    text: "The pricing for items and products offered by Plagit is as follows:",
    listItems: [
      "All product pricing will be clearly listed on our website or provided at the point of inquiry.",
      "Our products are priced competitively, reflecting their quality and value.",
      "Discounts and promotions may be offered at our discretion and will be clearly communicated when available.",
    ],
  },
  {
    id: 4,
    heading: "4. Mandatory Inclusion",
    text: "",
    listItems: [
      "The inclusion of pricing for all items, products, and services is mandatory and will always be presented clearly to our customers before any commitment is made.",
      "We are committed to providing complete pricing information without hidden fees or charges.",
    ],
  },
  {
    id: 5,
    heading: "5. Cookies and Tracking Technology",
    text: "Our website may use cookies and tracking technology depending on the features offered. Cookies and tracking technology are functional for gathering information such as browser type and operating system, tracking the number of visitors to the site, and understanding how visitors use the site.",
  },
  {
    id: 6,
    heading: "6. Review and Adjustments",
    text: "",
    listItems: [
      "Plagit reserves the right to review and adjust pricing to remain competitive and reflect market changes.",
      "Any pricing adjustments will be communicated to our customers with adequate notice.",
    ],
  },
  {
    id: 7,
    heading: "7. Inquiries",
    text: "If you have any inquiries or need further clarification on our pricing, please do not hesitate to contact us. Our customer service team is always ready to provide the information you need to make an informed decision.",
  },
  {
    id: 8,
    heading: "",
    text: "Plagit aims to maintain trust and ensure customer satisfaction by outlining our pricing policy. We are dedicated to providing our customers with the highest level of service at the best possible price.",
  },
];
const PricingPolicy = () => {
  return <PolicyPage title="Pricing Policy" data={contentArray} />;
};

export default PricingPolicy;
