import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

export default function BlogDetails() {
  const { slug } = useParams();
  const [data, setdata] = useState({});
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/blog/get-single-blog/${slug}`)
      .then((res) => res.json())
      .then((data) => {
        setdata(data?.details);
        // console.log(data?.details);
      });
  }, [slug]);

  return (
    <>
      <Helmet>
        <meta name="description" content="Test" />
        <title> {`${data.title} - PLAGIT`} </title>
      </Helmet>

      <div className="container">
        {/* <h3 className="m-2">{data.title}</h3> */}
        <img
          src={data.thumbnail}
          alt={data.title}
          className="img-fluid mb-3"
        />{" "}
        <div dangerouslySetInnerHTML={{ __html: data.description }} />
        <div className="mb-3">
          Keywords :{" "}
          {data.keywords &&
            data.keywords.map((keyword) => {
              return (
                <span className="text-warning underline">
                  <u>
                    <strong>{keyword}</strong>
                  </u>
                </span>
              );
            })}
        </div>
      </div>
    </>
  );
}
