import React, { useState, useEffect, useCallback } from 'react';

import { Button, Drawer, Form, Select, Input, Spin } from 'antd';
import axios from "axios";

import { responseNotification } from '../../../utils/notifcation';
import { addHandler, fetchHandler, updateSourceHandler } from '../../../api/source';

import { token } from '../../../utils/authentication';
import Loader from '../../loadar/Loader';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function Source() {
  const { t } = useTranslation();
  //get source
  const [source, setSource] = useState([]);

  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();
  const [open, setOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [sourceId, setSourceId] = useState();

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showEditDrawer = (id) => {
    setSourceId(id);
    setIsEditModalOpen(true);
  };

  const onCloseEdit = () => {
    setIsEditModalOpen(false);
  };

  const onFinish = (values) => {

    const name = values?.name;
    const active = values?.active === "YES" ? true : false;

    const addSourceFields = { name, active };

    if (name) {
      setLoading(true);
      addHandler(addSourceFields)
        .then((res) => res.json())
        .then((res) => {
          if (res?.statusCode === 201) {
            setError(undefined);
            setLoading(false);
            responseNotification("Source created successfully!", "success");
            fetchSource();
            onClose();
            form.resetFields();
          } else if (res?.statusCode === 400) {
            setError(res?.errors?.[0].msg);
            setLoading(false);
          } else if (res?.statusCode === 500) {
            setError(res?.message);
            setLoading(false);
          }
        });
    }
  };

  //Edit source Data
  const onFinishEdit = (values) => {

    const id = sourceId;
    const name = values?.name;
    const active = values?.active === "YES" ? true : false;

    const receivedSourceFields = { id, name, active };

    if (id) {
      setLoading(true);
      updateSourceHandler(receivedSourceFields)
        .then((res) => res.json())
        .then((res) => {
          if (res?.statusCode === 200) {
            setError(undefined);
            setLoading(false);
            responseNotification("Source updated successfully!", "success");
            fetchSource();
            onCloseEdit();
            form.resetFields();
          } else if (res?.statusCode === 400) {
            setError(res?.errors?.[0].msg);
            setLoading(false);
          } else if (res?.statusCode === 500) {
            setError(res?.message);
            setLoading(false);
          }
        });
    }
  };

  const fetchSource = useCallback(async () => {
    setLoading(true);
    await fetchHandler().then((res) => {
      if (res?.status === 200) {
        setSource(res?.data?.sources);
      } else {
        setLoading(false);
      }
    });

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchSource();
  }, []);

  const fetchSingleSource = async () => {
    try {

      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/sources/${sourceId}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      formEdit.setFieldsValue({
        name: res?.data?.details?.name,
        active: res?.data?.details?.active === true ? "YES" : "NO"
      });

    } catch (err) {
      // console.log(err, "error");
    }
  };

  useEffect(() => {
    fetchSingleSource();
  }, [sourceId]);


  return (
    <div className="container-fluid px-4">
      <div className='row mt-4'>
        <div className='d-flex justify-content-between'>
          <h3 className='mb-4 title'>{t("source_list")}</h3>
          <Button type="primary" style={{ background: '#58c8c8', color: 'white' }} className='ml-5' onClick={showDrawer}>
          {t("add_source")}
          </Button>
        </div>
      </div>
      <div className='card sd'>
        <table className="table table-bordered table-hover">
          <thead style={{ backgroundColor: "#58c8c8", color: 'white' }}>
            <tr className='text-center'>
              <th>#</th>
              <th>{t("name")}</th>
              <th>{t("status")}</th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td>
                  <Loader />
                </td>
              </tr>
            ) : source.length ? (source?.map((data, index) => (
              <tr key={index} className='text-center'>
                <th>{index + 1}</th>
                <td>{data?.name}</td>
                <td>{data?.active === true ? "YES" : "NO"}</td>
                <td>
                  <Button type="primary" style={{ background: '#58c8c8', color: 'white' }} className='ml-5'
                    onClick={() => showEditDrawer(data?._id)}>
                    {t("edit")}
                  </Button>
                </td>
              </tr>
            )))
              : (
                <tr>
                  <td colSpan={5} className='text-center text-danger'>{t("no_data_found!")}</td>
                </tr>
              )}
          </tbody>
        </table>

        <Drawer title={t("add_new_source")} width={520} form={form} closable={false} onClose={onClose} open={open}>
          <div className="drawer-toggle-wrapper">
            <div className="drawer-toggle-inner-wrapper">
              <Form
                className="ant-form ant-form-vertical"
                layout="vertical"
                onFinish={onFinish}
              >
                <div className="col-lg-12">
                  <div className="single-field">
                    <Form.Item
                      label={t("source_name")}
                      name="name"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_source_name")
                        },
                      ]}
                    >
                      <Input placeholder={t("enter_source_name")}className="ant-input ant-input-lg" />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="single-field1">
                    <Form.Item
                      label={t("active")}
                      name="active"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("status_is_required")
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_active_yes_or_no")}
                        optionFilterProp="children"
                      >
                        <Option value="YES">YES</Option>
                        <Option value="NO">NO</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                {getError ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="error-message">
                        <p className="error-text-color">{getError}</p>
                      </div>
                    </div>
                  </div>
                ) : undefined}

                <div className="col-lg-12">
                  <Form.Item>
                    <button
                      disabled={loading}
                      className="btn"
                      style={{ background: '#58c8c8', color: 'white' }}
                      type="submit"
                    >
                      {!loading && t("save")}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {t("please_wait...")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </Drawer>


        {/* Edit Drawer  */}
        <Drawer
          title="Edit source"
          width={520}
          closable={false}
          onClose={onCloseEdit}
          open={isEditModalOpen}
        >

          <div className="drawer-toggle-wrapper">
            <div className="drawer-toggle-inner-wrapper">
              <Form
                className="ant-form ant-form-vertical"
                layout="vertical"
                onFinish={onFinishEdit}
                form={formEdit}
                initialValues={{ remember: true }}
              >
                <div className="col-lg-12">
                  <div className="single-field">
                    <Form.Item
                      label={t("source_name")}
                      name="name"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_source_name")
                        },
                      ]}
                    >
                      <Input placeholder={t("enter_source_name")}className="ant-input ant-input-lg" />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="single-field1">
                    <Form.Item
                      label={t("active")}
                      name="active"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("status_is_required")
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_active_yes_or_no")}
                        optionFilterProp="children"
                      >
                        <Option value="YES">YES</Option>
                        <Option value="NO">NO</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                {getError ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="error-message">
                        <p className="error-text-color">{getError}</p>
                      </div>
                    </div>
                  </div>
                ) : undefined}

                <div className="col-lg-12">
                  <Form.Item>
                    <button
                      disabled={loading}
                      className="btn btn-primary"
                      type="submit"
                    >
                      {!loading && t("save")}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {t("please_wait...")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  )
}

export default Source