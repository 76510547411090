import { Button, Col, Modal, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";
import { FcCalendar, FcOrganization } from "react-icons/fc";
import { IoEye, IoNotifications, IoShirt } from "react-icons/io5";
import { token } from "../../../../utils/authentication";

const RequestDates = ({
  isModalVisible,
  handleOk,
  handleCancel,
  activeEmployee,
  countDates,
  modalId,
  setRefetch,
  refeth,
}) => {
  console.log(modalId);
  const [requestDates, setRequestDates] = useState([]);
  const [updatedObject, setUpdatedObject] = useState({
    id: modalId,
    requestDate: [],
    rejectedDate: [],
  });

  useEffect(() => {
    // Check if activeEmployee is available before setting the state
    if (activeEmployee) {
      setRequestDates(activeEmployee.requestDate || []);
      setUpdatedObject({
        id: modalId,
        requestDate: activeEmployee.requestDate || [],
        rejectedDate: [],
      });
    }
  }, [activeEmployee, modalId]);

  const deleteDate = (item) => {
    const newRequestDates = requestDates.filter((date) => date !== item);
    const deletedDate = requestDates.find((date) => date === item);

    // Update the state with the new request dates
    setRequestDates(newRequestDates);

    // Update the state with the updated object including rejectedDate
    setUpdatedObject({
      id: modalId,
      requestDate: newRequestDates,
      rejectedDate: [...(updatedObject?.rejectedDate || []), deletedDate],
    });
  };

  const updateRequestDate = async () => {
    try {
      // Check if the updatedObject has data
      if (!updatedObject || !updatedObject.id) {
        console.error("Invalid or missing data in updatedObject");
        return;
      }

      // Your first API endpoint URL
      const firstApiUrl = `${process.env.REACT_APP_API_BASE_URL}/notifications/update-request-date`;

      // Your headers
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      };

      // Make the first PUT request to update request date
      const response = await axios.put(firstApiUrl, updatedObject, { headers });

      // Handle the response from the first API
      console.log("First API Response:", response.data);

      // Check if the first API call was successful
      if (response.data.status === "success") {
        // Your second API endpoint URL
        const secondApiUrl = `${process.env.REACT_APP_API_BASE_URL}/notifications/update-status`;

        // Your second API payload
        const secondApiPayload = {
          id: modalId,
          hiredStatus: "ALLOW",
        };

        // Make the second PUT request to update status
        const secondApiResponse = await axios.put(
          secondApiUrl,
          secondApiPayload,
          { headers }
        );

        toast.success(`Successfully allowed the hire request`);
        handleOk();
        setRefetch(!refeth);
      } else {
      }
    } catch (error) {
      // Handle errors
      console.error("API Error:", error);

      // Optionally, perform actions if the API call fails
      // handleFailure();
    }
  };

  const rejectRequest = async () => {
    try {
      // Check if the updatedObject has data
      if (!updatedObject || !updatedObject.id) {
        console.error("Invalid or missing data in updatedObject");
        return;
      }

      // Your reject API endpoint URL
      const rejectApiUrl = `${process.env.REACT_APP_API_BASE_URL}/notifications/update-status`;
      // Your reject API payload
      const rejectApiPayload = {
        id: modalId,
        hiredStatus: "DENY",
      };

      // Your headers
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      };

      // Make the reject PUT request
      const rejectApiResponse = await axios.put(
        rejectApiUrl,
        rejectApiPayload,
        { headers }
      );
      toast.success(`Successfully rejected the hire request`);
      handleOk();
      setRefetch(!refeth);
    } catch (error) {
      // Handle errors
      console.error("Reject API Error:", error);
    }
  };

  return (
    <Modal
      title="Booked Dates"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {requestDates?.map((item, index) => (
        <>
          <Row
            key={index}
            style={{
              background: "#FAFAFA",
              padding: "10px",
              margin: "10px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Col style={{}}>
              <p>
                <FcCalendar
                  style={{
                    marginRight: "5px",
                  }}
                />
                {item?.startDate} - {item?.endDate}
                {requestDates.length > 1 && (
                  <span
                    key={index}
                    className="text-red-500 ml-2 cursor-pointer"
                    onClick={() => deleteDate(item)}
                  >
                    <AiFillDelete
                      style={{
                        color: "red",
                        marginLeft: "5px",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                    />
                  </span>
                )}
              </p>
              <div
                style={{
                  color: "#58c8c8",
                  fontWeight: "bold",
                }}
              >
                {countDates(item?.startDate, item?.endDate)} Days
              </div>
              <div>
                {item?.startTime} - {item?.endTime}
              </div>
            </Col>
          </Row>
        </>
      ))}

      <div
        style={{
          background: "#FAFAFA",
          padding: "10px",
          margin: "10px",
          borderRadius: "10px",
        }}
      >
        <div className="d-flex">
          <IoNotifications
            style={{
              marginRight: "5px",
              height: "50px",
              width: "50px",
            }}
          />
          <p>{activeEmployee?.text}</p>
        </div>
        <p
          style={{
            marginLeft: "26px",
            marginTop: "-20px",
            color: "#a6a6a6",
          }}
        >
          {activeEmployee?.restaurantAddress}
        </p>
        <div className="d-flex">
          <FcOrganization
            style={{
              marginRight: "5px",
              height: "20px",
              width: "20px",
            }}
          />
          <p>
            The resturant is situated at a distance of{" "}
            <span style={{ color: "#58c8c8", fontWeight: "bold" }}>
              {activeEmployee?.distance || 0} km
            </span>
            from your location.
          </p>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div
            className="d-flex align-items-center"
            style={{
              marginTop: "-10px",
            }}
          >
            <IoShirt
              style={{
                marginRight: "5px",
                height: "20px",
                width: "20px",
              }}
            />
            <p className="mt-3">
              Restaurant will provide you uniforms, view uniforms
            </p>
          </div>
          <IoEye style={{ color: "#58c8c8", fontWeight: "bold" }} />
        </div>
      </div>

      {/* Button */}
      <div className="d-flex justify-content-around mt-4">
        <Button
          style={{
            background: "#58c8c8",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={updateRequestDate}
        >
          ALLOW ALL
        </Button>
        <Button
          style={{
            background: "red",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={rejectRequest}
        >
          DENY ALL
        </Button>
      </div>
    </Modal>
  );
};

export default RequestDates;
