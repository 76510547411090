/* eslint-disable react/prop-types */
import "react-multi-carousel/lib/styles.css";
import EventCard from "./EventCard";
import CarouselTemplate from "./CarouselTemplate";

const EventCardCarousel = ({ events }) => {
  return (
    <CarouselTemplate>
      {events.map((event, index) => (
        <div key={index} className="rounded-[25px]   mx-3 border mb-6 mt-12">
          <EventCard
            key={index}
            title={event.title}
            location={event.location}
            date={event.date}
            time={event.time}
            entryFee={event.entryFee}
            imageUrl={event.imageUrl}
            detailsUrl={event.detailsUrl}
          />
          <div className="w-[94%] h-[86%] left-[3%] mx-auto top-[3.5rem] rounded-[55px] bg-[#58C8C8] z-[-2]  absolute"></div>
        </div>
      ))}
    </CarouselTemplate>
  );
};

export default EventCardCarousel;
