import PolicyPage from "./Policy";

const contentArray = [
  {
    id: 1,
    heading: "1. Introduction",
    text: "At Plagit, we value our customers' privacy and are committed to protecting their personal information. This Privacy Policy outlines the types of information we collect, how it is used, and the measures we take to ensure your personal data is treated with the highest standards of security and confidentiality. Welcome to Wege. This Privacy Policy outlines how we collect, use, and safeguard your personal information in accordance with the laws of the United Arab Emirates.",
  },
  {
    id: 2,
    heading: "2. Information Collection and Use",
    text: "We may collect information from you when you use our website, including but not limited to your name, email address, contact information, and payment details when you make a purchase or register on our site. The information collected is used to process transactions, provide the requested services, and enhance your experience with our services.",
  },
  {
    id: 3,
    heading: "3. Payment Information",
    text: "Plagit takes the following approach regarding the security of payment information:",
    listItems: [
      "Credit/Debit Card Details: Plagit confirms that all credit/debit card details and personally identifiable information will NOT be stored, sold, shared, rented, or leased to any third parties.",
    ],
  },
  {
    id: 4,
    heading: "4. Third-Party Disclosure",
    text: "We do not sell, trade, or transfer your personally identifiable information to outside parties. This does not include trusted third parties who assist us in operating our website, conducting our business, or serving you, so long as those parties agree to keep this information confidential.",
  },
  {
    id: 5,
    heading: "5. Cookies and Tracking Technology",
    text: "Our website may use cookies and tracking technology depending on the features offered. Cookies and tracking technology are functional for gathering information such as browser type and operating system, tracking the number of visitors to the site, and understanding how visitors use the site.",
  },
  {
    id: 6,
    heading: "6. Policy Changes and Updates",
    text: "The following applies to our policy changes:",
    listItems: [
      "Updates to Policies: The Website Policies and Terms and Conditions may be changed or updated occasionally to meet the requirements and standards. Therefore, customers are encouraged to frequently visit these sections to be updated on the changes on the website.",
      "Effective Date: Modifications will be effective on the day they are posted.",
    ],
  },
  {
    id: 7,
    heading: "7. Your Consent",
    text: "By using our site, you consent to our Privacy Policy.",
  },
  {
    id: 8,
    heading: "8. Contacting Us",
    text: "If you have any questions regarding this Privacy Policy, you may contact us using the information below:",
    link: "info@plagit.com",
  },
];
const PrivacyPolicy = () => {
  return <PolicyPage title="Privacy Policy" data={contentArray} />;
};

export default PrivacyPolicy;
