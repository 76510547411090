import { Avatar, Dropdown, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { AiOutlineMessage } from "react-icons/ai";
import { HiOutlineBookmark } from "react-icons/hi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import jwtDecode from "jwt-decode";
import { ContextApi } from "../../../hooks/ContextApi";
import {
    changeLanguageFunc,
    getUserId,
    getUserInfo,
} from "../../../utils/commonFunctions";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { responseNotification } from "../../../utils/notifcation";
import Notification from "./Notification/Notification";
import notificationSound from "./Notification/notification-sound.mp3";
import { DownOutlined } from "@ant-design/icons";
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { FiPower } from "react-icons/fi";
import axios from "axios";
import { token } from "../../../utils/authentication";

function Header() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userInfo = getUserInfo();

    const jwtDecode = jwtTokenDecode();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(
        localStorage.getItem("countryCode") || "GB"
    );

    const location = useLocation();
    const currentPath = location.pathname;
    const isClientDashboard2 = currentPath === "/client-dashboard2";

    const profilePictureUrl =
        userInfo.profilePicture && userInfo.profilePicture != ""
            ? `${process.env.REACT_APP_ASSETs_BASE_URL}/${userInfo.profilePicture}`
            : "/assets/client.jpg";

    const {
        getShortList,
        notificationCount,
        setNotificationCount,
        loadingShortList,
    } = useContext(ContextApi);
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
        withCredentials: false,
        transports: ["websocket", "polling", "flashsocket"],
    });

    const [message, setMessage] = useState("");
    useEffect(() => {
        const payload = {
            senderId: getUserId(), // sender id
            isAdmin: true,
        };
        axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token()}`,
                    },
                }
            )
            .then((res) => {
                const conversationId = res.data.details._id;
                axios
                    .get(
                        `${process.env.REACT_APP_API_BASE_URL}/messages/unread-msg?conversationId=${conversationId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token()}`,
                            },
                        }
                    )
                    .then((res) => {
                        setMessage(res.data.details.count);
                    });
            });
    }, []);

    useEffect(() => {
        console.log("WebSocket connection initiated.");

        socket.on("notification", (dataFromServer) => {
            console.log("Received notification:", dataFromServer);

            const logedInUserId = getUserId();
            if (dataFromServer.userInfo._id === logedInUserId) {
                setNotificationCount(notificationCount + 1);
                responseNotification(dataFromServer.body, "success");
                new Audio(notificationSound).play();
            }
        });

        socket.on("new_message", (data) => {
            var currentUrl = window.location.href;
            // CHECK IF THE MESSAGE IS FROM THE ADMIN
            if (
                data.message.receiverDetails?.length > 1 &&
                data.message.receiverDetails
                    .map((item) => item.receiverId)
                    .includes(getUserId()) &&
                !currentUrl.includes("/client-help-support")
            ) {
                const message = `Admin: ${data.message.text}`;
                responseNotification(message, "success");
                new Audio(notificationSound).play();
                return;
            }

            // CHECK IF THE MESSAGE IS FROM THE USER
            if (data.message.receiverDetails[0].receiverId === getUserId()) {
                const message = `${data.message.senderDetails.name} : ${data.message.text}`;
                responseNotification(message, "success");
                new Audio(notificationSound).play();
            }
        });

        return () => {
            socket.off("notification");
            socket.off("new_message");
            console.log("WebSocket connection closed.");
        };
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("loginData");
        navigate("/");
        window.location.reload();
    };

    const notificationMenu = (
        <Notification setNotificationCount={setNotificationCount} />
    );

    const [prevCount, setPrevCount] = useState(0);
    const [shake, setShake] = useState(false);

    useEffect(() => {
        if (notificationCount > prevCount) {
            setShake(true);
            const timer = setTimeout(() => setShake(false), 500); // 500ms for the shaking duration
            return () => clearTimeout(timer);
        }
        setPrevCount(notificationCount);
    }, [notificationCount, prevCount]);

    const shakingStyle = {
        animation: shake ? "shake 0.5s cubic-bezier(.36,.07,.19,.97) both" : "",
    };
    // console.log(notificationCount);

    const items = [
        {
            label: (
                <Link to="/profile" className="profile-item">
                    <FaRegUserCircle className="pro-icons" />
                    Edit Profile
                </Link>
            ),
            key: "0",
        },
        {
            label: (
                <Link to="/setting" className="profile-item">
                    <MdOutlinePassword className="change-password" />
                    Setting
                </Link>
            ),
            key: "1",
        },
        {
            label: (
                <a href="" onClick={handleLogout} className="profile-item">
                    <FiPower className="close-btn" />
                    Logout
                </a>
            ),
            key: "2",
        },
    ];

    return (
        <section className="Dashboardheader sticky-top">
            <div className="container header_container">
                <div className="row">
                    <div className="col-lg-12">
                        <nav className="navbar DashboardNavban navbar-expand-md navbar-light">
                            <a className="navbar-brand " href="/">
                                <img
                                    className="img-fluid Dashboard_nav_logo"
                                    style={{
                                        height: "38px",
                                    }}
                                    src="/assets/frontend/images/Dashboardimages/logo_full.png"
                                    alt="Default"
                                />
                            </a>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div
                                className="Dash_navb_custom collapse navbar-collapse"
                                id="navbarSupportedContent"
                            >
                                <ul className="Dashboard_navbar_custom navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link
                                            className="Dashboard_navbar_custom_hover nav-link"
                                            to="/short-list"
                                        >
                                            <div
                                                className="navLinkImageTextWraper"
                                                style={{ position: "relative" }}
                                            >
                                                <HiOutlineBookmark
                                                    style={{
                                                        fontSize: "30px",
                                                        fontWeight: "bold",
                                                    }}
                                                />
                                                {getShortList?.total > 0 && (
                                                    <span className="bage_count">
                                                        {getShortList.total}
                                                    </span>
                                                )}
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Dropdown overlay={notificationMenu} trigger={["click"]}>
                                            <a
                                                className="Dashboard_navbar_custom_hover nav-link"
                                                href="#"
                                            >
                                                <div
                                                    className="navLinkImageTextWraper"
                                                    style={{ position: "relative" }}
                                                >
                                                    <IoMdNotificationsOutline
                                                        style={{
                                                            fontSize: "30px",
                                                            ...shakingStyle,
                                                        }}
                                                    />
                                                    {notificationCount > 0 && (
                                                        <span className="bage_count">
                                                            {notificationCount > 20
                                                                ? "20+"
                                                                : notificationCount}
                                                        </span>
                                                    )}
                                                </div>
                                            </a>
                                        </Dropdown>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            className="Dashboard_navbar_custom_hover nav-link"
                                            to="/client-help-support"
                                        >
                                            <div
                                                className="navLinkImageTextWraper"
                                                style={{ position: "relative" }}
                                            >
                                                <AiOutlineMessage
                                                    style={{
                                                        fontSize: "30px",
                                                        ...shakingStyle,
                                                    }}
                                                />

                                                <span className="bage_count">
                                                    {message == "" ? 0 : message}
                                                </span>
                                            </div>
                                        </Link>
                                    </li>

                                    <li>
                                        <ReactFlagsSelect
                                            defaultCountry="GB"
                                            countries={["GB", "AE", "IT"]}
                                            selected={selected || "GB"}
                                            customLabels={{
                                                US: "Eng",
                                                GB: "Eng",
                                                AE: "Ara",
                                                IT: "Ita",
                                            }}
                                            onSelect={(e) => {
                                                setSelected(e);
                                                changeLanguageFunc(e);
                                            }}
                                        />
                                    </li>

                                    {!isClientDashboard2 && (
                                        <li className="nav-item">
                                            <Dropdown
                                                menu={{
                                                    items,
                                                }}
                                                trigger={["click"]}
                                            >
                                                <a
                                                    className="profile-icon-menu"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img
                                                        src={profilePictureUrl}
                                                        alt="Profile"
                                                        className="rounded-circle user-icon-btn"
                                                    />
                                                    <DownOutlined className="down-arrow" />
                                                </a>
                                            </Dropdown>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Header;
