import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Discover PLAGIT, where recruitment is art. Our delicate and tailored approach ensures the perfect match for every company. Experience recruitment redefined.          "
        />
        <title>Recruitment Excellence at PLAGIT</title>
      </Helmet>

      <div
        class="banner-header section-padding valign bg-img bg-fixed img-fluid container"
      >
        <img
          src="assets/frontend/images/aboutUs/Our Services-01 cc.jpg"
          className="banner-header section-padding valign bg-img bg-fixed img-fluid container"
          alt="our services"
          style={{
            minHeight: "400px",
            width: "100%",
            objectFit: "cover",
          }}
        />
      </div>

      <div className="container">
        <h2 style={{ color: "black", fontSize: "60px" }}>
          {t("our_services")}
        </h2>
        <div className="mt-5 mb-5">
          <div className="about_us_section_title1">
            {" "}
            <h3>{t("recruiting")}</h3>{" "}
          </div>
          <p className="about_us_section_paragraph">
            {t("recruiting_description1")}
          </p>
          <p className="about_us_section_paragraph">
            {t("recruiting_description2")}
          </p>
          <p className="about_us_section_paragraph">
            {t("recruiting_description3")}
          </p>
          <p className="about_us_section_paragraph">
            {t("recruiting_description4")}
          </p>
          <p className="about_us_section_paragraph">
            {t("recruiting_description5")}
          </p>
        </div>
      </div>

      {/* <div className="container">
        <div className="mt-5 mb-5">
          <div className="about_us_section_title">
            {t("pay_roll_rent_staff")}
          </div>
          <p className="about_us_section_paragraph">
            {t("pay_roll_rent_staff_description")}
          </p>
          <p className="about_us_section_paragraph">
            {t("pay_roll_rent_staff_description1")}
          </p>
        </div>
      </div> */}
      {/* <div className="container">
        <ServicePosition />
      </div> */}
    </div>
  );
}

export default Services;
